import { useMemo } from "react";
import { useLocation, useRouter } from "wouter";

export const useSomeRoutes = (...routes: string[]): boolean => {
  const router = useRouter();
  const [path] = useLocation();
  const result = useMemo(
    () => routes.some((route) => router.matcher(route, path)[0]),
    [...routes, path]
  );
  return result;
};
