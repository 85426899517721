import React, { FunctionComponent, useEffect, useRef } from "react";

import { useUrl } from "../../stores/hooks/useUrl";
import { useIntersection } from "../../util/useIntersection";

const update = (node: HTMLSpanElement): void => {
  const parentNode = node.parentElement;
  if (!parentNode) {
    return;
  }
  const selectedNode = [...parentNode.childNodes].find(
    (node) => (node as Element).getAttribute("aria-selected") === "true"
  ) as HTMLElement | undefined;

  if (selectedNode) {
    const { width } = selectedNode.getBoundingClientRect();
    node.style.width = `${width}px`;
    node.style.transform = `translateX(${selectedNode.offsetLeft}px)`;
  }
};

export const TabPaddle: FunctionComponent = () => {
  const ref = useRef<HTMLSpanElement>(null);
  const url = useUrl();

  useIntersection(
    {
      callback([{ intersectionRatio }]) {
        if (intersectionRatio > 0 && ref.current) {
          update(ref.current);
        }
      },
      ref,
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    },
    [url.toString()]
  );

  useEffect(() => {
    const node = ref.current;
    const parentNode = node?.parentElement;
    const observer = new MutationObserver(() => {
      update(ref.current as HTMLSpanElement);
    });
    if (parentNode) {
      observer.observe(parentNode, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }
    update(ref.current as HTMLSpanElement);

    return () => {
      observer.disconnect();
    };
  }, []);

  return <span className="tw-tabs-paddle" ref={ref} />;
};
