import {
  partTypeAccessory,
  partTypePackage,
  partTypeTire,
} from "../../constants/partTypes";
import { AnyOrderItem, AnyPartItem } from "../../stores/slices/cartSlice";

type Props = {
  isStaggered: boolean;
  orderItem: AnyOrderItem | null;
  part: AnyPartItem | null;
};

export const getDefaultQuantity = ({
  isStaggered,
  orderItem,
  part,
}: Props): string => {
  if (orderItem) {
    return orderItem.itemQty;
  }
  switch (part?.partDetails.primaryPart.itemType) {
    case partTypeTire:
      return isStaggered ? "2" : "4";
    case partTypePackage: {
      const { packageItem } = part.partDetails.primaryPart;
      const hasQuantity = packageItem.some(
        ({ itemQuantity }) => Number(itemQuantity) > 1
      );
      return hasQuantity || !packageItem.length ? "1" : "4";
    }
    case partTypeAccessory:
      return "1";
    default:
      return "4";
  }
};
