/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/prop-types */
import clsx from "clsx";
import React, { FormEvent, ReactElement, cloneElement } from "react";

import { Dropdown, DropdownProps } from "../Dropdown";
import { Toggle } from "../Toggle";
import { PopoverPlacementOptions } from "../hooks/usePopoverCoordinates/getOptimalPopoverPlacement";
import { ComboBoxOption, ComboBoxValue, useComboBox } from "./useComboBox";

type ComboBoxProps<Value extends ComboBoxValue, Data = null> = Omit<
  DropdownProps,
  "aria-labelledby" | "role"
> & {
  children: ReactElement;
  formatOption?: (option: ComboBoxOption<Value, Data> | null) => string;
  onSelectOption?: (option: ComboBoxOption<Value, Data> | null) => void;
  open?: boolean;
  options: ComboBoxOption<Value, Data>[];
};

export const ComboBox = <Value extends ComboBoxValue, Data = null>({
  children,
  formatOption,
  id,
  onSelectOption,
  open,
  options,
  placement,
  preferredPlacement,
  ...dropodownProps
}: ComboBoxProps<Value, Data>): JSX.Element => {
  const {
    currentOption,
    handleClick,
    handleInput,
    handleToggle,
    label,
    ref,
    visibleOptions,
  } = useComboBox({
    onSelectOption,
    options,
    value: children.props.value,
  });
  return (
    <Toggle disabled={!visibleOptions.length} onToggle={handleToggle}>
      {cloneElement(children, {
        autoComplete: "off",
        id,
        onChange(event: FormEvent<HTMLInputElement>) {
          if (children.props.onChange) {
            children.props.onInput(event);
          }
          if (!event.isDefaultPrevented()) {
            handleInput(event);
          }
        },
        ref,
        role: "combobox",
        value: formatOption ? formatOption(currentOption) : label,
      })}
      {/* @ts-ignore */}
      <Dropdown
        {...dropodownProps}
        aria-labelledby={id}
        className={clsx("tw-combobox-dropdown", dropodownProps.className)}
        id={`${id}-dropdown`}
        placement={placement as PopoverPlacementOptions}
        preferredPlacement={preferredPlacement as PopoverPlacementOptions}
        role="listbox"
        stopOutsidePropagation
        visible={open}
      >
        {visibleOptions.map(({ data, description, label, value }, key) => (
          <button
            className={clsx(
              "tw-dropdown__option",
              value === currentOption?.value && "bg-selected"
            )}
            key={`${value}-${key}`}
            onClick={handleClick({ data, description, label, value })}
            type="button"
          >
            {label}
            {description && <div className="text-xs">{description}</div>}
          </button>
        ))}
      </Dropdown>
    </Toggle>
  );
};
