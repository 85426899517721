/* eslint-disable react/jsx-no-target-blank */
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { config } from "../../config/config";
import {
  DealerEntryWithGeocoderResultAndDistance,
  useGetNationalAccountDealerList,
} from "../../queries/useGetNationalAccountDealerList";
import { Point } from "../../util/getDistanceInKm";
import { WithGoogleMaps } from "../GoogleMapsLoader/WithGoogleMaps";
import { getDealerAddress } from "./getDealerAddress";
import { useDealerLink } from "./useDealerLink";

const fallbackCenter: Point = {
  lat: 63.7467,
  lng: -68.517,
};

const useCenter = (
  dealers?: DealerEntryWithGeocoderResultAndDistance[],
  defaultCenter: Point = fallbackCenter
): Point => {
  const center = useMemo(() => {
    if (dealers) {
      const latSum = dealers.reduce((acc, dealer) => acc + dealer.point.lat, 0);
      const lngSum = dealers.reduce((acc, dealer) => acc + dealer.point.lng, 0);
      const latAvg = latSum / dealers.length;
      const lngAvg = lngSum / dealers.length;

      const point: Point = { lat: latAvg, lng: lngAvg };

      return latAvg && lngAvg ? point : defaultCenter;
    }
    return defaultCenter;
  }, [dealers]);

  return center;
};

const getBounds = (
  map: google.maps.Map | null,
  dealers?: DealerEntryWithGeocoderResultAndDistance[]
): void => {
  const center = map?.getCenter();
  if (dealers?.length && center && map) {
    const latLngBounds = new window.google.maps.LatLngBounds(center);
    dealers.forEach(({ point }) => {
      latLngBounds.extend(point);
    });

    const paddingInDegreesLat = 1 / 69;
    const paddingInDegreesLng =
      1 / (Math.cos((center.lat() * Math.PI) / 180) * 69);

    const northEast = latLngBounds.getNorthEast();
    const southWest = latLngBounds.getSouthWest();

    latLngBounds.extend(
      new google.maps.LatLng(
        northEast.lat() + paddingInDegreesLat,
        northEast.lng() + paddingInDegreesLng
      )
    );

    latLngBounds.extend(
      new google.maps.LatLng(
        southWest.lat() - paddingInDegreesLat,
        southWest.lng() - paddingInDegreesLng
      )
    );

    map.fitBounds(latLngBounds);
  }
};

export const DealershipMap: FunctionComponent = () => {
  const { t } = useTranslation();
  const [selectedDealer, setSelectedDealer] =
    useState<DealerEntryWithGeocoderResultAndDistance | null>(null);
  const { data, geocodeResult } = useGetNationalAccountDealerList();

  const point: Point = {
    lat: geocodeResult?.geometry?.location.lat() || fallbackCenter.lat,
    lng: geocodeResult?.geometry?.location.lng() || fallbackCenter.lng,
  };
  const center = useCenter(data, point);
  const ref = useRef<google.maps.Map | null>(null);
  const link = useDealerLink(selectedDealer);

  useEffect(() => {
    if (ref.current) {
      getBounds(ref.current, data);
    }
  }, [data]);

  if (!center) {
    return null;
  }

  return (
    <section>
      <WithGoogleMaps>
        <GoogleMap
          onLoad={(map): void => {
            ref.current = map;
            getBounds(map, data);
          }}
          options={{
            mapTypeControl: false,
            streetViewControl: false,
          }}
          center={center}
          mapContainerClassName="w-full h-56 sm:h-64 md:h-72 lg:h-96 rounded-panel border border-edge"
          zoom={8}
        >
          {data?.map((dealer) => (
            <Marker
              icon={config.googleMapsMarkerSrc}
              key={dealer.dealerNumber}
              onClick={(): void => setSelectedDealer(dealer)}
              position={dealer.point}
            />
          ))}

          {selectedDealer && (
            <InfoWindow
              onCloseClick={(): void => setSelectedDealer(null)}
              position={selectedDealer.point}
            >
              <>
                <a
                  className="text-lg font-medium mb-2 mt-1 underline"
                  href={link}
                >
                  {selectedDealer.dealerName}
                </a>
                <div className="grid grid-cols-4 gap-2 my-2">
                  {selectedDealer.dealerContactPhone && (
                    <>
                      <span className="font-thin text-ink-2">
                        {t("national.phone")}
                      </span>
                      <strong className="col-span-3">
                        {selectedDealer.dealerContactPhone}
                      </strong>
                    </>
                  )}
                  <span className="font-thin text-ink-2">
                    {t("national.location")}
                  </span>
                  <strong className="col-span-3">
                    {getDealerAddress(selectedDealer)}
                  </strong>
                </div>
              </>
            </InfoWindow>
          )}
        </GoogleMap>
      </WithGoogleMaps>
    </section>
  );
};
