import { useEffect, useRef } from "react";

import { useUrl } from "../stores/hooks/useUrl";
import { isNationalReferrer } from "./isNationalReferrer";

window.dataLayer = window.dataLayer || [];

const oemBrand = window.dealerAccountInfoPayload?.dealerNationalAccountName;

const gtag = (payload: Record<string, unknown>): void => {
  if (window.googleAnalyticsCode) {
    payload["googleAnalyticsCode"] = window.googleAnalyticsCode;
  }
  if (window.googleTagManagerAccessCode) {
    payload["gtmPropertyID"] = window.googleTagManagerAccessCode;
  }
  window.dataLayer.push(payload);
};

const trackPageView = (path: string): void => {
  const page = isNationalReferrer() ? `/national${path}` : path;
  gtag({
    event: "pageview",
    oemBrand,
    page,
  });
};

export const gtmTrackEvent = (payload: {
  event: string;
  eventAction?: string;
  eventCategory?: string;
  eventLabel?: string;
  eventValue: unknown;
}): void => {
  gtag({ ...payload, oemBrand });
};

export const useGoogleTagManager = (): void => {
  const url = useUrl();
  const location = `${url.pathname}${url.search}`;
  const hash = url.hash;
  const lastHash = useRef(hash);

  useEffect(() => {
    trackPageView(location);
  }, [location]);

  useEffect(() => {
    const last = lastHash.current;

    if (!last && !hash) {
      return;
    }

    gtmTrackEvent({
      event: "overlay",
      eventAction: last ? "overlay_close" : "overlay_open",
      eventCategory: "user_interaction",
      eventValue: last ? last : hash,
    });
    lastHash.current = hash;
  }, [hash]);
};

declare global {
  interface Window {
    dataLayer: unknown[];
    googleAnalyticsCode?: string;
    googleTagManagerAccessCode?: string;
  }
}
