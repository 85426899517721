import { useCallback, useEffect, useState } from "react";
import { useCounter, useToggle } from "react-use";

import { useTokenId } from "../stores/hooks/useTokenId";
import { FetcherResult, fetcher } from "./fetcher";

type Parameters = Record<string, string>;

export type PostResponse<Data> = {
  data: Data | null;
  error: Error | null;
  loading: boolean;
  send(payload: Parameters): Promise<FetcherResult<Data>>;
  version: number;
};

export const usePost = <Data>(url: string): PostResponse<Data> => {
  const [loading, setLoading] = useToggle(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<Data | null>(null);
  const tokenId = useTokenId();
  const [version, { inc }] = useCounter(0);

  useEffect(() => {
    setData(null);
    setError(null);
    setLoading(false);
  }, [url]);

  return {
    data,
    error,
    loading,
    send: useCallback(
      (payload: Parameters) => {
        setLoading(true);
        const requestInit = {
          body: new URLSearchParams({ ...payload }),
          method: "POST",
        };
        return fetcher<Data>(url, requestInit)
          .then((response) => {
            setData(response.payload);
            setError(null);
            setLoading(false);
            inc();
            return response;
          })
          .catch((err) => {
            setLoading(false);
            setError(err);
            setData(null);
            throw err;
          });
      },

      [url, tokenId]
    ),
    version,
  };
};
