import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { useLanguage } from "../../i18n/useLanguage";

type PriceDisplayProps = {
  children?: number | string;
  fallback?: string;
};

export const formatPrice = (
  value: number | string,
  language: string
): string => {
  const price = Number(value);
  const locale = language === "fr" ? "fr-CA" : "en-CA";
  return price.toLocaleString(locale, {
    currency: "CAD",
    style: "currency",
  });
};

export const PriceDisplay: FunctionComponent<PriceDisplayProps> = ({
  children,
  fallback,
}) => {
  const { t } = useTranslation();
  const [language] = useLanguage();
  const value = Number(children) || 0;
  if (!value) {
    return fallback || t("cart.notAvailable");
  }
  return <>{formatPrice(value, language)}</>;
};
