interface SuggestPlacement {
  (args: { producerNode: HTMLElement }): "bottom" | "left" | "right" | "top";
}

type SuggestedPlacement = ReturnType<SuggestPlacement>;

type Space = Record<SuggestedPlacement, number>;

export const suggestPlacement: SuggestPlacement = ({ producerNode }) => {
  const { bottom, left, right, top } = producerNode.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  const space: Space = {
    bottom: (innerHeight - bottom) * innerHeight,
    left: left * innerHeight,
    right: (innerWidth - right) * innerHeight,
    top: top * innerWidth,
  };

  const entries = Object.entries(space) as [SuggestedPlacement, number][];

  const largestSpace = entries.reduce((last, next) =>
    next[1] > last[1] ? next : last
  );

  return largestSpace[0];
};
