import {
  PayloadAction,
  SliceCaseReducers,
  createSlice,
} from "@reduxjs/toolkit";

import { OrderItem } from "../../blocks/Cart/CartPreview/OrderItem";
import { LanguageMap } from "../../i18n/useLanguage";
import { AccessoryPartItem } from "../../queries/useSearchAccessoriesByVehicle";
import { PackagePartItem } from "../../queries/useSearchPartPackagesByVehicle";
import { TirePartItem } from "../../queries/useSearchTiresByVehicle";
import { WheelPartItem } from "../../queries/useSearchWheelsByVehicle";
import { createSessionStorage } from "../../util/createSessionStorage";

export type CartState = OrderDetails | null;

const defaultState: CartState = null;

const name = "cart";

const storage = createSessionStorage<CartState>(name, defaultState);

export const cartSlice = createSlice<CartState, SliceCaseReducers<CartState>>({
  extraReducers: (builder): void => {
    builder.addCase("auth/replaceToken", () => {
      storage.set(defaultState);
      return defaultState;
    });
  },
  initialState: storage.get() as CartState,
  name,
  reducers: {
    next: (_state, action: PayloadAction<OrderDetails>): OrderDetails => {
      storage.set(action.payload);
      return action.payload;
    },
  },
});

export const cart = cartSlice.reducer;

export type OrderDetails = {
  appointmentRequestDate: string;
  appointmentRequestTime: string;
  confirmationNumber?: string;
  customerAddress: string;
  customerCity: string;
  customerContactMethodPreference: string;
  customerCountry: string;
  customerEmail: string;
  customerName: string;
  customerNameBilling: string;
  customerNotes: string;
  customerPhone: string;
  customerPhoneBilling: string;
  customerPostalCode: string;
  customerState: string;
  dealerId: string;
  dealerNotes: string;
  isNational: number;
  orderAbsoluteUrl: LanguageMap;
  orderCreatedTimestamp: string;
  orderCreatedUserId: string;
  orderCreatedUserName: string;
  orderDeposit: string;
  orderDepositDealer: string;
  orderDepositInternal: string;
  orderDisclaimerList: unknown[];
  orderDueAtDealer: string;
  orderFulfillmentList: {
    fulfillment_value?: {
      customerPoNumber: string;
      errorMsg?: string;
      orderNumber: string;
    };
    poNumber: string;
  }[];
  orderGrandTotal: string;
  orderId: string;
  orderIsPriceMatched: boolean;
  orderIsPrinted: boolean;
  orderItemList: AnyOrderItem[];
  orderLastUpdatedTimestamp: string;
  orderMessageList: unknown[];
  orderNormalRefund: string;
  orderNotes: LanguageMap;
  orderNumber: string;
  orderPaymentList: unknown[];
  orderRestockingFee: string;
  orderStatus: string;
  orderStatusDescription: LanguageMap;
  orderSubtotal: string;
  orderTaxPercent: string;
  orderTaxTotal: string;
  orderTypeCode: string;
  referenceNumber: string;
  supportConsumerOrder: boolean;
  supportDealerOrder: boolean;
  vehicleInformation: VehicleInformation[];
  workOrderNumber: string;
};

export type VehicleInformation = {
  vehicleId: string;
  vehicleModelImageLink: string;
  vehicleSubmodelImageLink: string;
  vehicleTitle: LanguageMap;
};

export type AnyPartItem =
  | AccessoryPartItem
  | PackagePartItem
  | TirePartItem
  | WheelPartItem;

export type AnyOrderItem = OrderItem<AnyPartItem>;
export type OrderItemAccessory = OrderItem<AccessoryPartItem>;
export type OrderItemPackage = OrderItem<PackagePartItem>;
export type OrderItemTire = OrderItem<TirePartItem>;
export type OrderItemWheel = OrderItem<WheelPartItem>;

type OrderItem<ItemPart extends AnyPartItem> = {
  additionalOptionalLineItemList: OptionalLineItem[];
  hasRequiredRelatedItems: string;
  hideItem: boolean;
  includedOptionalLineItemList: IncludedOptionalLineItem[];
  isTaxable: string;
  itemDescription: LanguageMap;
  itemDisplayPrice: string;
  itemHasDealerPricing: boolean;
  itemHasStock: boolean;
  itemId: string;
  itemImageLink: string;
  itemListPrice: string;
  itemNationalPartNumber: string;
  itemOriginalEquipmentFlag: string;
  itemPart: ItemPart;
  itemPartNumber: string;
  itemPartOption: null;
  itemPrice: string;
  itemPrivatePrice: string;
  itemPromotion: LanguageMap;
  itemPromotionPricing: LanguageMap;
  itemQty: string;
  itemSavings: string;
  itemShippingMetrics: null;
  itemTitle: LanguageMap;
  itemType: ItemPart["partSummary"]["primaryPart"]["itemType"];
  mandatoryLineItemList: MandatoryLineItem[];
  marketMinimum: boolean;
  optionId: string;
  orderItemId: string;
  pricingContextId: string;
  rowPrice: string;
  rowSavings: string;
  supportConsumerOrder: boolean;
  supportDealerOrder: boolean;
  vehicleId: string;
};

export type MandatoryLineItem = {
  description: LanguageMap;
  isMandatory: string;
  isPriceMatch: string;
  isTaxable: string;
  lineItemDetails: {
    lineItemCreatedTimestamp: string;
    lineItemCreatedTokenId: string;
  };
  lineItemId: string;
  matchItemQty: string;
  price: string;
  qty: string;
  title: LanguageMap;
};

export type OptionalLineItem = {
  authOnly: number;
  description: LanguageMap;
  isMandatory: number;
  isPriceMatch: string;
  isTaxable: string;
  itemApplicationCriteria: string;
  lineItemId: string;
  matchItemQty: string;
  ownerId: string;
  ownerType: string;
  price: string;
  priceMax: string;
  qty: string;
  title: LanguageMap;
  unitPrice: string;
};

export type IncludedOptionalLineItem = {
  description: LanguageMap;
  isMandatory: string;
  isPriceMatch: string;
  isTaxable: string;
  lineItemDetails: {
    lineItemCreatedTimestamp: string;
    lineItemCreatedTokenId: string;
    lineItemNotes: string;
    lineItemWorkOrderNumber: string;
  };
  lineItemId: string;
  matchItemQty: string;
  price: string;
  qty: string;
  title: LanguageMap;
};
