import { DefaultParams } from "wouter";

export type SomeParams = Record<string, string>;

export const decodeParams = (params: DefaultParams): SomeParams => {
  const decodedParams: SomeParams = {};

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];
      if (value) {
        decodedParams[key] = decodeURIComponent(value);
      }
    }
  }

  return decodedParams;
};
