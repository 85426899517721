/* eslint-disable react/prop-types */
import React, { FunctionComponent } from "react";

import { OemLogos } from "../blocks/DealerGroup/OemLogos";
import { HomepageCarousel } from "../blocks/Hero/HomepageCarousel";
import { Meta } from "../blocks/Meta/Meta";
import { config } from "../config/config";
import { useLanguage } from "../i18n/useLanguage";
import { useNullableGroupAccountInfo } from "../stores/hooks/useNullableGroupAccountInfo";

export const GroupLandingPage: FunctionComponent = () => {
  const { LandingPageContent } = config;
  const groupInfo = useNullableGroupAccountInfo();
  const [language] = useLanguage();

  return (
    <>
      <Meta
        cmsTitle={groupInfo?.dealerGroupSiteCustomTitle[language] ?? ""}
        title=""
      />
      <OemLogos />
      <HomepageCarousel />
      <LandingPageContent />
    </>
  );
};
