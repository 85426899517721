export const createSessionStorage = <State>(
  name: string,
  defaultState: State
): { get(): State; set(next: State): void } => {
  const key = `tw: ${name}`;
  return {
    get(): State {
      try {
        const value = sessionStorage.getItem(key);
        return value ? JSON.parse(value) : defaultState;
      } catch (error) {
        return defaultState;
      }
    },
    set(value): void {
      const valueToStore = JSON.stringify(value);
      sessionStorage?.setItem(key, valueToStore);
    },
  };
};
