import React, { FunctionComponent } from "react";

import { Language, useLanguage } from "../../i18n/useLanguage";
import { useDealerAccountInfo } from "../../stores/hooks/useDealerAccountInfo";
import { DealerCountry } from "../../stores/slices/dealerAccountInfoSlice";

type DateDisplayProps = {
  children?: string;
};

const map = {
  "en-CA": "Km",
  "en-US": "mi.",
  "fr-CA": "km",
  "fr-US": "mi.",
};

export const formatDistance = (
  distanceString: string,
  language: Language,
  country: DealerCountry
): string => {
  const distanceStringCleaned = distanceString.replace(/[^\d]/g, "");

  // NOTE: distanceString can be some free text, so we don't want to format it
  if (!distanceStringCleaned) {
    return distanceString;
  }

  // NOTE: when distanceString is a number, we want to format it
  const distance = Number(distanceStringCleaned);

  const unitString = map[`${language}-${country}`] || map["en-CA"];
  const formattedDistance = distance.toLocaleString(language);

  return `${formattedDistance} ${unitString}`;
};

export const MileageDisplay: FunctionComponent<DateDisplayProps> = ({
  children,
}) => {
  const [language] = useLanguage();
  const { dealerCountry } = useDealerAccountInfo();

  if (!children) {
    return null;
  }

  return <>{formatDistance(children, language, dealerCountry)}</>;
};
