import { PricingOption } from "../blocks/PriceSelect/getFlatPricingOptions";
import {
  partTypeAccessory,
  partTypePackage,
  partTypeTire,
  partTypeWheel,
} from "../constants/partTypes";
import { useIsLoggedOn } from "../stores/hooks/useIsLoggedOn";
import { useNullableDealerAccountInfo } from "../stores/hooks/useNullableDealerAccountInfo";
import { AnyPartItem } from "../stores/slices/cartSlice";

export const useAllowPartPricing = (
  part: Readonly<AnyPartItem> | null,
  priceOption: PricingOption | null
): boolean => {
  const dealer = useNullableDealerAccountInfo();
  const isLoggedOn = useIsLoggedOn();
  const partType = part?.partDetails.primaryPart.itemType;

  if (
    dealer?.dealerEnableHidePublicPricing === "HIDE_ALL" ||
    (dealer?.dealerEnableHidePublicPricing === "SHOW_AUTH" && !isLoggedOn) ||
    (priceOption && !Number(priceOption.context.displayPrice))
  ) {
    return false;
  }

  const flags: boolean[] = [
    Boolean(
      partType === partTypeAccessory && dealer?.dealerIgnoreAccessoryStock
    ),
    Boolean(
      partType === partTypePackage && dealer?.dealerIgnoreMerchandiseStock
    ),
    Boolean(partType === partTypeTire && dealer?.dealerIgnoreTireStock),
    Boolean(partType === partTypeWheel && dealer?.dealerIgnoreWheelStock),
  ];

  const displayPrice = priceOption?.context.displayPrice ?? 0;

  const itemHasDealerPricing =
    part?.partSummary.primaryPart.itemHasDealerPricing;

  const hasPrice = Number(displayPrice) > 0;

  return Boolean((itemHasDealerPricing || flags.some(Boolean)) && hasPrice);
};
