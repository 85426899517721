import { ReactElement } from "react";

import {
  productTypeAccessory,
  productTypePackage,
  productTypeTire,
  productTypeWheel,
} from "../constants/productTypes";

type Config = {
  HeaderAddons(): ReactElement;
  LandingPageContent(): ReactElement;
  Logo(): ReactElement;
  NationalDescriptor?: () => ReactElement;
  brandName: string;
  bugsnagApiKey?: string;
  forceBreakDealerName?: boolean;
  googleMapsMarkerSrc: string;
  landingPageImage: {
    [productTypeAccessory]: string;
    [productTypePackage]: string;
    [productTypeTire]: string;
    [productTypeWheel]: string;
  };
  productHeroBackground: null | string;
  themeClass: string;
};

interface UpdateConfig {
  (newConfig: Config): void;
}

export const imageHost = "images.tirelocator.ca";

export const googleMapsApiKey = "AIzaSyCKQY24KvnUZnOYaHuB3SMha8Y-pBWFPDQ";

export const config = {
  bugsnagApiKey: process.env.BUGSNAG_API_KEY,
} as unknown as Config;

export const updateConfig: UpdateConfig = (newConfig) => {
  Object.assign(config, newConfig);
};
