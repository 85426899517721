import { useState } from "react";

type Patch<S> = ((prev: S) => S) | Partial<S>;
type UsePatchState<S> = [S, (patch: Patch<S>) => void];

export function usePatchState<S>(initialState: S): UsePatchState<S> {
  const [state, setState] = useState<S>(initialState);
  const patchState = (next: Patch<S>): void => {
    setState((prev) =>
      typeof next === "function" ? next(prev) : { ...prev, ...next }
    );
  };
  return [state, patchState];
}
