import { useState } from "react";

export type Transitions<T> = {
  [K in keyof T]: Set<keyof Omit<T, K>>;
};

interface MachineFactory {
  <T>(transitions: Transitions<T>): (
    initialState: keyof T
  ) => [keyof T, (state: keyof T) => void];
}

export const createMachine: MachineFactory =
  <T>(transitions: Transitions<T>) =>
  (initialState: keyof T) => {
    const [state, setState] = useState(initialState);
    const next = (nextState: keyof T): void => {
      const availableTranstions = transitions[state];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (availableTranstions.has(nextState)) {
        setState(nextState);
      }
    };
    return [state, next];
  };
