import { createSlice } from "@reduxjs/toolkit";

import { getURL } from "../../util/getURL";

const name = "url";

export const urlSlice = createSlice({
  initialState: getURL(),
  name,
  reducers: {
    next(_state, { payload }: { payload: string }) {
      return payload;
    },
  },
});

export const url = urlSlice.reducer;
