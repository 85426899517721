import { Loader } from "@googlemaps/js-api-loader";

import { googleMapsApiKey } from "../../config/config";

const loader = new Loader({
  apiKey: googleMapsApiKey,
  version: "weekly",
});

export const loadGeocoder = async (): Promise<void> => {
  if (!window.google?.maps?.Geocoder) {
    await loader.importLibrary("geocoding");
  }
};

export const loadMaps = async (): Promise<void> => {
  if (!window.google?.maps) {
    await loader.importLibrary("maps");
  }
};
