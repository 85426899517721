import clsx from "clsx";
import React, { FunctionComponent } from "react";

type TireLinkLogoProps = {
  className: string;
};

export const TireLinkLogo: FunctionComponent<TireLinkLogoProps> = ({
  className,
}) => {
  return (
    <img
      alt="TireLink logo"
      className={clsx("aspect-[372/87]", className)}
      height={27}
      src="/static/img/tirelink.png"
    />
  );
};
