import React, { FunctionComponent, ReactNode, useRef } from "react";

import { useIntersection } from "../../util/useIntersection";
import { Button } from "../Button/Button";
import { usePagination } from "./usePagination";

type PagingProps = {
  children: ReactNode[];
  className?: string;
};

export const Pagination: FunctionComponent<PagingProps> = ({
  children,
  className,
}) => {
  const [take, next] = usePagination(children.length);

  const visibleChildren = children.slice(0, take);
  const ref = useRef<HTMLButtonElement>(null);

  const buttonIsVisible = take < children.length;

  useIntersection(
    {
      callback([{ intersectionRatio }]) {
        if (intersectionRatio > 0) {
          next();
        }
      },
      ref,
      root: null,
      rootMargin: "240px",
      threshold: 1.0,
    },
    [buttonIsVisible, children.length, take]
  );

  return (
    <>
      {visibleChildren}
      {buttonIsVisible && (
        <Button
          aria-label="moreResults"
          className={className}
          onClick={next}
          ref={ref}
          type="button"
        >
          Show More
        </Button>
      )}
    </>
  );
};
