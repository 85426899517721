import clsx from "clsx";
import React, { FunctionComponent, SVGProps } from "react";

export const ChevronRightIcon: FunctionComponent<SVGProps<SVGSVGElement>> = ({
  className,
  ...props
}) => {
  return (
    <svg
      {...props}
      className={clsx("tw-icon", className)}
      fill="none"
      height="14"
      viewBox="0 0 8 14"
      width="8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M.46967 13.5303c-.292893-.2929-.292893-.7677 0-1.0606L5.93934 7 .469669 1.53033c-.292893-.29289-.292893-.767767 0-1.06066.292894-.292893.767771-.292893 1.060661 0l6 6c.29289.29289.29289.76777 0 1.06066l-6 5.99997c-.29289.2929-.767767.2929-1.06066 0Z"
        fillRule="evenodd"
      />
    </svg>
  );
};
