import { ExtractRouteParams, Path, useRoute } from "wouter";

import { decodeParams } from "./decodeParams";

export const useRouteParams = <RoutePath extends Path>(
  routePath: RoutePath
): ExtractRouteParams<RoutePath> | null => {
  const [, route] = useRoute(routePath);

  if (!route) {
    return null;
  }

  const params = decodeParams(route);

  return params as ExtractRouteParams<RoutePath>;
};
