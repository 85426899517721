import { createSlice } from "@reduxjs/toolkit";
import { z } from "zod";

import { bugsnagUseToken } from "../../util/bugsnag";
import { parseToken } from "../../util/parseToken";

const name = "auth";

export const schema = z.object({
  applicationVersionNumber: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  resetSecurityCheckpoint: z.boolean({ coerce: true }),
  sessionReferenceNumber: z.string(),
  tokenCreatedTimestamp: z.string(),
  tokenExpirationTimestamp: z.string(),
  tokenId: z.string(),
  tokenIsAuthenticated: z.boolean({ coerce: true }),
  userRoleId: z.number({ coerce: true }),
  userRoleName: z.string(),
});

const defaultState = parseToken();

export const dealerUrlCode =
  window.dealerAccountInfoPayload?.dealerUrlCode || "global";

const getInitialState = (): Token => {
  bugsnagUseToken(defaultState);
  return defaultState;
};

export const authSlice = createSlice({
  initialState: getInitialState(),
  name,
  reducers: {
    replaceToken(state, { payload }: { payload: Token }) {
      bugsnagUseToken(payload);
      return payload;
    },
  },
});

export const auth = authSlice.reducer;

// export const replaceTokenType = authSlice.actions.replaceToken.type;

export type Token = z.infer<typeof schema>;

declare global {
  interface Window {
    ipAddress: string;
    token: string;
  }
}
