import { useEffect, useState } from "react";

import { subscribe } from "../../helpers/subscribe";

interface ConnectionStatusHook {
  (): { connected: boolean | null; hasChange: boolean };
}

export const useConnectionStatus: ConnectionStatusHook = () => {
  const [isOnline, setIsOnline] = useState<boolean | null>(null);
  const [changed, setHasChange] = useState<boolean>(false);

  useEffect(() => {
    const setTrue = (): void => {
      setIsOnline(true);
    };
    const setFalse = (): void => {
      setIsOnline(false);
      setHasChange(true);
    };

    if (navigator.onLine) {
      setTrue();
    } else {
      setFalse();
    }
    const unsubscribeOnline = subscribe(window, "online", setTrue);
    const unsubscribeOffline = subscribe(window, "offline", setFalse);

    return () => {
      unsubscribeOnline();
      unsubscribeOffline();
    };
  }, []);

  return { connected: isOnline, hasChange: changed };
};
