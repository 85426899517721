import { DependencyList, RefObject, useEffect } from "react";

export const useIntersection = (
  {
    callback,
    ref,
    ...options
  }: {
    callback: IntersectionObserverCallback;
    ref: RefObject<HTMLElement>;
  } & IntersectionObserverInit,
  dependencyList: DependencyList
): void => {
  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, dependencyList);
};
