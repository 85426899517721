import {
  FormEvent,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export type ComboBoxValue = number | string;

export type ComboBoxOption<Value extends ComboBoxValue, Data = null> = {
  data: Data;
  description?: string;
  label: string;
  value: Value;
};

type ComboBoxProps<Value extends ComboBoxValue, Data = null> = {
  onSelectOption?: (option: ComboBoxOption<Value, Data> | null) => void;
  options: ComboBoxOption<Value, Data>[];
  value?: Value;
};

type ComboBoxResult<Value extends ComboBoxValue, Data = null> = {
  currentOption: ComboBoxOption<Value, Data> | null;
  handleClick(option: ComboBoxOption<Value, Data>): VoidFunction;
  handleInput(event: FormEvent<HTMLInputElement>): void;
  handleToggle(isVisible: boolean): void;
  label: string;
  ref: RefObject<HTMLInputElement>;
  visibleOptions: ComboBoxOption<Value, Data>[];
};

const findOption = <Value extends ComboBoxValue, Data = null>(
  options: ComboBoxOption<Value, Data>[],
  value: string
): ComboBoxOption<Value, Data> | undefined =>
  options.find(({ label }) => label.toLowerCase() === value.toLowerCase());

export const useComboBox = <Value extends ComboBoxValue, Data = null>({
  onSelectOption,
  options,
  value,
}: ComboBoxProps<Value, Data>): ComboBoxResult<Value, Data> => {
  const ref = useRef<HTMLInputElement>(null);
  const defaultOption = useMemo(
    () => findOption(options, String(value)),
    [options, value]
  );
  const [label, setLabel] = useState(defaultOption?.label || "");

  const currentOption = useMemo(
    () => findOption(options, String(label)) || null,
    [options, label]
  );

  useEffect(() => {
    const nextLabel = defaultOption?.label || "";
    if (nextLabel !== label) {
      setLabel(nextLabel);
    }
  }, [defaultOption]);

  const filterOptions = useMemo(() => {
    if (currentOption) {
      return options;
    }
    return options.filter((option) =>
      `${option.label} ${option.description || ""}`
        .toLowerCase()
        .includes(label.toLowerCase())
    );
  }, [options, label, currentOption]);

  const handleClick = (option: ComboBoxOption<Value, Data>) => () => {
    setLabel(option.label);
    if (onSelectOption) {
      onSelectOption(option);
    }
  };

  const handleInput = (event: FormEvent<HTMLInputElement>): void => {
    const value = (event.target as HTMLInputElement).value.trim();
    setLabel(value);
  };

  const handleToggle = (isVisible: boolean): void => {
    if (onSelectOption && !isVisible && !value) {
      onSelectOption(currentOption);
    }
  };

  return {
    currentOption,
    handleClick,
    handleInput,
    handleToggle,
    label,
    ref,
    visibleOptions: filterOptions,
  };
};
