import { useTypedSelector } from "../hooks";
import { NationalAccountInfoPayload } from "../slices/nationalAccountInfoSlice";

export const useNullableNationalAccountInfo =
  (): NationalAccountInfoPayload => {
    const state = useTypedSelector(
      ({ _nationalAccountInfo }) => _nationalAccountInfo
    );
    return state;
  };
