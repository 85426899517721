import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useTypedSelector } from "../../stores/hooks";
import {
  defaultItemsPerPage,
  incrementPagination,
  resetPagination,
} from "../../stores/slices/paginationSlice";

export const usePagination = (totalItems: number): [number, VoidFunction] => {
  const state = useTypedSelector((state) => state.pagination);
  const dispatch = useDispatch();

  const increment = useCallback(() => {
    const action = incrementPagination();
    dispatch(action);
  }, []);

  const shouldReset = state.total !== totalItems;

  useEffect(() => {
    if (shouldReset) {
      const action = resetPagination(totalItems);
      dispatch(action);
    }
  }, [shouldReset, totalItems]);

  const vivibleItems = shouldReset ? defaultItemsPerPage : state.visible;

  return [vivibleItems, increment];
};
