import { useGet } from "../api-client/useGet";
import { useTypedSelector } from "../stores/hooks";
import { useCartNext } from "../stores/hooks/useCartNext";
import { useDealerAccountInfo } from "../stores/hooks/useDealerAccountInfo";
import { OrderDetails } from "../stores/slices/cartSlice";

export const useGetNewCart = (): (() => Promise<OrderDetails>) => {
  const state = useTypedSelector(({ cart }) => cart);
  const { dealerId } = useDealerAccountInfo();
  const { get } = useGet();
  const cartNext = useCartNext();
  return async () => {
    if (state) {
      return state;
    }
    const { payload } = await get<OrderDetails>(
      "/retailSiteWebService/shopping/cart/getNewCart.php",
      {
        dealerId,
      }
    );
    cartNext(payload);
    return payload;
  };
};
