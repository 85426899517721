import { createSlice } from "@reduxjs/toolkit";

import { decodePayload } from "../../api-client/decodePayload";
import { LanguageMap } from "../../i18n/useLanguage";
import { DealerInfo } from "../../queries/useGetDealerGroupDealerList";

const name = "campaign";

export const applicationSlice = createSlice({
  initialState: (decodePayload(window.campaignData) as CampaignInfo) ?? null,
  name,
  reducers: {},
});

export const _campaignInfo = applicationSlice.reducer;

export type CampaignInfo = {
  campaign: Campaign;
  campaignDealerList: DealerInfo[];
};

type Campaign = {
  campaignCode: string;
  campaignDirectToSite: string;
  campaignId: string;
  campaignIsActive: boolean;
  campaignName: LanguageMap;
  campaignPartType: string;
  campaignSearchCode: string;
  campaignSearchText: string;
  campaignSeason: string;
};

declare global {
  interface Window {
    campaignData?: CampaignInfo;
  }
}
