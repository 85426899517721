import { useDispatch } from "react-redux";

import { useTypedSelector } from "../hooks";
import { Token, authSlice } from "../slices/authSlice";

export const useUpgradeToken = (): ((token: Token) => void) => {
  const dispatch = useDispatch();
  const state = useTypedSelector(({ auth }) => auth);

  return (token): void => {
    if (token && state.tokenId !== token.tokenId) {
      const action = authSlice.actions.replaceToken(token);
      dispatch(action);
    }
  };
};
