import { createSlice } from "@reduxjs/toolkit";

import { decodePayload } from "../../api-client/decodePayload";
import { Language, LanguageMap } from "../../i18n/useLanguage";
import { CarouselItem } from "./dealerAccountInfoSlice";

const name = "nationalAccountInfo";

export const nationalAccountInfoSlice = createSlice({
  initialState: decodePayload(
    window.nationalAccountInfoPayload
  ) as NationalAccountInfoPayload,
  name,
  reducers: {},
});

export const _nationalAccountInfo = nationalAccountInfoSlice.reducer;

export type NationalAccountInfoPayload = {
  minimumAccessoryYear: string;
  minimumPackageYear: string;
  minimumTireYear: string;
  minimumWheelYear: string;
  nationalAccessoryShowSecondaryManufacturers: boolean;
  nationalAccountId: string;
  nationalAccountName: string;
  nationalAccountUrlCode: string;
  nationalCarousels: {
    home_primary: CarouselItem[];
    home_secondary: CarouselItem[];
  };
  nationalCountryCode: string;
  nationalDefaultLanguage: Language;
  nationalDefaultTemplate: string;
  nationalEnableAccessorySearch: boolean;
  nationalEnableAdvancedSearchModal: true;
  nationalEnableAdvancedTiresizeSearch: true;
  nationalEnableBookingSystem: boolean;
  nationalEnableHomepageCarousel: boolean;
  nationalEnableMerchandiseSearch: boolean;
  nationalEnablePackageSearch: boolean;
  nationalEnableRetailSite: boolean;
  nationalEnableShowMsrpForItems: true;
  nationalEnableShowSubmodelTiresizeSelection: boolean;
  nationalEnableSkipMap: boolean;
  nationalEnableTireSearch: boolean;
  nationalEnableWheelSearch: true;
  nationalLandingPageTitle: LanguageMap;
  nationalLanguageSet: Language[];
  nationalPackageShowSecondaryManufacturers: boolean;
  nationalRetailSiteCustomFaviconImage: string;
  nationalRetailSiteCustomTitle: LanguageMap;
  nationalRetailSiteDealerSelectionMethod: string;
  nationalRetailSiteDestinationPage: string;
  nationalRetailSiteGoogleAnalyticsCode: string;
  nationalRetailSiteGoogleAnalyticsReferrer: string;
  nationalRetailSiteGoogleTagManagerCode: string;
  nationalRetailSiteHomeWidgetPlacement: string;
  nationalRetailSiteShowLocationPromptOnMap: boolean;
  nationalRetailSiteStartPage: string;
  nationalTireManufacturerInfoList: {
    tireManufacturerId: string;
    tireManufacturerImage: string;
    tireManufacturerName: string;
  }[];
  nationalTireShowSecondaryManufacturers: boolean;
  nationalWheelShowSecondaryManufacturers: boolean;
  placeholderImageUrl: string;
  promotionalImageTire: { en: string; fr: string };
} | null;

declare global {
  interface Window {
    nationalAccountInfoPayload: NationalAccountInfoPayload;
  }
}
