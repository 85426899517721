import { navigate } from "wouter/use-location";

import { Token } from "../stores/slices/authSlice";
import { createSessionStorage } from "./createSessionStorage";

const storage = createSessionStorage<Token | null>("tw-sso-token", null);

// NOTE: Hash is used to pass token from the CMS to the web app and when printing order details
// SEE: code/client/service/HashService.js:decodeUrlSSO
export const decodeUrlSSO = (): Token | null => {
  const urlParams = window.location.hash.substring(1).split("/");
  let ssoFound = false;
  let isoFound = false;
  for (let i = 0; i < urlParams.length; i++) {
    if (ssoFound || isoFound) {
      const tokenParams = urlParams[i].split("_");
      if (tokenParams[0].search(/^[A-F0-9]{32}$/) != -1) {
        const token = {
          applicationVersionNumber: tokenParams[5],
          firstName: tokenParams[7],
          lastName: tokenParams[8],
          resetSecurityCheckpoint: Boolean(tokenParams[6]),
          sessionReferenceNumber: tokenParams[4],
          tokenCreatedTimestamp: new Date(
            parseFloat(tokenParams[1]) * 1000
          ).toISOString(),
          tokenExpirationTimestamp: new Date(
            parseFloat(tokenParams[2]) * 1000
          ).toISOString(),
          tokenId: tokenParams[0],
          tokenIsAuthenticated: tokenParams[3] === "1",
          userRoleId: Number(tokenParams[9]),
          userRoleName: tokenParams[10],
        };

        storage.set(token);

        // NOTE: This is a hack to erase history and avoid the leaking of the token
        navigate(window.location.pathname + window.location.search, {
          replace: true,
        });

        return token;
      }
    }
    if (urlParams[i] == "sso") {
      ssoFound = true;
    }
    if (urlParams[i] == "iso") {
      isoFound = true;
    }
  }
  return storage.get();
};

export const parseToken = (): Token => {
  const ssoToken = decodeUrlSSO();
  if (ssoToken) {
    return ssoToken;
  }
  return JSON.parse(window.token);
};

declare global {
  interface Window {
    token: string;
    uniquifyToken: null | string;
  }
}
