/* eslint-disable react/prop-types */
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { useDealerChassisSetting } from "../../stores/hooks/useDealerChassisSetting";
import { useIsLoggedOn } from "../../stores/hooks/useIsLoggedOn";
import { useSearchForm } from "../../stores/hooks/useSearchForm";
import { TabButton } from "../../ui/Tabs/TabButton";
import { TabsGroup } from "../../ui/Tabs/TabsGroup";

export const tabVehicle = "vehicle";
export const tabTireSize = "tireSize";
export const tabPartNubmer = "partNubmer";
export const tabChassis = "chassis";

type Props = {
  className?: string;
  compact?: boolean;
};

export const Tabs: FunctionComponent<Props> = ({ className, compact }) => {
  const [{ activeTab }, { setActiveTab }] = useSearchForm();
  const { t } = useTranslation();
  const isLoggedOn = useIsLoggedOn();
  const shouldEnableChassis = useDealerChassisSetting();
  return (
    <TabsGroup className={className} id="search-form-tabs">
      <TabButton
        onClick={(): void => {
          setActiveTab(tabVehicle);
        }}
        aria-label="searchByVehicle"
        selected={activeTab === tabVehicle}
        type="button"
      >
        {t(compact ? "searchForm.vehicle" : "searchForm.searchByVehicle")}
      </TabButton>
      <TabButton
        onClick={(): void => {
          setActiveTab(tabTireSize);
        }}
        aria-label="searchByTireSize"
        selected={activeTab === tabTireSize}
        type="button"
      >
        {t(compact ? "searchForm.tireSize" : "searchForm.searchByTireSize")}
      </TabButton>
      {shouldEnableChassis && (
        <TabButton
          onClick={(): void => {
            setActiveTab(tabChassis);
          }}
          aria-label="searchByChassis"
          selected={activeTab === tabChassis}
          type="button"
        >
          {t("searchForm.chassis")}
        </TabButton>
      )}
      {isLoggedOn && (
        <TabButton
          onClick={(): void => {
            setActiveTab(tabPartNubmer);
          }}
          aria-label="searchByPartNubmer"
          selected={activeTab === tabPartNubmer}
          type="button"
        >
          {t(
            compact ? "searchForm.partNubmer" : "searchForm.searchByPartNubmer"
          )}
        </TabButton>
      )}
    </TabsGroup>
  );
};
