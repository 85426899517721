import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "wouter";

import { config } from "../../config/config";
import {
  AnyProductType,
  productTypePackage,
} from "../../constants/productTypes";
import { ProductHeroBackground } from "./ProductHeroBackground";

export const ProductPageHero: FunctionComponent = () => {
  const { t } = useTranslation();
  const { productType = "" } = useParams();
  const src =
    productType in config.landingPageImage
      ? config.landingPageImage[productType as AnyProductType]
      : config.landingPageImage[productTypePackage];
  return (
    <>
      <ProductHeroBackground />
      <section
        className={clsx(
          "flex flex-col items-center mt-8 text-center px-4 z-0 select-none",
          "text-2xl md:text-3xl lg:text-4xl xl:text-5xl relative"
        )}
      >
        <h1 className="text-ink-2 text-[0.64em] md:mb-[0.2em] font-thin">
          {config.brandName}
        </h1>
        <h3 className="text-ink uppercase font-bold text-[1em]">
          {t("heroWheels.tireSource")}
        </h3>
        <img
          alt=""
          className="max-h-[12rem] lg:max-h-[16rem] my-6 md:my-8 mx-16"
          height={380}
          src={src}
        />
      </section>
    </>
  );
};
