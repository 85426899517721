import { RefObject, useEffect, useRef } from "react";

import { subscribe } from "../../helpers";
import { createMachine } from "../createMachine/createMachine";
import { useEscapeKey } from "../useEscapeKey/useEscapeKey";
import { useLatestRef } from "../useLatestRef";

export const INVISIBLE = "invisible";
export const VISIBLE = "visible";
export const ENTERING = "entering";
export const EXITING = "exiting";

const visibleStates = new Set([VISIBLE, ENTERING]);

type Config = {
  onClose?: VoidFunction;
  onEnter?: VoidFunction;
  onLeave?: VoidFunction;
  visible: boolean;
};

interface ShowHideHook {
  <RefType extends HTMLElement>(config: Config): {
    animationState: boolean;
    isOnTop: boolean;
    onClose: VoidFunction;
    ref: RefObject<RefType>;
    state: typeof ENTERING | typeof EXITING | typeof INVISIBLE | typeof VISIBLE;
  };
}

export type ShowHideState = ReturnType<ShowHideHook>["state"];

const useMachine = createMachine({
  [ENTERING]: new Set([VISIBLE]),
  [EXITING]: new Set([INVISIBLE]),
  [INVISIBLE]: new Set([ENTERING]),
  [VISIBLE]: new Set([EXITING]),
});

export const useShowHide: ShowHideHook = ({
  onClose,
  onEnter,
  onLeave,
  visible,
}) => {
  const ref = useRef(null);
  const [state, next] = useMachine(INVISIBLE);
  const closeRef = useLatestRef(onClose);
  const enterRef = useLatestRef(onEnter);
  const leaveRef = useLatestRef(onLeave);

  const handleClose = (): void => {
    next(EXITING);
    closeRef.current?.();
  };

  const isOnTop = useEscapeKey(
    handleClose,
    state !== INVISIBLE && typeof onClose === "function"
  );

  useEffect(() => {
    next(visible ? ENTERING : EXITING);
  }, [visible, state]);

  useEffect(
    () =>
      subscribe(ref.current, "animationend", (event) => {
        if (event.target === ref.current) {
          switch (state) {
            case ENTERING: {
              next(VISIBLE);
              enterRef.current?.();
              break;
            }
            case EXITING: {
              next(INVISIBLE);
              leaveRef.current?.();
              break;
            }

            default:
              break;
          }
        }
      }),
    [ref.current, state]
  );
  return {
    animationState: visibleStates.has(state),
    isOnTop,
    onClose: handleClose,
    ref,
    state,
  };
};
