import { DealerAccountInfoPayload } from "../slices/dealerAccountInfoSlice";
import { useNullableDealerAccountInfo } from "./useNullableDealerAccountInfo";

export const useDealerAccountInfo =
  (): NonNullable<DealerAccountInfoPayload> => {
    const state = useNullableDealerAccountInfo();
    if (!state) {
      throw new Error("Dealer account info not found");
    }
    return state;
  };
