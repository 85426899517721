export const loadScript = (id: string, url: string): void => {
  const existingScript = document.getElementById(id);
  if (existingScript) {
    existingScript.remove();
  }
  const script = document.createElement("script");
  script.src = url;
  script.id = id;
  document.body.appendChild(script);
};
