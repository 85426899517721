import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { createSessionStorage } from "../../util/createSessionStorage";

export type PanelState = Record<string, boolean>;

const defaultState: PanelState = {};

const name = "panelState";

const storage = createSessionStorage(name, defaultState);

export const panelStateSlice = createSlice({
  initialState: storage.get() as PanelState,
  name,
  reducers: {
    set: (
      state,
      { payload }: PayloadAction<{ panel: string; value: boolean }>
    ) => {
      state[payload.panel] = payload.value;
      storage.set(state);
    },
  },
});

export const panelState = panelStateSlice.reducer;
