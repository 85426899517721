import { createSlice } from "@reduxjs/toolkit";

import { SubmodelTireSize } from "../../queries/useGetVehicleSubmodels";
import { VehicleMappingRowItem } from "../../queries/useSearchPartPackagesByVehicle";

export type PackageEditorQuantityRecord = Record<string, string>;

export type CustomPart = {
  customName: string;
  customNameFr: string;
  customPartNumber: string;
  customPrice?: string;
  customTypeName: string;
  customTypeNameFr: string;
  quantity: number;
};

export type VehicleManufacturerEntry = Pick<
  VehicleMappingRowItem,
  | "vehicleManufacturerName"
  | "vehicleModelId"
  | "vehicleModelName"
  | "vehicleSubmodelId"
  | "vehicleSubmodelImage"
  | "vehicleSubmodelName"
  | "vehicleYear"
  | "vehicleYearId"
> & { vehicleSubmodelTiresizeArray: SubmodelTireSize[] };

export type PackageEditorEntry = {
  customParts: CustomPart[];
  forceVehicleLoaderMode: boolean;
  modelsAreLoading: boolean;
  partListTabIndex: number;
  quantity: PackageEditorQuantityRecord | null;
  tabIndex: number;
  vehicleManufacturerIds: number[];
  vehicleManufacturers: VehicleManufacturerEntry[];
  vehicleMapping: null | string[];
  vehicleYearIds: number[];
};

export const fallbackPackageEditorEntry: PackageEditorEntry = {
  customParts: [],
  forceVehicleLoaderMode: false,
  modelsAreLoading: false,
  partListTabIndex: 0,
  quantity: null,
  tabIndex: 0,
  vehicleManufacturerIds: [],
  vehicleManufacturers: [],
  vehicleMapping: null,
  vehicleYearIds: [],
};

type PackageEditorState = Record<string, PackageEditorEntry>;

export const packageEditorSlice = createSlice({
  initialState: {} as PackageEditorState,
  name: "packageEditor",
  reducers: {
    addCustomPart(
      state,
      {
        payload,
      }: {
        payload: { customPart: CustomPart; packageId: string };
      }
    ) {
      const entry = state[payload.packageId] ?? fallbackPackageEditorEntry;
      return {
        ...state,
        [payload.packageId]: {
          ...entry,
          customParts: [
            ...entry.customParts.filter(
              ({ customPartNumber }) =>
                customPartNumber !== payload.customPart.customPartNumber
            ),
            payload.customPart,
          ],
        },
      };
    },
    initializePackage(
      state,
      {
        payload,
      }: { payload: { packageId: string; vehicleMapping: null | string[] } }
    ) {
      if (payload.packageId in state) {
        return state;
      }

      const newPackage = {
        ...fallbackPackageEditorEntry,
        vehicleMapping: payload.vehicleMapping,
      };

      return {
        ...state,
        [payload.packageId]: newPackage,
      };
    },
    removeCustomPart(
      state,
      {
        payload,
      }: {
        payload: { customPartNumber: string; packageId: string };
      }
    ) {
      const entry = state[payload.packageId] ?? fallbackPackageEditorEntry;
      return {
        ...state,
        [payload.packageId]: {
          ...entry,
          customParts: entry.customParts.filter(
            ({ customPartNumber }) =>
              customPartNumber !== payload.customPartNumber
          ),
        },
      };
    },
    setForceVehicleLoaderMode(
      state,
      {
        payload,
      }: {
        payload: { forceVehicleLoaderMode: boolean; packageId: string };
      }
    ) {
      const entry = state[payload.packageId] ?? fallbackPackageEditorEntry;
      return {
        ...state,
        [payload.packageId]: {
          ...entry,
          forceVehicleLoaderMode: payload.forceVehicleLoaderMode,
        },
      };
    },
    setModelsAreLoading(
      state,
      {
        payload,
      }: {
        payload: { modelsAreLoading: boolean; packageId: string };
      }
    ) {
      const entry = state[payload.packageId] ?? fallbackPackageEditorEntry;
      return {
        ...state,
        [payload.packageId]: {
          ...entry,
          modelsAreLoading: payload.modelsAreLoading,
        },
      };
    },
    setPartListTabIndex(
      state,
      {
        payload,
      }: {
        payload: { packageId: string; partListTabIndex: number };
      }
    ) {
      const entry = state[payload.packageId] ?? fallbackPackageEditorEntry;
      return {
        ...state,
        [payload.packageId]: {
          ...entry,
          partListTabIndex: payload.partListTabIndex,
        },
      };
    },
    setQuantity(
      state,
      {
        payload,
      }: {
        payload: { packageId: string; quantity: PackageEditorQuantityRecord };
      }
    ) {
      const entry = state[payload.packageId] ?? fallbackPackageEditorEntry;
      return {
        ...state,
        [payload.packageId]: { ...entry, quantity: payload.quantity },
      };
    },
    setTabIndex(
      state,
      {
        payload,
      }: {
        payload: {
          packageId: string;
          tabIndex: number;
        };
      }
    ) {
      const entry = state[payload.packageId] ?? fallbackPackageEditorEntry;
      return {
        ...state,
        [payload.packageId]: { ...entry, tabIndex: payload.tabIndex },
      };
    },
    setVehicleManufacturers(
      state,
      {
        payload,
      }: {
        payload: {
          packageId: string;
          vehicleManufacturers: VehicleManufacturerEntry[];
        };
      }
    ) {
      const entry = state[payload.packageId] ?? fallbackPackageEditorEntry;
      return {
        ...state,
        [payload.packageId]: {
          ...entry,
          forceVehicleLoaderMode: false,
          vehicleManufacturers: payload.vehicleManufacturers,
        },
      };
    },
    setVehicleMapping(
      state,
      {
        payload,
      }: {
        payload: { packageId: string; vehicleMapping: string[] };
      }
    ) {
      const entry = state[payload.packageId] ?? fallbackPackageEditorEntry;
      return {
        ...state,
        [payload.packageId]: {
          ...entry,
          vehicleMapping: payload.vehicleMapping,
        },
      };
    },
    toggleVehicleManufacturerId(
      state,
      {
        payload,
      }: {
        payload: { packageId: string; vehicleManufacturerId: number };
      }
    ) {
      const entry = state[payload.packageId] ?? fallbackPackageEditorEntry;
      const vehicleManufacturerIds = entry.vehicleManufacturerIds.includes(
        payload.vehicleManufacturerId
      )
        ? entry.vehicleManufacturerIds.filter(
            (id) => id !== payload.vehicleManufacturerId
          )
        : [...entry.vehicleManufacturerIds, payload.vehicleManufacturerId];
      return {
        ...state,
        [payload.packageId]: {
          ...entry,
          vehicleManufacturerIds,
        },
      };
    },
    toggleYearId(
      state,
      {
        payload,
      }: {
        payload: { packageId: string; yearId: number };
      }
    ) {
      const entry = state[payload.packageId] ?? fallbackPackageEditorEntry;
      const vehicleYearIds = entry.vehicleYearIds.includes(payload.yearId)
        ? entry.vehicleYearIds.filter((id) => id !== payload.yearId)
        : [...entry.vehicleYearIds, payload.yearId];
      return {
        ...state,
        [payload.packageId]: {
          ...entry,
          vehicleManufacturerIds: [],
          vehicleYearIds,
        },
      };
    },
    updateCustomPart(
      state,
      {
        payload,
      }: {
        payload: { customPart: CustomPart; packageId: string };
      }
    ) {
      const entry = state[payload.packageId] ?? fallbackPackageEditorEntry;
      const customParts = entry.customParts.map((part) => {
        if (part.customPartNumber === payload.customPart.customPartNumber) {
          return payload.customPart;
        }
        return part;
      });
      return {
        ...state,
        [payload.packageId]: {
          ...entry,
          customParts,
        },
      };
    },
  },
});

export const packageEditor = packageEditorSlice.reducer;
