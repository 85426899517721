import React, { FunctionComponent, SVGProps } from "react";

export const TrashIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      {...props}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M8.16 2.159A2.25 2.25 0 0 1 9.75 1.5h4.5a2.25 2.25 0 0 1 2.25 2.25v.75h2.25s-.001 0 0 0h1.5a.75.75 0 0 1 0 1.5h-.75v13.5A1.5 1.5 0 0 1 18 21H6a1.5 1.5 0 0 1-1.5-1.5V6h-.75a.75.75 0 1 1 0-1.5H7.5v-.75c0-.597.237-1.169.66-1.591ZM6 6v13.5h12V6H6Zm9-1.5H9v-.75A.75.75 0 0 1 9.75 3h4.5a.75.75 0 0 1 .75.75v.75ZM9.75 9a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0v-6A.75.75 0 0 1 9.75 9Zm4.5 0a.75.75 0 0 1 .75.75v6a.75.75 0 0 1-1.5 0v-6a.75.75 0 0 1 .75-.75Z"
        fill="#64748B"
        fillRule="evenodd"
      />
    </svg>
  );
};
