import { RefObject, useEffect, useRef, useState } from "react";

interface IdRefHook {
  (id: string): RefObject<HTMLElement | null>;
}

export const useIdRef: IdRefHook = (id) => {
  const ref = useRef<HTMLElement | null>(null);
  const [, update] = useState({});

  useEffect(() => {
    const node = document.getElementById(id);
    ref.current = node;
    update({});
  }, [id]);

  return ref;
};
