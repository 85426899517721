import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet-async";

import { useTypedSelector } from "../../stores/hooks";
import { reduxStore } from "../../stores/reduxStore";
import { patch } from "../../stores/slices/captchaSlice";
import { useBodyClass } from "../../util/useBodyClass";

export const recapthcaSitekey = "6LdLczAUAAAAAOnkIrf9yCMlcEkceEw4KzQReMEs";

let isLoaded = false;

export const isCaptchaLoaded = (): boolean => isLoaded;

export const grecaptchaExecute = (): void => {
  grecaptcha.ready(() => {
    console.log("grecaptcha loading...");
    const action = patch({ loading: true });
    reduxStore.dispatch(action);
    grecaptcha.execute();
  });
};

window.greCaptchaLoadCallback = (): void => {
  console.log("grecaptcha loaded");
  isLoaded = true;
};

window.greCaptchaExpiredCallback = (): void => {
  console.log("grecaptcha token expired");
  const action = patch({ loading: false, token: null });
  reduxStore.dispatch(action);
};

window.greCaptchaTokenCallback = (token: string): void => {
  console.log("grecaptcha token updated:", token);
  const action = patch({ loading: false, token });
  reduxStore.dispatch(action);
};

export const ReCaptcha: FunctionComponent = () => {
  const isLoading = useTypedSelector((state) => state.captcha.loading);

  useBodyClass(isLoading ? "tw-rc-on" : "tw-rc-off");

  return (
    <>
      <Helmet>
        <script
          async
          defer
          src={`https://www.google.com/recaptcha/api.js?onload=greCaptchaLoadCallback&render=${recapthcaSitekey}`}
        />
      </Helmet>
      <div
        className="g-recaptcha"
        data-callback="greCaptchaTokenCallback"
        data-expired-callback="greCaptchaExpiredCallback"
        data-sitekey={recapthcaSitekey}
        data-size="invisible"
      />
    </>
  );
};

declare global {
  interface Window {
    greCaptchaExpiredCallback: VoidFunction;
    greCaptchaLoadCallback: VoidFunction;
    greCaptchaTokenCallback: (token: string) => void;
  }
}
