import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";

import { LinearProgress } from "../../ui/LinearProgress";
import { loadMaps } from "./googleMapsLoader";

export const WithGoogleMaps: FunctionComponent<{ children: ReactNode }> = ({
  children,
}) => {
  const [done, setDone] = useState(Boolean(window.google?.maps));

  useEffect(() => {
    loadMaps().then(() => {
      setDone(true);
    });
  }, []);

  return done ? <>{children}</> : <LinearProgress className="h-0.5" />;
};
