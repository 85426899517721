import React, { FunctionComponent, ReactNode } from "react";
import { Provider } from "react-redux";

import { reduxStore } from "./reduxStore";

type ReduxProviderProps = {
  children: ReactNode;
};

export const ReduxProvider: FunctionComponent<ReduxProviderProps> = ({
  children,
}) => {
  return <Provider store={reduxStore}>{children}</Provider>;
};
