import React, {
  Component,
  ErrorInfo,
  FunctionComponent,
  ReactNode,
} from "react";
import { useTranslation } from "react-i18next";

import { bugsnagNotify } from "../../util/bugsnag";

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_error: unknown): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    bugsnagNotify(error, (event) => {
      event.addMetadata("reactError", errorInfo);
    });
  }

  render(): JSX.Element {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return <>{this.props.children}</>;
  }
}

const ErrorPage: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      <h1>{t("error.boundary.heading")}</h1>
      <p>{t("error.boundary.message")}</p>
    </div>
  );
};
