import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { config } from "../../config/config";
import {
  createQueryCustomHook,
  createQueryNumberHook,
  createQueryStringHook,
} from "../../stores/useQueryState";
import { Button } from "../../ui/Button/Button";
import { Input } from "../../ui/Input/Input";
import { InputGroup } from "../../ui/IputGroup/IputGroup";
import { RadioButton } from "../../ui/RadioButton/RadioButton";
import { Select } from "../../ui/Select/Select";
import { MagnifyingGlassIcon } from "../../ui/icons/MagnifyingGlassIcon";
import { useForm } from "../../util/useForm";

const options = [0, 10, 25, 50, 75, 100, 150, 200, 250, 300] as const;

export const dealershipModeLocality = "locality";
export const dealershipModeDealer = "dealer";

export const useDealershipsMode = createQueryCustomHook("mode", () => {
  return (value): string => {
    return value === dealershipModeDealer
      ? dealershipModeDealer
      : dealershipModeLocality;
  };
});

export const useDealershipsCriteria = createQueryStringHook("q", "");

export const useDealershipsDistance = createQueryNumberHook("distance", 150);

const schema = z.object({
  criteria: z.string(),
});

export const DealershipForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const [mode, setMode] = useDealershipsMode();
  const [criteria, setCriteria] = useDealershipsCriteria();
  const [distance, setDistance] = useDealershipsDistance();
  const [hasCriteria, setHasCriteria] = useState(!!criteria.trim());
  const [handleForm] = useForm({
    onSubmit({ criteria }) {
      setCriteria(criteria);
    },
    schema,
  });

  return (
    <section className="lg:flex lg:justify-between lg:items-center">
      <h1 className="mb-4 lg:mb-0">
        {t("national.selectDealership", { brand: config.brandName })}
      </h1>
      <form
        onSubmit={(event): void => {
          event.preventDefault();
        }}
        className="grid gap-2 md:gap-4 md:flex md:items-center"
      >
        <label className="flex items-center gap-2 text-ink-2 font-thin">
          <RadioButton
            onChange={(): void => {
              setMode(dealershipModeLocality);
            }}
            checked={mode === dealershipModeLocality}
            name="mode"
            value={dealershipModeLocality}
          />{" "}
          {t("national.postalCodeOrCity")}
        </label>
        <label className="flex items-center gap-2 text-ink-2 font-thin">
          <RadioButton
            onChange={(): void => {
              setMode(dealershipModeDealer);
            }}
            checked={mode === dealershipModeDealer}
            name="mode"
            value={dealershipModeDealer}
          />{" "}
          {t("national.dealer")}
        </label>
        <form noValidate onSubmit={handleForm}>
          <InputGroup className="bg-paper">
            <Input
              onChange={(event): void => {
                const nextCriteria = event.target.value.trim();
                setHasCriteria(!!nextCriteria);
                if (!nextCriteria) {
                  setCriteria(nextCriteria);
                }
              }}
              aria-label="searchCriteria"
              defaultValue={criteria}
              name="criteria"
              placeholder={t("national.search")}
            />
            <Button
              aria-label="searchDealers"
              className="w-11 h-11 md:w-14 md:h-14 px-0 py-0 rounded-input rounded-l-none shrink-0 text-xl font-thin"
              disabled={!hasCriteria}
              type="submit"
            >
              <MagnifyingGlassIcon />
            </Button>
          </InputGroup>
        </form>
        {mode === dealershipModeLocality && (
          <Select
            onChange={(event): void => {
              const nextDistance = Number(event.target.value);
              setDistance(nextDistance);
            }}
            aria-label="distance"
            className="bg-paper"
            value={distance}
          >
            {options.map((option) => (
              <option key={option} value={option}>
                {option === 0
                  ? t("national.anyDistance")
                  : t("national.withinDistance", { option })}
              </option>
            ))}
          </Select>
        )}
      </form>
    </section>
  );
};
