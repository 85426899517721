import { TirePartItem } from "../queries/useSearchTiresByVehicle";

export const getTiresizeAndClass = (
  part?: TirePartItem | null,
  isRear?: boolean
): null | string => {
  if (!part) {
    return null;
  }

  const primaryPart = isRear
    ? part.partDetails.secondaryParts[0]
    : part.partDetails.primaryPart;

  return [primaryPart.tireVehicleClass, primaryPart.formattedTiresize]
    .filter(Boolean)
    .join("")
    .trim();
};
