/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { useState } from "react";

import { FetcherResult, fetcher } from "./fetcher";

export type UrlParameters = Record<string, string>;

export type GetHookResult = {
  get: <Data>(
    path: string,
    parameters?: UrlParameters
  ) => Promise<FetcherResult<Data>>;
  isLoading: boolean;
};

export const useGet = (): GetHookResult => {
  const [isLoading, setIsLoading] = useState(false);
  return {
    get<Data>(path: string, parameters?: UrlParameters) {
      setIsLoading(true);
      const url = [
        path,
        parameters &&
          new URLSearchParams({
            ...parameters,
          }),
      ]
        .filter(Boolean)
        .join("?");
      return fetcher<Data>(url).finally(() => {
        setIsLoading(false);
      });
    },
    isLoading,
  };
};
