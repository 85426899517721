const isPlainObject = (value: unknown): boolean => {
  if (typeof value !== "object" || value === null) return false;
  let proto = value;
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto);
  }
  return Object.getPrototypeOf(value) === proto;
};

const decodeString = (value: string): string => {
  const replaced = value
    .replace(new RegExp("&#39;", "g"), "'")
    .replace(new RegExp("<br />", "g"), "\n")
    .replace(new RegExp("<br/>", "g"), "\n");
  const textDecoder = new TextDecoder("utf-8");
  const bytes = Uint8Array.from(
    replaced.split("").map((char, index) => replaced.charCodeAt(index))
  );
  return textDecoder.decode(bytes);
};

export const decodePayload = <Result>(value: unknown): Result => {
  if (typeof value === "string") {
    return decodeString(value) as unknown as Result;
  }
  if (Array.isArray(value)) {
    return value.map(decodePayload) as unknown as Result;
  }
  if (isPlainObject(value)) {
    const nextValue = {};
    for (const key in value as Record<string, unknown>) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      nextValue[key] = decodePayload(value[key]);
    }
    return nextValue as unknown as Result;
  }
  return value as unknown as Result;
};
