export const getApiBaseUrl = (): string => {
  switch (window.environment) {
    case "DEV":
      return "http://localhost";
    case "PRODUCTION":
      return "https://api.tirelocator.ca";
    case "QA":
      return "https://api.qa-tirelocator.space";
    case "STAGE":
      return "https://api.stage-tirelocator.space";
    case "TRY":
      return "https://api.try-tirelocator.space";
    case "TIGHTEN":
      return "https://tirelocator.tgtn.co";
    case "TLV2":
      return "https://tlv2api.qa-tirelocator.space";
    case "LARAVEL":
      return "https://tighten-stage.herokuapp.com";
    default:
      return "https://api.qa-tirelocator.space";
  }
};
