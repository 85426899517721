import { createSlice } from "@reduxjs/toolkit";

const name = "consumerAccountInfo";

export const consumerAccountInfoSlice = createSlice({
  initialState: window.consumerAccountInfoPayload,
  name,
  reducers: {},
});

export const _consumerAccountInfo = consumerAccountInfoSlice.reducer;

// TODO: add data type
type ConsumerAccountInfoPayload = null;

declare global {
  interface Window {
    consumerAccountInfoPayload: ConsumerAccountInfoPayload;
  }
}
