import { RefObject, useEffect, useState } from "react";

import { matchElement, subscribe } from "../../helpers";

interface DetectAutofillHook {
  (
    ref: RefObject<HTMLInputElement | HTMLTextAreaElement>,
    matchers?: string[]
  ): boolean;
}

const defaultMatchers = [
  ":autofill",
  ":-internal-autofill-selected",
  ":-webkit-autofill",
];

export const useDetectAutofill: DetectAutofillHook = (
  ref,
  matchers = defaultMatchers
) => {
  const [autofilled, setAutofilled] = useState(false);

  useEffect(() => {
    const clear = (): void => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
    const intervalId = setInterval(() => {
      const matched = matchElement(ref.current, matchers);
      if (matched) {
        setAutofilled(matched);
        clear();
      }
    }, 64);

    const timeoutId = setTimeout(() => {
      clear();
    }, 2600);

    const unsubscribe = subscribe(ref.current, "input", () => {
      setAutofilled(false);
      clear();
    });

    return () => {
      clear();
      unsubscribe();
    };
  }, [ref]);

  return autofilled;
};
