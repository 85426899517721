import {
  AnyPartType,
  partTypeAccessory,
  partTypePackage,
  partTypeTire,
  partTypeWheel,
} from "../constants/partTypes";
import {
  AnyProductType,
  productTypeAccessory,
  productTypePackage,
  productTypeTire,
  productTypeWheel,
} from "../constants/productTypes";

const map = {
  [partTypeAccessory]: productTypeAccessory,
  [partTypePackage]: productTypePackage,
  [partTypeTire]: productTypeTire,
  [partTypeWheel]: productTypeWheel,
};

export const partTypeToProductType = (
  partType?: null | string
): AnyProductType | null => {
  return (map[partType as AnyPartType] as AnyProductType) ?? null;
};
