import clsx from "clsx";
import React, {
  ComponentProps,
  ForwardRefRenderFunction,
  ReactNode,
  forwardRef,
  useMemo,
} from "react";

type PlaceholderProps = {
  animationDelay?: string;
  children?: ReactNode;
  className?: string;
  loading?: boolean;
};

const PlaceholderWithoutRef: ForwardRefRenderFunction<
  HTMLSpanElement,
  PlaceholderProps & ComponentProps<"span">
> = (
  { animationDelay, children, className, loading, style, ...rest },
  forwarderRef
) => {
  const delay = useMemo(
    () => animationDelay ?? `${Math.random() * 5}s`,
    [animationDelay]
  );
  if (loading ?? typeof children === "undefined") {
    return (
      <span
        {...rest}
        className={clsx("tw-placeholder", className)}
        ref={forwarderRef}
        style={{ animationDelay: delay, ...style }}
      />
    );
  }
  return children as unknown as JSX.Element;
};

export const Placeholder = forwardRef(PlaceholderWithoutRef);
