import clsx from "clsx";
import React, { FunctionComponent, ReactNode } from "react";

type KeyValueProps = {
  children: [ReactNode, ReactNode];
  className?: string;
  keyClassName?: string;
  valueClassName?: string;
};

export const KeyValue: FunctionComponent<KeyValueProps> = ({
  children: [key, value],
  className,
  keyClassName,
  valueClassName,
}) => {
  return (
    <span className={clsx("tw-key-value", className)}>
      <span className={clsx("tw-key-value__key", keyClassName)}>{key}</span>
      <span className={clsx("tw-key-value__value", valueClassName)}>
        {value}
      </span>
    </span>
  );
};
