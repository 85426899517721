import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { createSessionStorage } from "../../util/createSessionStorage";

export type PricingAnalysisFormState = {
  brand: string;
  season: string;
  tireSize: string;
  version: number;
};

const defaultState: PricingAnalysisFormState = {
  brand: "",
  season: "",
  tireSize: "",
  version: 0,
};

const name = "pricingAnalysisForm";

const pricingAnalysisStorage = createSessionStorage(name, defaultState);

export const pricingAnalysisSlice = createSlice({
  initialState: pricingAnalysisStorage.get(),
  name,
  reducers: {
    patch(
      state,
      { payload }: PayloadAction<Partial<PricingAnalysisFormState>>
    ) {
      const nextState = { ...state, ...payload, version: state.version + 1 };
      pricingAnalysisStorage.set(nextState);
      return nextState;
    },
  },
});

export const pricingAnalysis = pricingAnalysisSlice.reducer;

export const patchPricingAnalysis = pricingAnalysisSlice.actions.patch;
