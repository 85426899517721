import { createSlice } from "@reduxjs/toolkit";

const name = "application";

export const applicationSlice = createSlice({
  initialState: {
    appTabIndex: window.appTabIndex,
    appVersion: window.appVersion,
    cmsBaseUrl: window.cmsBaseUrl,
    environment: window.environment,
  },
  name,
  reducers: {},
});

export const _application = applicationSlice.reducer;

export type ApplicationInfo = {
  appTabIndex: number;
  appVersion: string;
  cmsBaseUrl: string;
  environment: string;
};

declare global {
  interface Window {
    appTabIndex: number;
    appVersion: string;
    cmsBaseUrl: string;
    environment: string;
  }
}
