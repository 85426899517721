export const productTypeAccessory = "ACCESSORIES";
export const productTypePackage = "PACKAGES";
export const productTypeTire = "TIRES";
export const productTypeWheel = "ALLOY_WHEELS";

export type ProductTypeAccessory = typeof productTypeAccessory;
export type ProductTypePackage = typeof productTypePackage;
export type ProductTypeTire = typeof productTypeTire;
export type ProductTypeWheel = typeof productTypeWheel;

export type AnyProductType =
  | ProductTypeAccessory
  | ProductTypePackage
  | ProductTypeTire
  | ProductTypeWheel;
