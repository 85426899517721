import { CLICK, FOCUS, ROLLOVER, TOAST, ToggleMode } from "./useToggle";

interface SuggestMode {
  (
    producerNode: HTMLElement | null,
    consumerNode: HTMLElement | null
  ): NonNullable<ToggleMode>;
}

export const suggestMode: SuggestMode = (producerNode, consumerNode) => {
  if (producerNode?.nodeName === "INPUT") {
    return FOCUS;
  }

  if (consumerNode?.classList.contains("tw-tooltip")) {
    return ROLLOVER;
  }
  if (consumerNode?.classList.contains("tw-toast")) {
    return TOAST;
  }

  return CLICK;
};
