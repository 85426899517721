import { FetcherResult } from "../api-client/fetcher";
import { PostResponse, usePost } from "../api-client/usePost";
import { useCartNext } from "../stores/hooks/useCartNext";
import { useDealerAccountInfo } from "../stores/hooks/useDealerAccountInfo";
import { AnyOrderItem, OrderDetails } from "../stores/slices/cartSlice";
import { useGetNewCart } from "./useGetNewCart";

type RemoveItemFromCart = (
  orderItem: Pick<AnyOrderItem, "orderItemId">
) => Promise<FetcherResult<OrderDetails>>;

export const useRemoveItemFromCart = (): Omit<
  PostResponse<OrderDetails>,
  "send"
> & {
  send: RemoveItemFromCart;
} => {
  const init = useGetNewCart();
  const { send: removeItemFromCart, ...post } = usePost<OrderDetails>(
    "/retailSiteWebService/shopping/cart/removeItemFromCart.php"
  );
  const { dealerId } = useDealerAccountInfo();
  const cartNext = useCartNext();
  const send: RemoveItemFromCart = async ({ orderItemId }) => {
    const { orderNumber } = await init();
    const response = await removeItemFromCart({
      cartId: orderNumber,
      cartItemId: orderItemId,
      dealerId,
      isNational: "0",
      orderNumber,
    });
    cartNext(response.payload);
    return response;
  };
  return { ...post, send };
};
