/* eslint-disable react/display-name */
import clsx from "clsx";
import React, { InputHTMLAttributes, forwardRef } from "react";

type RadioButtonProps = Omit<InputHTMLAttributes<HTMLInputElement>, "type"> & {
  checkedClassName?: string;
  className?: string;
};

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ checked, checkedClassName, className, ...props }, ref) => {
    return (
      <input
        ref={ref}
        type="radio"
        {...props}
        className={clsx(
          "tw-radiobutton",
          className,
          checked && checkedClassName
        )}
        checked={checked}
      />
    );
  }
);
