import { useDispatch } from "react-redux";

import { ToastItem, ToastTypeError, toastSlice } from "../slices/toastSlice";

export const useSetErrorToast = (): ((error: Error) => void) => {
  const dispatch = useDispatch();
  return (error) => {
    const payload: ToastItem = {
      message: error.message,
      ts: Date.now(),
      type: ToastTypeError,
    };
    const action = toastSlice.actions.set(payload);
    dispatch(action);
  };
};
