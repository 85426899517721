import { clsx } from "clsx";
import React, { FunctionComponent } from "react";

import { partTypePackage, partTypeTire } from "../../../constants/partTypes";
import { PackagePartItem } from "../../../queries/useSearchPartPackagesByVehicle";
import { Image } from "../../../ui/Image/Image";
import { fixTireImageUrl } from "../../../util/fixTireImageUrl";
import { getPartFallbackSrc } from "../../../util/getPartFallbackSrc";

type PackageImagesProps = {
  className?: string;
  part: PackagePartItem | null;
  select?: VoidFunction;
};

export const PackageImages: FunctionComponent<PackageImagesProps> = ({
  className,
  part,
  select,
}) => {
  return (
    <>
      <Image
        src={fixTireImageUrl(
          part?.partTire?.partDetails.primaryPart.tireImageLink
        )}
        alt="tire image"
        className={clsx("aspect-square rounded-tw-lg scale-x-[-1]", className)}
        errorClassName="opacity-20 blur-[8px]"
        fallbackSrc={getPartFallbackSrc(partTypeTire)}
        onClick={select}
        pulsate={!part}
      />
      <Image
        src={
          part?.partDetails.primaryPart.packageImageLink ||
          part?.partWheel?.partDetails.primaryPart.wheelImageLink
        }
        alt="wheel image"
        className={clsx("aspect-square rounded-tw-lg", className)}
        errorClassName="opacity-20 blur-[8px]"
        fallbackSrc={getPartFallbackSrc(partTypePackage)}
        onClick={select}
        pulsate={!part}
      />
    </>
  );
};
