import { DealerEntryWithGeocoderResultAndDistance } from "../../queries/useGetNationalAccountDealerList";

export const getDealerAddress = (
  dealer: DealerEntryWithGeocoderResultAndDistance | null
): string => {
  if (!dealer) {
    return "";
  }

  return [dealer.dealerAddress, dealer.dealerCity, dealer.dealerState]
    .filter(Boolean)
    .join(", ");
};
