import React, { FunctionComponent } from "react";
import { Redirect, Route, Router, Switch, useRoute } from "wouter";

import { useLanguage } from "../i18n/useLanguage";
import { GroupLandingPage } from "../pages/GroupLandingPage";
import { NationalCampaignRedirect } from "../pages/NationalCampaignRedirect";
import { NationalResultsPage } from "../pages/NationalResultsPage";
import { NationalVendorLandingPage } from "../pages/NationalVendorLandingPage";
import { NationalVendorPage } from "../pages/NationalVendorPage";
import { useNullableGroupAccountInfo } from "../stores/hooks/useNullableGroupAccountInfo";
import { useNullableNationalAccountInfo } from "../stores/hooks/useNullableNationalAccountInfo";

export const NationalVendorRoute = "/national/:vendor/:language";
export const NationalVendorLandingRoute =
  "/national/:vendor/:language/landingpage";

export const NationalVehicleResultsRoute =
  "/national/:vendor/:language/product/:productType/type/:searchType/year/:vehicleYear/make/:vehicleManufacturerName/category/:vehicleModelClassId/model/:vehicleModelName/trim/:vehicleSubmodel";
export const NationalTiresizeResultsRoute =
  "/national/:vendor/:language/search/tiresize-search/:tireSize";

export const NationalCampaignRoute =
  "/marketing/national/:vendor/campaign/:campaignNumber/dealerNumber/:dealerId/:language";

export const DealerGroupHomeRoute = "/group/:dealerGroupUrlCode";
export const DealerGroupLandingPageRoute =
  "/group/:dealerGroupUrlCode/:language/landingpage";

export const AllNationalRoutes = [
  NationalVendorRoute,
  NationalVendorLandingRoute,
  NationalVehicleResultsRoute,
  NationalTiresizeResultsRoute,
  DealerGroupHomeRoute,
  DealerGroupLandingPageRoute,
];

export const NationalRoutes: FunctionComponent = () => {
  const national = useNullableNationalAccountInfo();
  const group = useNullableGroupAccountInfo();
  const [campaign] = useRoute(NationalCampaignRoute);
  const [language] = useLanguage();
  return (
    <Router>
      <Switch>
        {campaign && (
          <>
            <Route
              component={NationalCampaignRedirect}
              path={NationalCampaignRoute}
            />
          </>
        )}
        {national && (
          <>
            <Route path="/national/:vendor">
              <Redirect
                to={`/national/${national.nationalAccountUrlCode}/${language}`}
              />
            </Route>
            <Route component={NationalVendorPage} path={NationalVendorRoute} />
            <Route
              component={NationalVendorLandingPage}
              path={NationalVendorLandingRoute}
            />
            <Route
              component={NationalResultsPage}
              path={NationalVehicleResultsRoute}
            />
            <Route
              component={NationalResultsPage}
              path={NationalTiresizeResultsRoute}
            />
          </>
        )}
        {group && (
          <>
            <Route path={DealerGroupHomeRoute}>
              <Redirect
                to={`/group/${group.dealerGroupUrlCode}/${language}/landingpage`}
              />
            </Route>
            <Route
              component={GroupLandingPage}
              path={DealerGroupLandingPageRoute}
            />
          </>
        )}
        <Route>Not Found</Route>
      </Switch>
    </Router>
  );
};
