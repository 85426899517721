import { useIsLoggedOn } from "./useIsLoggedOn";
import { useNullableDealerAccountInfo } from "./useNullableDealerAccountInfo";

export const useDealerChassisSetting = (): boolean => {
  const dealer = useNullableDealerAccountInfo();
  const isLoggedOn = useIsLoggedOn();

  if (dealer?.dealerEnableAdvancedSearchVehicleChassis === "SHOW") {
    return true;
  }

  if (
    isLoggedOn &&
    dealer?.dealerEnableAdvancedSearchVehicleChassis === "AUTH"
  ) {
    return true;
  }

  return false;
};
