export const queryParameterToNumberArray = <State extends number[]>(
  value: (null | string)[] | null | string,
  fallbackValue: State
): State => {
  if (Array.isArray(value)) {
    return value.filter(Boolean).map(Number) as State;
  }
  if (typeof value === "string" && value.length > 0) {
    return [Number(value)] as State;
  }
  return fallbackValue;
};
