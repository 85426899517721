import {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { bulk, subscribe } from "../helpers";
import {
  useCombineRefs,
  useEscapeKey,
  useEventOutsideRefs,
  useLatestRef,
} from "../hooks";
import { ToggleProps } from "./Toggle";
import { useToggleContext } from "./ToggleContext";
import { getComponentProps } from "./getComponentProps";
import { suggestMode } from "./suggestMode";

export const CLICK = "click";
export const FOCUS = "focus";
export const ROLLOVER = "rollover";
export const TOAST = "toast";

interface ToggleHook {
  (props: ToggleProps): {
    close: VoidFunction;
    consumer: ReactNode;
    consumerId?: string;
    consumerRef: RefObject<HTMLElement | null>;
    mode: NonNullable<ToggleMode>;
    producer: ToggleProps["children"][0];
    producerRef: RefObject<HTMLElement | null>;
    setChildToggleIsVisible: (isVisible: boolean | null) => void;
    visible: boolean;
  };
}

export type ToggleMode = ToggleProps["mode"];

export const useToggle: ToggleHook = ({
  activeProducerClassName,
  children,
  disabled,
  mode,
  onToggle,
}) => {
  const callbackRef = useLatestRef(onToggle);
  const mountedRef = useRef<boolean>(false);
  const [producer, consumer] = children;
  const producerRef = useCombineRefs(producer.ref, useRef<HTMLElement>(null));
  const consumerRef = useRef<HTMLElement | null>(null);
  const consumerProps = getComponentProps(consumer);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const customerIsVisible =
    typeof consumerProps.visible === "boolean" ? consumerProps.visible : null;
  const [hasCursor, setHasCursor] = useState(customerIsVisible || false);
  const [, update] = useState({});
  const context = useToggleContext();
  const [childToggleIsVisible, setChildToggleIsVisible] = useState<
    boolean | null
  >(null);

  const visible: boolean = hasFocus || hasCursor;

  const resultingMode =
    mode || suggestMode(producerRef.current, consumerRef.current);

  const setFocus: typeof setHasFocus = useCallback(
    (nextState) => {
      if (!disabled) {
        setHasFocus(nextState);
      }
    },
    [disabled]
  );

  const setCursor: typeof setHasCursor = useCallback(
    (nextState) => {
      if (!disabled) {
        setHasCursor(nextState);
      }
    },
    [disabled]
  );

  const close = useCallback((): void => {
    setFocus(false);
    setCursor(false);
  }, [setFocus, setCursor]);

  useEventOutsideRefs(
    [producerRef, consumerRef],
    ["mousedown", "touchstart"],
    (event) => {
      if (
        visible &&
        event.target !== producerRef.current &&
        !childToggleIsVisible
      ) {
        close();
      }
    },
    { deps: [visible, producerRef, childToggleIsVisible, close] }
  );

  useEventOutsideRefs(
    [producerRef, consumerRef],
    ["focusin"],
    () => {
      if (visible && resultingMode === FOCUS) {
        setFocus(false);
      }
    },
    { deps: [visible, setFocus, resultingMode] }
  );

  useLayoutEffect(() => {
    update({});
  }, []);

  useEscapeKey(() => {
    close();
    if (resultingMode === FOCUS) {
      producerRef.current?.blur();
    }
  }, visible && !childToggleIsVisible);

  useEffect(() => {
    const toggle = (): void => {
      setFocus((lastState) => !lastState);
    };
    const handleFocus = (): void => {
      setFocus(true);
    };
    const handleBlur = (event: Event): void => {
      const target = event.target as HTMLElement;
      if (target.getAttribute("aria-haspopup") !== "true") {
        setFocus(false);
      }
    };
    const handleMouseOver = (): void => {
      setCursor(true);
    };
    const handleMouseOut = (): void => {
      setCursor(false);
    };

    const unsubscribe: VoidFunction[] = [];

    switch (resultingMode) {
      case ROLLOVER:
        unsubscribe.push(
          subscribe(producerRef.current, "mouseover", handleMouseOver),
          subscribe(producerRef.current, "mouseout", handleMouseOut),
          subscribe(producerRef.current, FOCUS, handleFocus),
          subscribe(producerRef.current, "blur", handleBlur)
        );
        break;
      case FOCUS:
        unsubscribe.push(
          subscribe(producerRef.current, FOCUS, handleFocus),
          subscribe(consumerRef.current, CLICK, handleBlur)
        );
        break;
      case CLICK:
      case TOAST:
      default:
        unsubscribe.push(subscribe(producerRef.current, CLICK, toggle));
        break;
    }
    return () => {
      bulk(...unsubscribe);
    };
  }, [resultingMode, producerRef.current, consumerRef.current, disabled]);

  useEffect(() => {
    if (customerIsVisible) {
      const event = new CustomEvent(resultingMode, { bubbles: true });
      producerRef.current?.focus();
      producerRef.current?.dispatchEvent(event);
    }
  }, [resultingMode, customerIsVisible]);

  useEffect(() => {
    if (setChildToggleIsVisible !== context.setChildToggleIsVisible) {
      context.setChildToggleIsVisible(visible);
    }
    if (activeProducerClassName && producerRef.current) {
      if (visible) {
        producerRef.current.classList.add(activeProducerClassName);
      } else {
        producerRef.current.classList.remove(activeProducerClassName);
      }
    }
    if (callbackRef.current && mountedRef.current) {
      callbackRef.current(visible);
    }
    if (!mountedRef.current) {
      mountedRef.current = true;
    }
  }, [visible]);

  return {
    close,
    consumer,
    consumerId: consumerProps.id,
    consumerRef,
    mode: resultingMode,
    producer,
    producerRef,
    setChildToggleIsVisible,
    visible,
  };
};
