import clsx from "clsx";
import React, { ElementType, ReactNode, Ref, forwardRef } from "react";
import { useTranslation } from "react-i18next";

import {
  PolymorphicComponent,
  PolymorphicComponentProps,
} from "../PolymorphicComponent";
import { Spinner } from "../Spinner";

export type ButtonProps = {
  "aria-label": string;
  busy?: boolean;
  busyIndicator?: ReactNode;
  className?: string;
  defaultClassName?: string;
  disabled?: boolean;
  selected?: boolean;
  selectedClassName?: string;
};

export type ButtonConfig<E extends ElementType = typeof defaultElement> =
  PolymorphicComponentProps<E, Partial<ButtonProps>>;

const defaultElement = "button";

export const createButton = <C extends ElementType = typeof defaultElement>({
  "aria-label": configAriaLabel,
  className: configClassName,
  defaultClassName: configDefaultClassName,
  element: configElement,
  selectedClassName: configSelectedClassName,
  style: configStyle,
  type: configType,
  ...config
}: ButtonConfig<C>): PolymorphicComponent<C, ButtonProps> => {
  const Button = forwardRef(
    <E extends ElementType = C>(
      {
        "aria-label": ariaLabel,
        busy,
        busyIndicator = <Spinner />,
        children,
        className,
        defaultClassName = configDefaultClassName,
        disabled,
        element,
        selected,
        selectedClassName = configSelectedClassName,
        style,
        type = configType,
        ...rest
      }: PolymorphicComponentProps<E, ButtonProps>,
      ref: Ref<Element>
    ) => {
      const Tag: ElementType = configElement || element || defaultElement;
      const { t } = useTranslation();

      return (
        <Tag
          aria-busy={busy}
          aria-label={t(`aoda.buttonLabel.${ariaLabel ?? configAriaLabel}`)}
          aria-selected={selected}
          {...config}
          {...rest}
          className={clsx(
            "tw-button",
            busy && "tw-button--busy",
            disabled && "tw-button--disabled",
            configClassName,
            className,
            selected ? selectedClassName : defaultClassName
          )}
          disabled={Tag === defaultElement ? disabled || busy : undefined}
          ref={ref}
          style={{ ...configStyle, ...style }}
          type={Tag === defaultElement ? type || defaultElement : undefined}
        >
          {busy ? (
            <>
              <span>{children}</span>
              <span>{busyIndicator}</span>
            </>
          ) : (
            <span>{children}</span>
          )}
        </Tag>
      );
    }
  ) as PolymorphicComponent<C, ButtonProps>;

  Button.displayName = "TWButton";

  return Button;
};
