import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { useGetNationalAccountDealerList } from "../../queries/useGetNationalAccountDealerList";
import { Pagination } from "../../ui/Pagination/Pagination";
import { NoData } from "../NoData/NoData";
import { DealershipTile } from "./DealershipTile";

const fallback = [null, null, null, null, null, null];

export const DealershipList: FunctionComponent = () => {
  const { t } = useTranslation();
  const { data = fallback, isLoading } = useGetNationalAccountDealerList();
  const dealerList = isLoading ? fallback : data;
  const hasData = Boolean(dealerList.length);
  return (
    <section
      className={clsx(
        hasData && "grid gap-2 md:gap-4 lg:gap-6 sm:grid-cols-2 lg:grid-cols-3"
      )}
    >
      <Pagination className="sm:col-span-2 lg:col-span-3">
        {dealerList.map((item, key) => (
          <DealershipTile dealer={item} key={key} />
        ))}
      </Pagination>
      {!hasData && <NoData>{t("national.nodata")}</NoData>}
    </section>
  );
};
