/* eslint-disable react/prop-types */
import React, { FunctionComponent } from "react";

import { HomepageCarousel } from "../blocks/Hero/HomepageCarousel";
import { Meta } from "../blocks/Meta/Meta";
import { config } from "../config/config";
import { useLanguage } from "../i18n/useLanguage";
import { useNullableNationalAccountInfo } from "../stores/hooks/useNullableNationalAccountInfo";

export const NationalVendorLandingPage: FunctionComponent = () => {
  const { LandingPageContent } = config;
  const nationalInfo = useNullableNationalAccountInfo();
  const [language] = useLanguage();

  return (
    <>
      <Meta
        cmsTitle={
          nationalInfo?.nationalLandingPageTitle[language]?.trim() ?? ""
        }
        title="nationalVendorLandingPage"
      />
      <HomepageCarousel />
      <LandingPageContent />
    </>
  );
};
