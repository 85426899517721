import {
  partTypeAccessory,
  partTypePackage,
  partTypeTire,
  partTypeWheel,
} from "../constants/partTypes";
import { Language } from "../i18n/useLanguage";
import { AnyOrderItem } from "../stores/slices/cartSlice";

export const getPartName = (
  itemPart: AnyOrderItem["itemPart"] | null | undefined,
  language: Language
): string => {
  if (!itemPart) {
    return "";
  }
  const { primaryPart } = itemPart.partDetails;
  switch (primaryPart.itemType) {
    case partTypeAccessory:
      return (
        primaryPart.accessoryName[language] || primaryPart.accessoryName["en"]
      );
    case partTypePackage:
      return primaryPart.packageName[language] || primaryPart.packageName["en"];
    case partTypeTire:
      return primaryPart.tireModelName;
    case partTypeWheel:
      return primaryPart.wheelManufacturerName;
  }
};
