import clsx from "clsx";
import React, {
  ComponentProps,
  ForwardRefExoticComponent,
  ForwardRefRenderFunction,
  PropsWithoutRef,
  ReactNode,
  Ref,
  RefAttributes,
  RefObject,
  forwardRef,
} from "react";
import { createPortal } from "react-dom";

import { PopoverPlacementOptions } from "../hooks/usePopoverCoordinates/getOptimalPopoverPlacement";
import { useDropdown } from "./useDropdown";

type Props = (
  | {
      placement: PopoverPlacementOptions;
      preferredPlacement?: undefined;
    }
  | {
      placement?: undefined;
      preferredPlacement: PopoverPlacementOptions;
    }
) & {
  "aria-labelledby": string;
  blockLevel?: boolean;
  children: ReactNode;
  className?: string;
  id: string;
  offset?: number;
  onClose?: () => void;
  onHidden?: () => void;
  onShown?: () => void;
  producerRef?: RefObject<HTMLElement>;
  role: "combobox" | "dialog" | "listbox" | "menu";
  stopOutsidePropagation?: boolean;
  visible?: boolean;
};

type NavProps = ComponentProps<"nav">;

export type DropdownProps = PropsWithoutRef<Props & NavProps>;

export interface YobtaMenuFactory {
  (defaultProps: { className?: string }): ForwardRefExoticComponent<
    DropdownProps & RefAttributes<HTMLElement>
  >;
}
export const createDropdown: YobtaMenuFactory = (defaultProps) => {
  const Dropdown: ForwardRefRenderFunction<HTMLElement, Props & NavProps> = (
    {
      blockLevel,
      children,
      className,
      id,
      offset = 0,
      onClose,
      onHidden,
      onShown,
      placement,
      preferredPlacement,
      producerRef,
      stopOutsidePropagation,
      visible = false,
      ...rest
    },
    forwardedRef
  ) => {
    const { animationClassName, combinedRef, hiddenClassName, portalNode } =
      useDropdown({
        blockLevel,
        forwardedRef,
        offset,
        onClose,
        onHidden,
        onShown,
        placement,
        preferredPlacement,
        producerRef,
        stopOutsidePropagation,
        visible,
      });

    const menu = (
      <div
        {...rest}
        className={clsx(
          "tw-dropdown",
          hiddenClassName,
          animationClassName,
          className
        )}
        hidden={!visible}
        id={id}
        ref={combinedRef as Ref<HTMLDivElement>}
      >
        {children}
      </div>
    );

    return <>{portalNode ? createPortal(menu, portalNode) : menu}</>;
  };

  const YobtaDropdown = forwardRef(Dropdown);

  YobtaDropdown.defaultProps = defaultProps;

  return YobtaDropdown;
};
