import { DependencyList, useEffect } from "react";

import { bulk, subscribe } from "../../helpers";
import { useLatestRef } from "../useLatestRef/useLatestRef";
import { useNanoId } from "../useNanoId/useNanoId";

const stack = new Set<string>();

const getLastStackItem = (): string | undefined => [...stack].pop();

interface UseEscapeKey {
  (
    callback: (event: KeyboardEvent) => void,
    active: boolean,
    dependencyList?: DependencyList
  ): boolean;
}

export const useEscapeKey: UseEscapeKey = (
  callback,
  active,
  dependencyList = []
) => {
  const callbackRef = useLatestRef(callback);
  const id = useNanoId();
  const lastId = getLastStackItem();

  useEffect(() => {
    if (active) {
      stack.add(id);
    } else {
      stack.delete(id);
    }
  }, [active]);

  useEffect(() => {
    const handler = (event: KeyboardEvent): void => {
      if (event.key === "Escape" && id === getLastStackItem()) {
        callbackRef.current?.(event);
        event.stopImmediatePropagation();
      }
    };
    const unsubscribe = [
      subscribe(document, "keydown", handler as EventListener),
      (): void => {
        stack.delete(id);
      },
    ];
    return () => {
      bulk(...unsubscribe);
    };
  }, dependencyList);

  return id === lastId;
};
