import useSWR from "swr";

import { reduxStore } from "../stores/reduxStore";
import { authSlice } from "../stores/slices/authSlice";
import { loadScript } from "../util/loadScript";
import { parseToken } from "../util/parseToken";

export const upgradeToken = (): void => {
  const dealerUrlCode = window.dealerAccountInfoPayload?.dealerUrlCode;

  loadScript("tw-token", `/${dealerUrlCode ?? "unknown"}/token.js`);

  const token = parseToken();
  if (!token) {
    throw new Error("Token is not valid");
  }

  const action = authSlice.actions.replaceToken(token);
  reduxStore.dispatch(action);
};

export const useUpgradeToken = (): void => {
  useSWR("upgradeToken", upgradeToken, {
    refreshInterval: 1000 * 60 * 60,
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });
};
