import { configureStore } from "@reduxjs/toolkit";

import { _application } from "./slices/applicationSlice";
import { auth } from "./slices/authSlice";
import { _campaignInfo } from "./slices/campaignSlice";
import { captcha } from "./slices/captchaSlice";
import { cart } from "./slices/cartSlice";
import { columnSort } from "./slices/columnSortSlice";
import { _consumerAccountInfo } from "./slices/consumerAccountInfoSlice";
import { _dealerAccountInfo } from "./slices/dealerAccountInfoSlice";
import { _groupAccountInfo } from "./slices/groupAccountInfoSlice";
import { _nationalAccountInfo } from "./slices/nationalAccountInfoSlice";
import { packageEditor } from "./slices/packageEditorSlice";
import { pagination } from "./slices/paginationSlice";
import { panelState } from "./slices/panelStateSlice";
import { pricingAnalysis } from "./slices/pricingAnalysisSlice";
import { searchForm } from "./slices/searchFormSlice";
import { toast } from "./slices/toastSlice";
import { url } from "./slices/urlSlice";

export const reduxStore = configureStore({
  reducer: {
    _application,
    _campaignInfo,
    _consumerAccountInfo,
    _dealerAccountInfo,
    _groupAccountInfo,
    _nationalAccountInfo,
    auth,
    captcha,
    cart,
    columnSort,
    packageEditor,
    pagination,
    panelState,
    pricingAnalysis,
    searchForm,
    toast,
    url,
  },
});

export type ReduxState = ReturnType<typeof reduxStore.getState>;
export type ReduxDispatch = typeof reduxStore.dispatch;
