import { createContext, useContext } from "react";

type ToggleContextValue = {
  close: VoidFunction;
  setChildToggleIsVisible: (state: boolean) => void;
  visible: boolean;
};

export const ToggleContext = createContext<ToggleContextValue>({
  close() {},
  setChildToggleIsVisible() {},
  visible: false,
});

export const useToggleContext = (): ToggleContextValue =>
  useContext(ToggleContext);
