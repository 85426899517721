import { createSlice } from "@reduxjs/toolkit";

import { decodePayload } from "../../api-client/decodePayload";
import { Language, LanguageMap } from "../../i18n/useLanguage";
import { CarouselItem } from "./dealerAccountInfoSlice";

const name = "groupAccountInfo";

export const groupAccountInfoSlice = createSlice({
  initialState: decodePayload(
    window.groupAccountInfoPayload
  ) as GroupAccountInfoPayload,
  name,
  reducers: {},
});

export const _groupAccountInfo = groupAccountInfoSlice.reducer;

export type GroupAccountInfoPayload = {
  dealerGroupAccessoryShowSecondaryManufacturers: boolean;
  dealerGroupCarousels: {
    home_primary: CarouselItem[];
    home_secondary: CarouselItem[];
  };
  dealerGroupCountry: string;
  dealerGroupDealerCount: string;
  dealerGroupDefaultLanguage: Language;
  dealerGroupDefaultTemplate: string;
  dealerGroupEnableAccessorySearch: boolean;
  dealerGroupEnableHomepageCarousel: boolean;
  dealerGroupEnablePackageSearch: boolean;
  dealerGroupEnableTireSearch: boolean;
  dealerGroupEnableWheelSearch: boolean;
  dealerGroupId: string;
  dealerGroupLanguageSet: string[];
  dealerGroupMinimumAccessoryYear: string;
  dealerGroupMinimumPackageYear: string;
  dealerGroupMinimumTireYear: string;
  dealerGroupMinimumWheelYear: string;
  dealerGroupName: string;
  dealerGroupPackageShowSecondaryManufacturers: boolean;
  dealerGroupPrimaryVehicleManufacturerList: VehicleManufacturer[];
  dealerGroupRetailSiteCustomLogo: string;
  dealerGroupSiteCustomFaviconImage: string;
  dealerGroupSiteCustomTitle: LanguageMap;
  dealerGroupSiteDestinationPage: string;
  dealerGroupSiteShowLocationPromptOnMap: boolean;
  dealerGroupTireShowSecondaryManufacturers: boolean;
  dealerGroupUrlCode: string;
  dealerGroupWheelShowSecondaryManufacturers: boolean;
} | null;

export type VehicleManufacturer = {
  discontinued: string;
  manufacturerDisplayName: string;
  manufacturerId: string;
  manufacturerLogoUrl: string;
  manufacturerName: string;
  nationalAccountId: string;
};

declare global {
  interface Window {
    groupAccountInfoPayload: GroupAccountInfoPayload;
  }
}
