import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { MappedLeadItem } from "../../queries/useGetDealerLeads";
import { MappedDealerOrderItem } from "../../queries/useGetDealerOrderHistory";
import { MappedQuoteItem } from "../../queries/useGetOrderHistory";
import { createSessionStorage } from "../../util/createSessionStorage";

export type SortOrder = "asc" | "desc";

export type PricingAnalysisField =
  | "averagePrice"
  | "brand"
  | "costPrice"
  | "minPrice"
  | "name"
  | "partNumber"
  | "quantityBooked"
  | "quantitySold"
  | "summary"
  | "tireSize"
  | "yourPrice";

export type ColumnSortState = {
  leadReport: {
    field: keyof MappedLeadItem;
    order: SortOrder;
  };
  orderReport: {
    field: keyof MappedDealerOrderItem;
    order: SortOrder;
  };
  pricingAnalysis: {
    field: PricingAnalysisField;
    order: SortOrder;
  };
  quoteReport: {
    field: keyof MappedQuoteItem;
    order: SortOrder;
  };
};

type ColumnSortPayload<Key extends keyof ColumnSortState> = PayloadAction<{
  field: ColumnSortState[Key]["field"];
  key: Key;
}>;

const defaultState: ColumnSortState = {
  leadReport: {
    field: "createdDate",
    order: "desc",
  },
  orderReport: {
    field: "dateCreated",
    order: "desc",
  },
  pricingAnalysis: {
    field: "brand",
    order: "asc",
  },
  quoteReport: {
    field: "dateCreated",
    order: "desc",
  },
};

const name = "columnSort";

const columnSortStorage = createSessionStorage(name, defaultState);

export const columnSortSlice = createSlice({
  initialState: columnSortStorage.get(),
  name,
  reducers: {
    setSort: <Key extends keyof ColumnSortState>(
      state: ColumnSortState,
      { payload }: ColumnSortPayload<Key>
    ) => {
      const { field, key } = payload;
      const order =
        state[key].field === field
          ? state[key].order === "asc"
            ? "desc"
            : "asc"
          : "asc";
      const nextState: ColumnSortState = {
        ...state,
        [key]: { field, order },
      };
      columnSortStorage.set(nextState);
      return nextState;
    },
  },
});

export const columnSort = columnSortSlice.reducer;

export const setColumnSort = columnSortSlice.actions.setSort;

export default columnSortSlice.reducer;
