import { subscribe } from "../subscribe";

interface WhenOnline {
  (): Promise<void>;
}

export const whenOnline: WhenOnline = () => {
  if (window.navigator.onLine) {
    return Promise.resolve();
  }
  return new Promise((resolve) => {
    const unsubscribe = subscribe(window, "online", () => {
      unsubscribe();
      resolve();
    });
  });
};
