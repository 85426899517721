import {
  ForwardedRef,
  Ref,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";

import { subscribe } from "../helpers";
import {
  useCombineRefs,
  useDependencyChangeCount,
  useEventOutsideRefs,
  usePopoverCoordinates,
  usePortalNode,
} from "../hooks";
import { PopoverPlacementOptions } from "../hooks/usePopoverCoordinates/getOptimalPopoverPlacement";
import { getAnimationClassName } from "./getAnimationClassName";
import { useDropdownStyle } from "./useDropdownStyle";

interface DropDownHook {
  (props: {
    blockLevel?: boolean;
    forwardedRef: ForwardedRef<HTMLElement>;
    offset: number;
    onClose?: () => void;
    onHidden?: () => void;
    onShown?: () => void;
    placement?: PopoverPlacementOptions;
    preferredPlacement?: PopoverPlacementOptions;
    producerRef?: RefObject<HTMLElement>;
    stopOutsidePropagation?: boolean;
    visible: boolean;
  }): {
    animationClassName: null | string;
    combinedRef: Ref<HTMLElement>;
    hiddenClassName: null | string;
    portalNode: HTMLElement | null;
  };
}

const hiddenClassName = "tw-dropdown--hidden";

export const useDropdown: DropDownHook = ({
  blockLevel,
  forwardedRef,
  offset,
  onClose,
  onHidden,
  onShown,
  placement,
  preferredPlacement,
  producerRef,
  stopOutsidePropagation,
  visible,
}) => {
  const [, update] = useState({});
  const [forceHide, setForceHide] = useState(!visible);
  const portalNode = usePortalNode();
  const consumerRef = useRef<HTMLElement>(null);
  const combinedRef = useCombineRefs<HTMLElement>(forwardedRef, consumerRef);
  const isTriggered = useDependencyChangeCount(visible) > 0;

  const placementProps = placement ? { placement } : { preferredPlacement };

  useEventOutsideRefs(
    [consumerRef],
    ["mousedown", "touchstart"],
    () => {
      if (visible) {
        onClose?.();
      }
    },
    { deps: [onClose, visible], stopPropagation: stopOutsidePropagation }
  );

  useEffect(() => {
    if (isTriggered && visible && consumerRef.current) {
      consumerRef.current.classList.remove(hiddenClassName);
      setForceHide(false);
      if (onShown) {
        onShown();
      }
    }
  }, [visible, isTriggered, onShown]);

  useEffect(() => {
    const handleAnimationEnd = (event: AnimationEvent): void => {
      if (
        !visible &&
        consumerRef.current &&
        event.target === consumerRef.current
      ) {
        consumerRef.current.classList.add(hiddenClassName);
        update({});
        if (onHidden) {
          onHidden();
        }
      }
    };
    const unsubscribe = subscribe(
      consumerRef.current,
      "animationend",
      handleAnimationEnd as EventListenerOrEventListenerObject
    );
    return unsubscribe;
  }, [onHidden, visible]);

  const position = usePopoverCoordinates({
    ...placementProps,
    consumerNode: consumerRef.current,
    disabled: !visible,
    offset,
    producerNode: producerRef?.current,
  });

  useDropdownStyle({ blockLevel, consumerRef, position, producerRef });

  const isActive = !!position && visible;
  const animationClassName =
    position && getAnimationClassName(position.placement, isActive);

  return {
    animationClassName,
    combinedRef,
    hiddenClassName: forceHide ? hiddenClassName : null,
    portalNode,
  };
};
