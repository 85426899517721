import clsx from "clsx";
import React, { FunctionComponent } from "react";

import { useGetDealerGroupDealerList } from "../../queries/useGetDealerGroupDealerList";
import { useNullableGroupAccountInfo } from "../../stores/hooks/useNullableGroupAccountInfo";
import { OemLogo } from "./OemLogo";

export const OemLogos: FunctionComponent = () => {
  const groupInfo = useNullableGroupAccountInfo();
  const { data = [] } = useGetDealerGroupDealerList();
  if (!groupInfo) {
    return null;
  }
  const manufacturerIds = new Set<string>([]);
  return (
    <section
      className={clsx(
        "my-4 mx-auto bg-paper rounded-md flex overflow-x-auto gap-0",
        "md:my-6 md:gap-2 md:bg-transparent md:rounded-none md:overflow-x-visible md:flex-wrap md:justify-center",
        "xl:max-w-7xl"
      )}
    >
      {groupInfo.dealerGroupPrimaryVehicleManufacturerList
        .filter(({ manufacturerId }) => {
          if (manufacturerIds.has(manufacturerId)) {
            return false;
          }
          manufacturerIds.add(manufacturerId);
          return true;
        })
        .map((vehicleManufacturer) => {
          return (
            <OemLogo
              dealerList={data}
              key={vehicleManufacturer.manufacturerId}
              vehicleManufacturer={vehicleManufacturer}
            />
          );
        })}
    </section>
  );
};
