/* eslint-disable react/display-name */
import clsx from "clsx";
import React, { ElementType, Ref, forwardRef } from "react";

import {
  PolymorphicComponent,
  PolymorphicComponentProps,
} from "../PolymorphicComponent";

export type SpinnerProps = { children?: never };

export type SpinnerConfig<E extends ElementType = typeof defaultElement> =
  PolymorphicComponentProps<E, Partial<SpinnerProps>>;

const defaultElement = "span";

export const createSpinner = <C extends ElementType = typeof defaultElement>({
  className: configClassName,
  element: configElement,
  style: configStyle,
  ...config
}: SpinnerConfig<C>): PolymorphicComponent<C, SpinnerProps> =>
  forwardRef(
    <E extends ElementType = C>(
      {
        className,
        element,
        role = "progressbar",
        style,
        ...rest
      }: PolymorphicComponentProps<E, SpinnerProps>,
      ref: Ref<Element>
    ) => {
      const Tag: ElementType = configElement || element || defaultElement;

      return (
        <Tag
          {...config}
          {...rest}
          className={clsx("tw-spinner", configClassName, className)}
          ref={ref}
          role={role}
          style={{ ...configStyle, ...style }}
        />
      );
    }
  ) as PolymorphicComponent<C, SpinnerProps>;
