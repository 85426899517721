import {
  AnyPartType,
  partTypeAccessory,
  partTypePackage,
  partTypeTire,
  partTypeWheel,
} from "../constants/partTypes";

export const getPartFallbackSrc = (partType: AnyPartType): string => {
  const map = {
    [partTypeAccessory]: "/static/img/placeholder_accessory.png",
    [partTypePackage]: "/static/img/placeholder_package.png",
    [partTypeTire]: "/static/img/placeholder_tire.png",
    [partTypeWheel]: "/static/img/placeholder_wheel.png",
  };
  return map[partType];
};
