import { RefObject, useEffect } from "react";

import { PopoverCoordinates } from "../hooks";
import { PopoverPlacementOptions } from "../hooks/usePopoverCoordinates/getOptimalPopoverPlacement";

interface GetStyle {
  (position: {
    height: number;
    placement: PopoverPlacementOptions;
    width: number;
    x: number;
    y: number;
  }): { left: number; top: number };
}

const getStyle: GetStyle = ({ height, placement, width, x, y }) => {
  switch (placement) {
    case "top":
      return {
        left: x - width / 2,
        top: y - height,
      };
    case "top-left":
      return {
        left: x,
        top: y - height,
      };
    case "top-right":
      return {
        left: x - width,
        top: y - height,
      };
    case "right":
      return {
        left: x,
        top: y - height / 2,
      };
    case "right-top":
      return {
        left: x,
        top: y,
      };
    case "right-bottom":
      return {
        left: x,
        top: y - height,
      };
    case "left":
      return {
        left: x - width,
        top: y - height / 2,
      };
    case "left-top":
      return {
        left: x - width,
        top: y,
      };
    case "left-bottom":
      return {
        left: x - width,
        top: y - height,
      };
    case "bottom-left":
      return {
        left: x,
        top: y,
      };
    case "bottom-right":
      return {
        left: x - width,
        top: y,
      };
    case "bottom":
    default:
      return {
        left: x - width / 2,
        top: y,
      };
  }
};

interface DropdownStyleHook {
  (props: {
    blockLevel?: boolean;
    consumerRef: RefObject<HTMLElement>;
    position: PopoverCoordinates;
    producerRef?: RefObject<HTMLElement>;
  }): void;
}

export const useDropdownStyle: DropdownStyleHook = ({
  blockLevel,
  consumerRef,
  position,
  producerRef,
}) => {
  useEffect(() => {
    if (position && consumerRef.current) {
      const { placement, x, y } = position;
      const { height, width } = consumerRef.current.getBoundingClientRect();
      const { left, top } = getStyle({ height, placement, width, x, y });
      consumerRef.current.style.top = `${top}px`;
      consumerRef.current.style.left = `${left}px`;

      if (blockLevel && producerRef?.current) {
        const producerRect = producerRef.current.getBoundingClientRect();
        switch (placement) {
          case "left":
          case "left-top":
          case "left-bottom":
          case "right":
          case "right-top":
          case "right-bottom":
            consumerRef.current.style.height = `${producerRect.height}px`;
            break;

          default:
            consumerRef.current.style.width = `${producerRect.width}px`;
            break;
        }
      }
    }
  }, [position]);
};
