export const queryParameterToStringArray = <State extends string[]>(
  value: (null | string)[] | null | string,
  fallbackValue: State
): State => {
  if (Array.isArray(value)) {
    return value.filter(Boolean) as State;
  }
  if (typeof value === "string" && value.length > 0) {
    return [value] as State;
  }
  return fallbackValue;
};
