import { solveCaptcha } from "../blocks/Captcha/solveCaptcha";
import { reduxStore } from "../stores/reduxStore";
import { Token } from "../stores/slices/authSlice";
import {
  ToastItem,
  ToastTypeError,
  ToastTypeInfo,
  toastSlice,
} from "../stores/slices/toastSlice";
import { bugsnagNotify } from "../util/bugsnag";
import { getIsNationalAsParam } from "../util/getIsNationalAsParam";
import { gtmTrackEvent } from "../util/useGoogleTagManager";
import { decodePayload } from "./decodePayload";
import { getApiBaseUrl } from "./getApiBase";
import { upgradeToken } from "./upgradeToken";

export type FetcherResult<Data> = {
  message: string;
  payload: Data;
  success: boolean;
  token: Token;
};

const setErrorToast = (error: Error): void => {
  const payload: ToastItem = {
    message: error.message,
    ts: Date.now(),
    type: ToastTypeError,
  };
  const action = toastSlice.actions.set(payload);
  reduxStore.dispatch(action);
};

const setInfoToast = (payload: Omit<ToastItem, "ts" | "type">): void => {
  const action = toastSlice.actions.set({
    ...payload,
    ts: Date.now(),
    type: ToastTypeInfo,
  });
  reduxStore.dispatch(action);
};

const expressEndpoints = new Set(["/sign-in", "/sign-out"]);

const extend = (
  requestInfo: RequestInfo,
  requestInit?: RequestInit
): [string, RequestInit] => {
  const isNational = getIsNationalAsParam();
  const tokenId = reduxStore.getState().auth.tokenId;
  const searchToken = reduxStore.getState().captcha.token;

  const init = {
    ...requestInit,
    credentials: "same-origin" as RequestCredentials,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...requestInit?.headers,
      // "X-Recaptcha-Token": captchToken || undefined,
    },
    method: requestInit?.method || "GET",
    referrerPolicy: "strict-origin" as ReferrerPolicy,
  };

  if (init.method !== "GET" && init.body instanceof URLSearchParams) {
    init.body.set("isNational", isNational);
    init.body.set("tokenId", tokenId);
    searchToken && init.body.set("searchToken", searchToken);
  }

  const origin = expressEndpoints.has(String(requestInfo))
    ? window.location.origin
    : getApiBaseUrl();

  let apiURL = `${origin}${requestInfo}`;

  if (window.stripPhpExtension) {
    apiURL = apiURL.replace(".php", "");
  }

  if (
    init.method === "GET" ||
    init.headers["Content-Type"] === "application/json"
  ) {
    const ts = Date.now();
    const url = new URL(apiURL);
    url.searchParams.append("ts", ts.toString());
    url.searchParams.set("isNational", isNational);
    url.searchParams.set("tokenId", tokenId);
    searchToken && url.searchParams.set("searchToken", searchToken);
    apiURL = url.toString();
  }

  return [apiURL, init];
};

export const fetcher = async <Data>(
  requestInfo: RequestInfo,
  requestInit?: RequestInit
): Promise<FetcherResult<Data>> => {
  await solveCaptcha(requestInfo);

  const tokenIsAuthenticated = reduxStore.getState().auth.tokenIsAuthenticated;

  const [extendedRequestInfo, extendedRequestInit] = extend(
    requestInfo,
    requestInit
  );

  const res = await fetch(extendedRequestInfo, extendedRequestInit);

  if (!res.ok) {
    if (res.status === 403 && tokenIsAuthenticated) {
      try {
        upgradeToken();
        return fetcher<Data>(requestInfo, requestInit);
      } catch (error) {
        setInfoToast({
          message: "toast.sessionExpired",
        });
      }
    }

    const error = new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
    bugsnagNotify(error, (event) => {
      event.addMetadata("apiRequest", {
        extendedRequestInfo,
        extendedRequestInit,
        res,
      });
    });
    setErrorToast(error);
    gtmTrackEvent({
      event: extendedRequestInit.method ?? "GET",
      eventAction: extendedRequestInfo,
      eventCategory: "api-error",
      eventValue: error.message,
    });
    throw error;
  }

  const { message, payload, success, token } = await res.json();

  if (!success && message) {
    const error = new Error(message);
    bugsnagNotify(error, (event) => {
      event.addMetadata("react error info", {
        extendedRequestInfo,
        extendedRequestInit,
        res,
      });
    });
    setErrorToast(error);
    gtmTrackEvent({
      event: extendedRequestInit.method ?? "GET",
      eventAction: extendedRequestInfo,
      eventCategory: "api-error",
      eventValue: error.message,
    });
    throw error;
  }

  gtmTrackEvent({
    event: extendedRequestInit.method ?? "GET",
    eventAction: extendedRequestInfo,
    eventCategory: "api",
    eventValue: message,
  });

  return { message, payload: decodePayload<Data>(payload), success, token };
};

declare global {
  interface Window {
    stripPhpExtension: boolean;
  }
}
