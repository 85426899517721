import useSWR, { SWRResponse } from "swr";

import { loadGeocoder } from "../blocks/GoogleMapsLoader/googleMapsLoader";

const geocoderCache = new Map<string, google.maps.GeocoderResult>();

export type GeocodingResults = google.maps.GeocoderResult | null;

const fetchResults = async (location: string): Promise<GeocodingResults> =>
  new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();
    // const isPostalCode =
    //   /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$|^\d{5}(-\d{4})?$/.test(location);

    // const requestPayload = isPostalCode
    //   ? { postalCode: location }
    //   : { address: location };

    geocoder.geocode({ address: location }, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        return reject(status);
      }
      const suggested = results?.[0] ?? null;

      if (suggested) {
        geocoderCache.set(location, suggested);
      }

      resolve(suggested);
    });
  });

const geocodeAddress = async (location: string): Promise<GeocodingResults> => {
  const cached = geocoderCache.get(location);

  if (cached) {
    return cached;
  }

  await loadGeocoder();

  const results = await fetchResults(location);

  if (results) {
    geocoderCache.set(location, results);
  }

  return results;
};

export const useGeocoder = (
  criteria: string
): SWRResponse<GeocodingResults> => {
  return useSWR(criteria || null, geocodeAddress);
};
