/* eslint-disable react/display-name */
import React, {
  JSXElementConstructor,
  ReactElement,
  RefObject,
  cloneElement,
} from "react";

import { ToggleContext } from "./ToggleContext";
import { getProducerAriaProps } from "./getProducerAriaProps";
import { CLICK, FOCUS, ROLLOVER, TOAST, useToggle } from "./useToggle";

export type ChildWithRef = {
  ref?: RefObject<HTMLElement | null>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & ReactElement<any, JSXElementConstructor<any> | string>;

export type ToggleProps = {
  activeProducerClassName?: string;
  children: [ChildWithRef, ChildWithRef];
  disabled?: boolean;
  mode?: typeof CLICK | typeof FOCUS | typeof ROLLOVER | typeof TOAST;
  onToggle?: (isOn: boolean) => void;
};

export const Toggle = (props: ToggleProps): JSX.Element => {
  const {
    close,
    consumer,
    consumerId,
    consumerRef,
    mode,
    producer,
    producerRef,
    setChildToggleIsVisible,
    visible,
  } = useToggle(props);

  return (
    <ToggleContext.Provider value={{ close, setChildToggleIsVisible, visible }}>
      {cloneElement(producer as ReactElement, {
        ...getProducerAriaProps({ consumerId, mode }),
        "aria-controls": consumerId,
        "aria-expanded": visible,
        "aria-haspopup": "true",
        ref: producerRef,
      })}
      {cloneElement(consumer as ReactElement, {
        producerRef,
        ref: consumerRef,
        visible,
      })}
    </ToggleContext.Provider>
  );
};
