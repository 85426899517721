import React, { FunctionComponent, SVGProps } from "react";

export const MagnifyingGlassIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (
  props
) => {
  return (
    <svg
      {...props}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M8 0.25C6.98226 0.25 5.97448 0.450459 5.0342 0.839933C4.09393 1.22941 3.23958 1.80027 2.51992 2.51992C1.80027 3.23958 1.22941 4.09393 0.839934 5.0342C0.45046 5.97448 0.25 6.98226 0.25 8C0.25 9.01774 0.45046 10.0255 0.839934 10.9658C1.22941 11.9061 1.80027 12.7604 2.51992 13.4801C3.23958 14.1997 4.09393 14.7706 5.0342 15.1601C5.97448 15.5495 6.98226 15.75 8 15.75C9.01774 15.75 10.0255 15.5495 10.9658 15.1601C11.6743 14.8666 12.334 14.4702 12.924 13.9847L18.4697 19.5303C18.7626 19.8232 19.2374 19.8232 19.5303 19.5303C19.8232 19.2374 19.8232 18.7626 19.5303 18.4697L13.9847 12.924C14.4702 12.334 14.8666 11.6743 15.1601 10.9658C15.5495 10.0255 15.75 9.01774 15.75 8C15.75 6.98226 15.5495 5.97448 15.1601 5.0342C14.7706 4.09393 14.1997 3.23958 13.4801 2.51992C12.7604 1.80027 11.9061 1.22941 10.9658 0.839933C10.0255 0.450459 9.01774 0.25 8 0.25ZM5.60823 2.22575C6.36651 1.91166 7.17924 1.75 8 1.75C8.82076 1.75 9.63349 1.91166 10.3918 2.22575C11.1501 2.53984 11.8391 3.00022 12.4194 3.58058C12.9998 4.16095 13.4602 4.84994 13.7742 5.60823C14.0883 6.36651 14.25 7.17924 14.25 8C14.25 8.82076 14.0883 9.63349 13.7742 10.3918C13.4602 11.1501 12.9998 11.8391 12.4194 12.4194C11.8391 12.9998 11.1501 13.4602 10.3918 13.7742C9.63349 14.0883 8.82076 14.25 8 14.25C7.17924 14.25 6.36651 14.0883 5.60823 13.7742C4.84994 13.4602 4.16095 12.9998 3.58058 12.4194C3.00022 11.8391 2.53984 11.1501 2.22575 10.3918C1.91166 9.63349 1.75 8.82076 1.75 8C1.75 7.17924 1.91166 6.36651 2.22575 5.60823C2.53984 4.84994 3.00022 4.16095 3.58058 3.58058C4.16095 3.00022 4.84994 2.53984 5.60823 2.22575Z"
        fill="#2C3E50"
        fillRule="evenodd"
      />
    </svg>
  );
};
