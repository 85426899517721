import { useDispatch } from "react-redux";

import {
  defaultSearchFormState,
  searchFormSlice,
} from "../slices/searchFormSlice";

export const useResetSearchForm = (): VoidFunction => {
  const dispatch = useDispatch();
  return (): void => {
    const action = searchFormSlice.actions.patch(defaultSearchFormState);
    dispatch(action);
  };
};
