import { useEffect } from "react";

type NodeObserverCallback = (
  entry: MutationRecord | ResizeObserverEntry
) => void;

export const useElementObserver = (
  callback: NodeObserverCallback,
  ...nodes: (Element | null | undefined)[]
): void => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        callback(entry);
      });
    });

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        callback(mutation);
      });
    });

    nodes.forEach((node) => {
      if (node) {
        resizeObserver.observe(node);
        mutationObserver.observe(node, {
          attributes: true,
          childList: true,
          subtree: true,
        });
      }
    });
    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, [callback, ...nodes]);
};
