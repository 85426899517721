export const queryParameterToString = (
  value: (null | string)[] | null | string,
  fallbackValue: string
): string => {
  if (Array.isArray(value)) {
    return value.filter(Boolean).join(", ");
  }
  if (typeof value === "string" && value.length > 0) {
    return value;
  }
  return fallbackValue;
};
