import {
  ForwardedRef,
  MutableRefObject,
  RefObject,
  useEffect,
  useRef,
} from "react";

type Refs<Element> =
  | ForwardedRef<Element>
  | MutableRefObject<Element>
  | undefined;

export const useCombineRefs = <Element>(
  ...refs: Refs<Element>[]
): RefObject<Element> => {
  const combinedRef = useRef<Element>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(combinedRef.current);
      } else if (ref && "current" in ref) {
        ref.current = combinedRef.current;
      }
    });
  }, [refs]);

  return combinedRef;
};
