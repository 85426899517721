import clsx from "clsx";
import React, { FunctionComponent, ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
};

export const NoData: FunctionComponent<Props> = ({ children, className }) => {
  return (
    <figure className={clsx("text-center", className)}>
      <svg
        className="max-w-8/12 sm:max-w-[478px] mx-auto"
        fill="none"
        viewBox="0 0 478 400"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M75.205 363.981c91.926 48.026 240.975 48.026 332.912 0 91.938-48.026 91.927-125.886 0-173.912-91.926-48.026-240.986-48.037-332.923 0-91.938 48.037-91.927 125.886.01 173.912Z"
          fill="#FAFAFA"
        />
        <path
          d="M318.583 372.802c18.586 0 33.653-7.182 33.653-16.042 0-8.859-15.067-16.041-33.653-16.041-18.585 0-33.652 7.182-33.652 16.041 0 8.86 15.067 16.042 33.652 16.042ZM55.433 79.357v55.191c0 1.81 1.272 2.556 2.852 1.646l128.136-73.987a6.327 6.327 0 0 0 2.853-4.938V2.035c0-1.81-1.284-2.557-2.853-1.646L58.285 74.375a6.288 6.288 0 0 0-2.852 4.982Z"
          fill="#F0F0F0"
        />
        <path
          d="m64.354 78.491 38.403-22.164c1.767-1.02 3.182-.198 3.182 1.832v38.59a6.987 6.987 0 0 1-3.182 5.486L64.354 124.41c-1.756 1.01-3.182.187-3.182-1.843V83.999a7.022 7.022 0 0 1 3.182-5.508Z"
          fill="#FAFAFA"
        />
        <path
          d="M95.45 75.583 84.027 99.261l-5.815-3.357a2.458 2.458 0 0 0-3.292.9l-13.748 23.711v2.074c0 2.029 1.426 2.852 3.182 1.843l38.403-22.175a7.029 7.029 0 0 0 3.182-5.486V83.198l-7.68-8.054a1.59 1.59 0 0 0-2.81.439ZM66.943 92.612a18.027 18.027 0 0 0 8.153-14.122c0-2.38-.768-4.103-2.03-5.025l-8.712 5.025a7.022 7.022 0 0 0-3.182 5.486v9.184c1.503.878 3.522.746 5.771-.548Z"
          fill="#94A3B8"
        />
        <path
          d="m120.269 49.818 57.703-33.323c1.317-.757 2.381-.22 2.381 1.207a5.088 5.088 0 0 1-2.381 3.95l-57.703 33.312c-1.317.758-2.381.22-2.381-1.206.059-.798.306-1.57.72-2.255a5.078 5.078 0 0 1 1.661-1.685ZM120.269 61.22l57.703-33.322c1.317-.758 2.381-.22 2.381 1.207a5.088 5.088 0 0 1-2.381 3.95l-57.703 33.312c-1.317.757-2.381.22-2.381-1.196a5.094 5.094 0 0 1 2.381-3.95ZM120.269 72.62l57.703-33.312c1.317-.757 2.381-.22 2.381 1.196a5.088 5.088 0 0 1-2.381 3.95l-57.703 33.312c-1.317.768-2.381.23-2.381-1.196a5.094 5.094 0 0 1 2.381-3.95ZM120.269 84.018l57.703-33.312c1.317-.757 2.381-.22 2.381 1.207a5.08 5.08 0 0 1-.72 2.254 5.08 5.08 0 0 1-1.661 1.685L120.269 89.23c-1.317.757-2.381.22-2.381-1.207a5.086 5.086 0 0 1 2.381-4.005Z"
          fill="#FAFAFA"
        />
        <path
          d="M28.66 253.007V172.02a6.287 6.287 0 0 1 2.854-4.938l96.907-55.959c1.569-.911 2.853-.175 2.853 1.646v80.987a6.312 6.312 0 0 1-2.853 4.938l-96.907 55.959c-1.58.91-2.853.175-2.853-1.646Z"
          fill="#F0F0F0"
        />
        <path
          d="M28.66 248.957v4.049c0 1.821 1.273 2.556 2.854 1.646l96.907-55.959a6.312 6.312 0 0 0 2.853-4.938v-4.06L28.661 248.957ZM75.545 175.508l9.876-.515c1.206-.066 1.536.614.735 1.536L74.81 189.432c-.8.911-1.448.659-1.448-.548v-11.06a2.336 2.336 0 0 1 2.183-2.316Z"
          fill="#94A3B8"
        />
        <path
          d="M73 196.509a6.688 6.688 0 0 1-3.38-.867c-2.436-1.415-3.775-4.389-3.775-8.273 0-7.922 5.585-17.556 12.454-21.561 3.39-1.964 6.583-2.26 9.041-.856 2.458 1.405 3.786 4.389 3.786 8.262 0 7.933-5.596 17.556-12.454 21.561a11.512 11.512 0 0 1-5.673 1.734Zm10.972-31.677a10.753 10.753 0 0 0-5.29 1.634c-6.66 3.841-12.069 13.222-12.069 20.903 0 3.643 1.207 6.353 3.402 7.615 2.194 1.261 5.135.965 8.284-.856 6.66-3.851 12.07-13.167 12.07-20.914 0-3.642-1.207-6.342-3.402-7.614a6.025 6.025 0 0 0-3.006-.768h.01ZM34.004 96.758l-6.002-3.445c-2.864-1.92-6.847-1.844-11.247.702C7.977 99.062.933 111.867.933 122.62v2.447c0 10.753 14.462 22.427 14.462 22.427l5.903 3.347s-3.544-4.049-3.138-4.883c3.917-7.944 14.396-30.393 14.396-39.171v-2.447a19.018 19.018 0 0 0-.67-5.245l.67.384 1.448-2.72Zm-17.26 24.655c-4.389 2.524-7.91.472-7.91-4.564 2.347-.373 8.261-10.468 7.91-13.705 4.39-2.524 7.9-.472 7.911 4.565a17.49 17.49 0 0 1-7.91 13.704Z"
          fill="#94A3B8"
        />
        <path
          d="m34.004 96.758-6.002-3.445c-2.864-1.92-6.847-1.844-11.247.702C7.977 99.062.933 111.867.933 122.62v2.447c0 10.753 14.462 22.427 14.462 22.427l5.903 3.347s-3.544-4.049-3.138-4.883c3.917-7.944 14.396-30.393 14.396-39.171v-2.447a19.018 19.018 0 0 0-.67-5.245l.67.384 1.448-2.72Zm-17.26 24.655c-4.389 2.524-7.91.472-7.91-4.564 2.347-.373 8.261-10.468 7.91-13.705 4.39-2.524 7.9-.472 7.911 4.565a17.49 17.49 0 0 1-7.91 13.704Z"
          fill="#000"
          opacity=".2"
        />
        <path
          d="M34.004 96.759a1.285 1.285 0 0 0-.209-.121c-2.82-1.58-6.583-1.36-10.73 1.031C16.81 101.29 11.4 108.872 8.8 116.85a31.181 31.181 0 0 0-1.58 9.403v2.48c0 8.284 9.382 17.896 13.694 21.879.093.084.192.16.296.23a2.143 2.143 0 0 0 1.711.228 2.138 2.138 0 0 0 1.317-1.116c3.731-7.681 14.583-30.591 14.583-39.501v-2.447c.066-5.53-1.81-9.436-4.817-11.246Zm-18.708 25.324a8.851 8.851 0 0 1-.132-1.58 17.476 17.476 0 0 1 7.9-13.716c.46-.265.941-.492 1.438-.68 3.686-1.339 6.473.779 6.473 5.245a17.46 17.46 0 0 1-7.91 13.693c-3.874 2.261-7.1.9-7.77-2.962Z"
          fill="#94A3B8"
        />
        <path
          d="M30.975 111.363a17.46 17.46 0 0 1-7.91 13.693c-3.874 2.195-7.1.889-7.77-2.973a8.41 8.41 0 0 0 1.45-.67 17.476 17.476 0 0 0 7.9-13.704 8.091 8.091 0 0 0-.143-1.591c3.686-1.339 6.473.779 6.473 5.245Z"
          fill="#94A3B8"
        />
        <path
          d="M30.975 111.363a17.46 17.46 0 0 1-7.91 13.693c-3.874 2.195-7.1.889-7.77-2.973a8.41 8.41 0 0 0 1.45-.67 17.476 17.476 0 0 0 7.9-13.704 8.091 8.091 0 0 0-.143-1.591c3.686-1.339 6.473.779 6.473 5.245Z"
          fill="#000"
          opacity=".2"
        />
        <path
          d="M146.262 247.595s3.961-25.236-4.839-46.94c-8.799-21.703-20.661-23.042-26.805-19.958-6.145 3.083-2.195 15.119 3.292 23.206 7.307 10.61 19.377 33.455 18.982 53.973l9.37-10.281Z"
          fill="#94A3B8"
        />
        <path
          d="M146.262 247.595s3.961-25.236-4.839-46.94c-8.799-21.703-20.661-23.042-26.805-19.958-6.145 3.083-2.195 15.119 3.292 23.206 7.307 10.61 19.377 33.455 18.982 53.973l9.37-10.281Z"
          fill="#000"
          opacity=".2"
        />
        <path
          d="M142.313 249.197a.734.734 0 0 1-.714-.779c1.449-34.552-15.811-60.348-23.261-65.373a.751.751 0 0 1 .004-1.243.736.736 0 0 1 .83.003c8.778 5.859 25.368 32.237 23.909 66.679a.73.73 0 0 1-.768.713Z"
          fill="#fff"
        />
        <path
          d="M144.704 277.56c-1.503-3.774-5.387-7.988-15.361-12.486-9.974-4.499-17.479-10.501-18.861-14.846a6.485 6.485 0 0 1 1.766-6.923s-9.403-1.098-10.588-6.858c-1.481-7.264 6.737-7.736 6.737-7.736s-10.786-15.185-13.65-21.944c-2.864-6.759.44-7.165 7.396-4.587 6.956 2.579 20.211 15.559 22.263 19.52 0 0 3.588-4.389 7.45-1.503s4.049 11.521 1.766 14.637a8.102 8.102 0 0 1 8.153 3.368c3.928 5.146 5.223 18.017 5.223 18.017l-2.294 21.341Z"
          fill="#94A3B8"
        />
        <path
          d="M145.406 274.048a.754.754 0 0 1-.724-.57c-5.332-22.219-30.646-57.682-46.369-68.961a.74.74 0 0 1-.304-.485.75.75 0 0 1 .613-.861.734.734 0 0 1 .558.128c15.921 11.422 41.552 47.334 46.962 69.828a.755.755 0 0 1-.56.9l-.176.021ZM344.017 50.313a.794.794 0 0 1-.373-.099l-3.083-1.788a.749.749 0 0 1 .179-1.37.746.746 0 0 1 .567.075l3.083 1.788a.738.738 0 0 1 .349.454.752.752 0 0 1-.074.567.748.748 0 0 1-.648.373Z"
          fill="#fff"
        />
        <path
          d="M144.705 115.368v163.247c0 1.821 1.272 2.556 2.841 1.646l190.415-109.933a6.322 6.322 0 0 0 2.853-4.937V2.144c0-1.821-1.284-2.557-2.853-1.646L147.546 110.43a6.303 6.303 0 0 0-2.841 4.938Z"
          fill="#455A64"
        />
        <path
          d="M166.989 145.519c0 5.694 4.247 7.845 9.502 4.816l150.947-87.153V42.598L176.491 129.74c-5.255 3.04-9.502 10.095-9.502 15.779Z"
          fill="#F5F5F5"
        />
        <path
          d="M340.276 35.216c5.245-3.028 9.502-.878 9.502 4.806s-4.257 12.75-9.502 15.778l-12.783 7.385V42.6l12.783-7.385Z"
          fill="#94A3B8"
        />
        <path
          d="M335.667 52.56a3.032 3.032 0 0 1-1.558-.405 3.9 3.9 0 0 1-1.711-3.6 10.755 10.755 0 0 1 4.937-8.558 3.512 3.512 0 0 1 5.684 3.292 10.789 10.789 0 0 1-4.938 8.558 4.869 4.869 0 0 1-2.414.714Zm4.071-11.795a3.405 3.405 0 0 0-1.657.527 9.432 9.432 0 0 0-4.191 7.264 2.061 2.061 0 0 0 3.445 1.986 9.413 9.413 0 0 0 4.192-7.264 2.5 2.5 0 0 0-.966-2.304 1.58 1.58 0 0 0-.823-.209Z"
          fill="#fff"
        />
        <path
          d="M342.471 80.268 196.538 164.47a6.262 6.262 0 0 0-2.852 4.937v44.91c0 1.822 1.272 2.557 2.852 1.646l145.933-84.235a6.29 6.29 0 0 0 2.853-4.937V81.914c-.055-1.822-1.328-2.557-2.853-1.646Z"
          fill="#94A3B8"
        />
        <path
          d="M342.471 80.268 196.538 164.47a6.262 6.262 0 0 0-2.852 4.937v44.91c0 1.822 1.272 2.557 2.852 1.646l145.933-84.235a6.29 6.29 0 0 0 2.853-4.937V81.914c-.055-1.822-1.328-2.557-2.853-1.646Z"
          fill="#fff"
          opacity=".2"
        />
        <path
          d="m249.589 141.327 26.443-15.273c1.317-.757 2.381-.22 2.381 1.207a5.095 5.095 0 0 1-2.381 3.939l-26.443 15.273c-1.317.758-2.381.22-2.381-1.206a5.088 5.088 0 0 1 2.381-3.94ZM247.208 155.559c0 1.426 1.097 1.964 2.381 1.207l79.934-46.15a5.102 5.102 0 0 0 2.37-3.95c0-1.415-1.097-1.953-2.37-1.196l-79.934 46.15a5.096 5.096 0 0 0-2.381 3.939ZM247.208 165.852c0 1.426 1.097 1.964 2.381 1.207l79.934-46.15a5.102 5.102 0 0 0 2.37-3.95c0-1.415-1.097-1.953-2.37-1.196l-79.934 46.15a5.096 5.096 0 0 0-2.381 3.939ZM247.208 176.143c0 1.426 1.097 1.964 2.381 1.207l79.934-46.15a5.102 5.102 0 0 0 2.37-3.95c0-1.415-1.097-1.953-2.37-1.196l-79.934 46.15a5.096 5.096 0 0 0-2.381 3.939ZM201.036 167.036l34.41-19.871c1.58-.9 2.853-.164 2.853 1.646v34.596a6.301 6.301 0 0 1-2.853 4.937l-34.41 19.86c-1.569.911-2.852.176-2.852-1.634v-34.596a6.29 6.29 0 0 1 2.852-4.938Z"
          fill="#fff"
        />
        <path
          d="m228.906 164.436-10.237 21.22-5.212-3.006a2.192 2.192 0 0 0-2.995.801l-12.278 21.253v1.855c0 1.821 1.283 2.556 2.852 1.646l34.41-19.86a6.29 6.29 0 0 0 2.853-4.938v-12.146l-6.924-7.22a1.425 1.425 0 0 0-2.469.395ZM203.352 179.699a16.156 16.156 0 0 0 7.307-12.662 5.373 5.373 0 0 0-1.821-4.51l-7.802 4.51a6.29 6.29 0 0 0-2.852 4.938v8.229c1.327.768 3.149.658 5.168-.505ZM342.471 147.165l-145.933 84.202a6.29 6.29 0 0 0-2.852 4.938v44.943c0 1.81 1.272 2.545 2.852 1.646l145.933-84.257a6.29 6.29 0 0 0 2.853-4.938v-44.888c-.055-1.81-1.328-2.523-2.853-1.646Z"
          fill="#94A3B8"
        />
        <path
          d="m342.471 147.165-145.933 84.202a6.29 6.29 0 0 0-2.852 4.938v44.943c0 1.81 1.272 2.545 2.852 1.646l145.933-84.257a6.29 6.29 0 0 0 2.853-4.938v-44.888c-.055-1.81-1.328-2.523-2.853-1.646Z"
          fill="#fff"
          opacity=".2"
        />
        <path
          d="m249.589 208.227 26.443-15.263c1.317-.757 2.381-.219 2.381 1.196a5.083 5.083 0 0 1-2.381 3.95l-26.443 15.263c-1.317.757-2.381.219-2.381-1.196a5.088 5.088 0 0 1 2.381-3.95ZM247.208 222.468c0 1.416 1.097 1.953 2.381 1.196l79.934-46.15a5.11 5.11 0 0 0 2.37-3.939c0-1.426-1.097-1.964-2.37-1.207l-79.934 46.15a5.089 5.089 0 0 0-2.381 3.95ZM247.208 232.761c0 1.416 1.097 1.953 2.381 1.196l79.934-46.15a5.11 5.11 0 0 0 2.37-3.939c0-1.426-1.097-1.964-2.37-1.207l-79.934 46.15a5.089 5.089 0 0 0-2.381 3.95ZM247.208 243.054c0 1.416 1.097 1.953 2.381 1.196l79.934-46.15a5.11 5.11 0 0 0 2.37-3.939c0-1.426-1.097-1.964-2.37-1.207l-79.934 46.15a5.089 5.089 0 0 0-2.381 3.95ZM201.036 233.945l34.41-19.871c1.58-.91 2.853-.175 2.853 1.646v34.585a6.264 6.264 0 0 1-2.853 4.938l-34.41 19.871c-1.569.91-2.852.175-2.852-1.646v-34.585a6.29 6.29 0 0 1 2.852-4.938Z"
          fill="#fff"
        />
        <path
          d="m228.906 231.346-10.237 21.221-5.212-3.018a2.202 2.202 0 0 0-2.995.812l-12.278 21.254v1.854c0 1.821 1.283 2.557 2.852 1.646l34.41-19.871a6.263 6.263 0 0 0 2.853-4.927V238.16l-6.924-7.209a1.42 1.42 0 0 0-1.353-.545c-.254.041-.492.15-.688.316a1.408 1.408 0 0 0-.428.624ZM203.352 246.596a16.14 16.14 0 0 0 7.307-12.651 5.373 5.373 0 0 0-1.821-4.509l-7.802 4.509a6.29 6.29 0 0 0-2.852 4.938v8.229c1.327.757 3.149.647 5.168-.516ZM340.814 9.133V2.144c0-1.821-1.284-2.557-2.853-1.646L147.557 110.43a6.29 6.29 0 0 0-2.852 4.938v7L340.814 9.133Z"
          fill="#94A3B8"
        />
        <path
          d="M317.453 15.016a4.692 4.692 0 0 0-2.106 3.664c0 1.35.943 1.899 2.106 1.218a4.624 4.624 0 0 0 2.118-3.653c0-1.35-.944-1.899-2.118-1.23ZM325.847 10.232a4.684 4.684 0 0 0-2.118 3.665c0 1.35.955 1.887 2.118 1.218a4.65 4.65 0 0 0 2.118-3.665c0-1.405-.944-1.942-2.118-1.218ZM334.241 5.337a4.624 4.624 0 0 0-2.118 3.654c0 1.35.944 1.898 2.118 1.229a4.693 4.693 0 0 0 2.106-3.665c0-1.35-.943-1.898-2.106-1.218Z"
          fill="#F5F5F5"
        />
        <path
          d="M343.853 349.518c.331.844.56 1.724.68 2.623.309.929.381 1.921.208 2.885a5.725 5.725 0 0 1-3.291 2.546 7.236 7.236 0 0 1-5.739-1.097c-2.008-1.196-3.511-2.656-6.364-3.457a14.469 14.469 0 0 1-5.618-2.556c-1.58-1.262-3.687-4.773-.669-5.585 3.017-.812 9.59 2.052 15.57 2.315l5.223 2.326Z"
          fill="#263238"
        />
        <path
          d="M343.567 345.568s.351 4.192.351 4.708c0 1.415-5.223 3.291-6.188 0l-.834-4.455 6.671-.253Z"
          fill="#F28F8F"
        />
        <path
          d="M337.159 253.609a84.57 84.57 0 0 1 4.927 8.208s.515 6.1.515 10.16c0 7.461-1.152 13.222-2.194 27.925a20.756 20.756 0 0 1 2.831 9.502c.439 6.66.45 6.583.494 37.306a8.53 8.53 0 0 1-6.584.636l-9.875-43.022-1.097-16.887-3.094-20.99 14.077-12.838Z"
          fill="#37474F"
        />
        <path
          d="M313.788 357.21c.351.362.23 1.097.395 2.304.182.928.23 1.878.143 2.82a5.744 5.744 0 0 1-2.919 2.929 7.3 7.3 0 0 1-5.837-.384c-2.129-.943-3.808-2.194-6.737-2.644a14.41 14.41 0 0 1-5.893-1.832c-1.722-1.098-4.257-4.28-1.36-5.486 2.897-1.207 10.16.954 16.118.471l6.09 1.822Z"
          fill="#263238"
        />
        <path
          d="M315.16 351.944s-1.097 4.784-1.393 5.849c-.659 2.556-6.331 2.688-6.331-.264l.384-6.802 7.34 1.217Z"
          fill="#F28F8F"
        />
        <path
          d="M324.354 254.914c2.842 5.585 5.585 10.512 5.673 16.459.208 12.585-5.014 26.443-6.935 35.539.572 3.292.735 6.642.483 9.974-.384 3.807-9.063 38.403-9.063 38.403s-4.828 1.01-6.88-1.174l2.436-66.613.659-19.344 13.627-13.244Z"
          fill="#37474F"
        />
        <path
          d="m320.448 220.801 1.789 9.908 8.953-.658-1.876-11.225-8.866 1.975Z"
          fill="#FFA8A7"
        />
        <path
          d="M340.638 229.327c-.889-2.688-4.301-5.617-7.681-4.981a101.657 101.657 0 0 0-14.966 3.731c-3.127 1.097-5.98 3.829-6.243 14.121l-.977 25.895a11.24 11.24 0 0 0 4.729 2.501c4.751 1.306 7.889 2.085 15.109.505 9.403-2.052 11.642-7.746 11.642-7.746s-.362-13.705-.648-21.155c-.23-6.331-.362-11.016-.965-12.871Z"
          fill="#455A64"
        />
        <path
          d="m316.63 208.15-45.59-12.366c-4.126-.713-5.848 9.36-1.723 10.062l46.819 4.729.494-2.425Z"
          fill="#263238"
        />
        <path
          d="m303.518 229.042-9.315.285s1.404-13.858 2.271-15.68c.867-1.821 2.94-2.194 5.003-2.776 2.063-.581 2.052-1.569 2.052-1.569l-1.854-.186c-1.624-.165-2.272-2.195-1.569-3.566.34-.637.888-1.646 1.876-1.372l2.458.67a5.74 5.74 0 0 0-.779-3.095c-.823-.91-1.372-.625-3.018-1.196-1.218-.427-3.708-1.185-5.716-.362-2.008.823-3.533 4.598-4.049 7.264a44.423 44.423 0 0 1-2.524 9.008c-1.788 4.938-4.389 15.076-4.663 19.136-.121 1.876.055 2.996 2.14 3.489 2.084.494 17.424.527 17.424.527l.263-10.577ZM313.821 209.072s-2.194 3.204-1.887 3.555a5.087 5.087 0 0 0 1.624.713l.263-4.268Z"
          fill="#FFA8A7"
        />
        <path
          d="M320.437 198.954c-2.084 1.098-5.683 2.996-6.682 10.633-1.02 7.834.329 10.357 1.383 11.4.724.702 4.608.746 6.583.153 2.436-.724 7.933-2.819 10.446-6.846 2.951-4.74 3.61-11.082.285-13.606a12.534 12.534 0 0 0-12.015-1.734Z"
          fill="#FFA8A7"
        />
        <path
          d="m326.571 293.504 4.751-13.43a23.534 23.534 0 0 0 9.601-6.408s-1.098 3.829-8.603 7.757l-5.212 12.706v7.067l-.537-7.692ZM313.328 201.534c.68 1.876 1.832 6.485 6.638 8.405 0 0 .691-2.93 3.149-2.622 2.458.307 2.886 3.456 1.371 5.409-1.514 1.953-2.984 1.251-2.984 1.251s-.329 4.202 2.48 6.111a7.842 7.842 0 0 0 6.978.494c1.613-.735 3.906-4.608 5.245-7.79 2.831-6.715 3.237-12.07-1.097-13.803-.538-3.051-1.909-4.203-4.675-4.773-5.947-1.218-10.445 2.688-15.438 1.997-2.556-.363-2.457 3.171-1.667 5.321Z"
          fill="#263238"
        />
        <path
          d="m335.25 200.053 2.809-1.745a1.66 1.66 0 0 0-2.948.535c-.109.406-.059.839.139 1.21Z"
          fill="#263238"
        />
        <path
          d="M301.433 228.855s13.112-.757 16.459-.779c3.347-.022 6.902 5.695 4.817 9.59-1.383 2.557-5.695 3.434-11.126 3.698-5.431.263-10.907.263-10.907.263s-4.882-5.563.757-12.772Z"
          fill="#455A64"
        />
        <path
          d="M311.747 241.364s8.306-.11 11.027-3.698c0 0-.636 2.195-3.862 3.544a24.002 24.002 0 0 1-7.22 1.558l.055-1.404Z"
          fill="#37474F"
        />
        <path
          d="M271.04 195.784c-4.126-.713-5.848 9.36-1.723 10.062l6.584.658c-2.678-.274-.823-9.634 1.218-9.085l-6.079-1.635ZM437.073 193.097a10.16 10.16 0 0 1-17.61 6.912l-.67-.812a10.16 10.16 0 0 1 15.581-13.002l.691.834a10.186 10.186 0 0 1 2.008 6.068Z"
          fill="#455A64"
        />
        <path
          d="M437.073 193.096a10.16 10.16 0 0 1-17.61 6.913l-.67-.812-42.309-51.098 17.83-10.292 40.06 48.366.691.834a10.177 10.177 0 0 1 2.008 6.089Z"
          fill="#455A64"
        />
        <path
          d="M402.071 178.998a10.159 10.159 0 0 0 13.848.803 10.164 10.164 0 0 0 1.744-13.761l-5.234-6.31a10.162 10.162 0 0 1-8.789 16.239 10.165 10.165 0 0 1-6.803-3.247l5.234 6.276Z"
          fill="#94A3B8"
        />
        <path
          d="M393.634 57.478a23.59 23.59 0 0 1 9.074 2.951l-12.871-7.483c-8.635-5.936-21.066-5.585-34.881 2.381-26.081 15.065-47.236 51.702-47.236 81.832 0 14.802 5.037 25.379 13.343 30.405l.175.109 13.738 7.911a23.702 23.702 0 0 1-6.266-5.958c3.385.371 6.808.174 10.128-.581a40.299 40.299 0 0 0 3.665-.977 2.948 2.948 0 0 1-.472-.186 12.37 12.37 0 0 1-1.887-1.097 13.221 13.221 0 0 1-2.853-2.7 18.949 18.949 0 0 1-2.677-4.663c-.894.129-1.796.195-2.7.198a14.72 14.72 0 0 1-6.21-1.24 12.1 12.1 0 0 1-1.328-.713 9.24 9.24 0 0 1-1.097-.812 52.22 52.22 0 0 1-1.152-11.302c0-30.119 21.155-66.767 47.258-81.832a54.069 54.069 0 0 1 10.533-4.718c.989.404 1.919.94 2.765 1.591a13.573 13.573 0 0 1 2.403 2.37 19.102 19.102 0 0 1 2.689 4.674 19.282 19.282 0 0 1 2.71-.186 13.989 13.989 0 0 1 7.11 1.722c.55.3 1.075.645 1.569 1.032a35.52 35.52 0 0 0-.658-2.568 31.102 31.102 0 0 0-4.872-10.16Z"
          fill="#263238"
        />
        <path
          d="m402.708 60.43-.746-.407a23.572 23.572 0 0 0-8.328-2.545 30.014 30.014 0 0 0-9.36.428 39.232 39.232 0 0 0-4.389 1.097 54.042 54.042 0 0 0-10.533 4.718c-26.104 15.065-47.258 51.713-47.258 81.832a52.22 52.22 0 0 0 1.152 11.302c.142.669.307 1.305.472 1.942a32.018 32.018 0 0 0 5.003 10.807 23.93 23.93 0 0 0 3.566 3.918 22.77 22.77 0 0 0 2.699 2.04c8.614 5.563 20.848 5.081 34.344-2.732 26.103-15.065 47.258-51.701 47.258-81.832-.011-15.065-5.311-25.653-13.88-30.569Zm-5.157 8.777c.55.3 1.075.645 1.569 1.032.827.672 1.581 1.43 2.249 2.26 3.292 4.126 5.036 10.544 5.036 18.532 0 26.334-19.311 59.822-42.166 73.021-5.761 3.292-11.302 5.092-16.009 5.092a14.59 14.59 0 0 1-5.728-1.098 2.938 2.938 0 0 1-.471-.186 12.297 12.297 0 0 1-1.888-1.097 13.036 13.036 0 0 1-2.041-1.778c-.274-.285-.537-.603-.801-.922a18.98 18.98 0 0 1-2.677-4.663 36.63 36.63 0 0 1-2.348-13.869v-1.097c.483-26.224 19.586-58.955 42.145-71.968a43.585 43.585 0 0 1 1.569-.867 34.878 34.878 0 0 1 11.729-4.027 19.312 19.312 0 0 1 2.711-.186 14.667 14.667 0 0 1 5.694 1.097 9.38 9.38 0 0 1 1.427.713v.011Z"
          fill="#455A64"
        />
        <path
          d="M406.405 91.02c0 26.334-19.311 59.822-42.166 73.022a45.4 45.4 0 0 1-4.28 2.194 29.084 29.084 0 0 1-11.696 2.875 14.607 14.607 0 0 1-5.728-1.097 3.09 3.09 0 0 1-.472-.187 12.335 12.335 0 0 1-1.887-1.097 13.264 13.264 0 0 1-2.853-2.699c-.285-.385-.581-.758-.844-1.185.515 0 1.097.076 1.601.076 4.708 0 10.249-1.766 16.009-5.091 22.823-13.123 42.145-46.567 42.145-72.988 0-7.253-1.437-13.167-4.169-17.325 1.928.126 3.803.685 5.486 1.635.55.3 1.075.644 1.569 1.03.827.673 1.581 1.43 2.249 2.261 2.897 3.632 4.598 9.03 4.949 15.713.055.965.087 1.909.087 2.863Z"
          fill="#37474F"
        />
        <path
          d="M401.995 87.981c0 26.334-19.312 59.822-42.167 73.021-5.761 3.292-11.291 5.092-16.009 5.092a14.558 14.558 0 0 1-5.717-1.098c-.274-.285-.537-.603-.801-.921a19.005 19.005 0 0 1-2.677-4.664 36.627 36.627 0 0 1-2.348-13.869v-1.097c.483-26.224 19.586-58.954 42.145-71.968a43.74 43.74 0 0 1 1.569-.867 34.887 34.887 0 0 1 11.729-4.026 19.312 19.312 0 0 1 2.711-.187 14.667 14.667 0 0 1 5.694 1.097c.297.296.571.615.834.933 3.292 4.147 5.037 10.566 5.037 18.554Z"
          fill="#fff"
          opacity=".6"
        />
      </svg>
      <figcaption>{children}</figcaption>
    </figure>
  );
};
