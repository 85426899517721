import React, { ReactNode, ReactPortal, createContext } from "react";
import { createPortal } from "react-dom";

import { usePortalNode } from "../hooks/usePortalNode";

type Context = { addToPortal(children: ReactNode): void };

export const context = createContext<Context>({} as Context);

export const createUiPortal = (
  children: ReactNode,
  nodeId?: string
): ReactNode | ReactPortal => {
  const portalNode = usePortalNode(nodeId);

  if (portalNode) {
    return createPortal(children, portalNode);
  }

  return <div className="hidden">{children}</div>;
};
