import { createSlice } from "@reduxjs/toolkit";

const name = "captcha";

export type CaptchaState = {
  loading: boolean;
  requests: string[];
  token: null | string;
};

export const paginationSlice = createSlice({
  initialState: {
    loading: false,
    requests: [],
    token: null,
  } as CaptchaState,
  name,
  reducers: {
    logRequest({ requests, ...rest }, { payload }: { payload: string }) {
      const nextRequests = new Set(requests);
      nextRequests.add(payload);
      return { ...rest, requests: [...nextRequests] };
    },
    patch(state, { payload }: { payload: Partial<CaptchaState> }) {
      return { ...state, ...payload };
    },
  },
});

export const captcha = paginationSlice.reducer;

export const logRequest = paginationSlice.actions.logRequest;
export const patch = paginationSlice.actions.patch;
