import { createSlice } from "@reduxjs/toolkit";

const name = "toast";

const defaultState: State = {};

export const toastSlice = createSlice({
  initialState: defaultState,
  name,
  reducers: {
    delete(state, { payload }: { payload: ToastItem }) {
      delete state[payload.message];
    },
    set(state, { payload }: { payload: ToastItem }) {
      state[payload.message] = payload;
    },
  },
});

type State = Record<string, ToastItem>;

export type ToastItem = {
  action?: {
    callback: VoidFunction;
    label: string;
  };
  message: string;
  ts: number;
  type: ToastType;
};

export type ToastType = typeof ToastTypeError | typeof ToastTypeInfo;

export const ToastTypeInfo = "info";

export const ToastTypeError = "error";

export const toast = toastSlice.reducer;
