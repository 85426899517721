import { reduxStore } from "../../stores/reduxStore";
import { logRequest } from "../../stores/slices/captchaSlice";
import { whenTrue } from "../../util/whenTrue";
import { grecaptchaExecute, isCaptchaLoaded } from "./ReCaptcha";
import { isCaptchaEnabled } from "./isCaptchaEnabled";

export const solveCaptcha = async (request: RequestInfo): Promise<void> => {
  const path = String(request);

  if (!isCaptchaEnabled(reduxStore.getState()) || !path.includes("/search")) {
    return;
  }

  await whenTrue(isCaptchaLoaded);

  grecaptchaExecute();

  await whenTrue(() => reduxStore.getState().captcha.token !== null);

  const action = logRequest(path);
  reduxStore.dispatch(action);
};
