import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { useRemoveItemFromCart } from "../../../queries/useRemoveItemFromCart";
import { AnyOrderItem } from "../../../stores/slices/cartSlice";
import { Button } from "../../../ui/Button/Button";
import { TrashIcon } from "../../../ui/icons/TrashIcon";

type OrderItemRemoveButtonProps = {
  className?: string;
  onRemoved?: VoidFunction;
  orderItem: AnyOrderItem;
};

export const OrderItemRemoveButton: FunctionComponent<
  OrderItemRemoveButtonProps
> = ({ className, onRemoved, orderItem }) => {
  const { t } = useTranslation();
  const removeItemFromCart = useRemoveItemFromCart();

  return (
    <Button
      onClick={(): void => {
        removeItemFromCart.send(orderItem).then(onRemoved);
      }}
      aria-label="removeItemFromCart"
      busy={removeItemFromCart.loading}
      className={clsx("text-ink-2 font-light -mt-0.5 px-2", className)}
      disabled={removeItemFromCart.loading}
    >
      <TrashIcon />
      <span className="hidden md:inline">{t("cart.remove")}</span>
    </Button>
  );
};
