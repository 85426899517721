export const partTypeAccessory = "ACC";
export const partTypeCustom = "CTM";
export const partTypePackage = "PKG";
export const partTypeTire = "TRE";
export const partTypeWheel = "WHL";

export type PartTypeAccessory = typeof partTypeAccessory;
export type PartTypeCustom = typeof partTypeCustom;
export type PartTypePackage = typeof partTypePackage;
export type PartTypeTire = typeof partTypeTire;
export type PartTypeWheel = typeof partTypeWheel;

export type AnyPartType =
  | PartTypeAccessory
  | PartTypePackage
  | PartTypeTire
  | PartTypeWheel;
