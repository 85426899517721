import React, { FunctionComponent } from "react";

import { config } from "../../config/config";

export const ProductHeroBackground: FunctionComponent = () =>
  config.productHeroBackground && (
    <img
      className="fixed inset-0 z-[-1] object-cover object-center w-full h-full"
      src={config.productHeroBackground}
    />
  );
