import { useCallback, useEffect, useState } from "react";

import { bulk } from "../../helpers/bulk";
import { subscribe } from "../../helpers/subscribe";
import { useElementObserver } from "../useElementObserver/useElementObserver";

interface ObserveChangesHook {
  (args: {
    consumerNode?: HTMLElement | null;
    disabled?: boolean;
    producerNode?: HTMLElement | null;
  }): number;
}

export const useObserveChanges: ObserveChangesHook = ({
  consumerNode,
  disabled,
  producerNode,
}) => {
  const [state, setState] = useState(0);
  const update = useCallback((): void => {
    if (disabled) return;
    setState((lastState) => lastState + 1);
  }, [disabled]);

  useElementObserver(update, consumerNode, producerNode);

  useEffect(() => {
    const eventTypes = !disabled ? ["resize", "scroll"] : [];
    const unsubsribe = eventTypes.map((eventType) =>
      subscribe(window, eventType, update, { passive: true })
    );
    return () => {
      bulk(...unsubsribe);
    };
  }, [disabled, producerNode]);

  return state;
};
