import { useEffect, useState } from "react";

import { useLanguage } from "../../i18n/useLanguage";
import { useRelatedCartItems } from "../../stores/hooks/useRelatedCartItems";
import { AnyPartItem } from "../../stores/slices/cartSlice";
import { CombinedPart, combinePartsForPricing } from "./combinePartsForPricing";
import { PricingOption } from "./getFlatPricingOptions";
import { PriceSelectEntry } from "./usePriceSelectState";

interface PricingHook {
  (props: {
    defaultQuantity: string;
    isStaggered: boolean;
    onReady?: (pricingOptions: PriceSelectEntry[]) => void;
    part: AnyPartItem | null;
  }): {
    entries: PricingHookEntries[];
    total: number;
  };
}

type PricingHookEntries = [
  combinedPart: CombinedPart,
  selectedPriceOption: PricingOption,
  setPricingIndex: (pricingIndex: number) => void
];

export const usePricing: PricingHook = ({
  defaultQuantity,
  isStaggered,
  onReady,
  part,
}) => {
  const [language] = useLanguage();

  const [state, setState] = useState<Record<number, number>>({});

  const relatedCartItems = useRelatedCartItems(part, isStaggered);
  const combinedParts = combinePartsForPricing(part, isStaggered, language);

  const entries: PricingHookEntries[] = combinedParts.map(
    (combinedPart, key) => {
      const currentIndex = state[key] ?? null;
      const currentPriceOption =
        currentIndex !== null
          ? combinedPart.pricingOptions[currentIndex]
          : null;

      const relatedOrderItem = relatedCartItems[key] ?? null;
      const orderPriceOption = relatedOrderItem
        ? combinedPart.pricingOptions.find(
            ({ context, quantity }) =>
              context.pricingContextId === relatedOrderItem.pricingContextId &&
              quantity === relatedOrderItem.itemQty
          )
        : null;

      const defaultPriceOption = combinedPart.pricingOptions.find(
        ({ quantity }) => quantity === defaultQuantity
      );

      const fallbackPriceOption = combinedPart.pricingOptions[0];

      const selectedPriceOption =
        currentPriceOption ||
        orderPriceOption ||
        defaultPriceOption ||
        fallbackPriceOption;

      const setCurrentIndex = (pricingIndex: number): void => {
        setState((state) => ({ ...state, [key]: pricingIndex }));
      };

      return [combinedPart, selectedPriceOption, setCurrentIndex];
    }
  );

  useEffect(() => {
    if (onReady) {
      const selectedOptions = entries.map(([combinedPart, pricingOption]) => ({
        combinedPart,
        pricingOption,
      }));
      onReady(selectedOptions);
    }
  }, [onReady, part?.partDetails.primaryPart.itemId]);

  const total = entries.reduce((total, [, { context, quantity }]) => {
    const displayLabour = Number(context.displayLabour) || 0;
    return (
      total + Number(context.displayPrice) * Number(quantity) + displayLabour
    );
  }, 0);

  return {
    entries,
    total,
  };
};
