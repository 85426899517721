/* eslint-disable @typescript-eslint/no-explicit-any */
import qs, { ParsedQuery } from "query-string";
import { useCallback, useMemo } from "react";
import { navigate } from "wouter/use-location";

import { arrayToggle } from "../util/arrayToggle";
import { queryParameterToNumberArray } from "../util/queryParameterToNumberArray";
import { queryParameterToString } from "../util/queryParameterToString";
import { queryParameterToStringArray } from "../util/queryParameterToStringArray";
import { useUrl } from "./hooks/useUrl";

type Params = Record<string, any>;

const useQueryState = <State extends Params>({
  defaultState,
  validate,
}: QueryStateHookParams<State>): QueryStateHookResult<State> => {
  const { hash = "", pathname, search } = useUrl();

  const state = useMemo((): State => {
    const query = qs.parse(search);
    try {
      return validate(query);
    } catch (_error) {
      return defaultState;
    }
  }, [search, validate, defaultState]);

  const next: QueryStateHookActions<State>["next"] = useCallback(
    (nextState: State) => {
      const nextQuery = qs.stringify(nextState);
      const nextLocation = `${window.location.pathname}?${nextQuery}${window.location.hash}`;
      navigate(nextLocation);
    },
    [hash, pathname]
  );

  const patch: QueryStateHookActions<State>["patch"] = useCallback(
    (partialState, omit = []) => {
      const params = qs.parse(search) as State;
      const nextParams = Object.keys(params).reduce<State>((acc, key) => {
        if (omit.includes(key)) {
          return acc;
        }
        return { ...acc, [key]: params[key] };
      }, {} as State);
      next({ ...nextParams, ...partialState });
    },
    [hash, pathname, search]
  );

  return [state, { next, patch }];
};

export const createQueryStringHook =
  (paramName: string, defaultValue: string): QueryStringHook =>
  (fallbackValue = defaultValue) => {
    const [state, { patch }] = useQueryState({
      defaultState: {
        [paramName]: fallbackValue,
      },
      validate: (qs) => ({
        [paramName]: queryParameterToString(qs[paramName], fallbackValue),
      }),
    });
    return [
      state[paramName],
      (nextValue, omit): void => patch({ [paramName]: nextValue }, omit),
    ];
  };

export const createQueryNumberHook =
  (paramName: string, defaultValue: number): QueryNumberHook =>
  (fallbackValue = defaultValue) => {
    const [state, { patch }] = useQueryState({
      defaultState: {
        [paramName]: fallbackValue,
      },
      validate: (qs) => ({
        [paramName]: Number(qs[paramName] || fallbackValue),
      }),
    });
    return [
      state[paramName],
      (nextValue, omit): void => patch({ [paramName]: nextValue }, omit),
    ];
  };

export const createQueryCustomHook =
  <State>(
    paramName: string,
    useValidator: () => (value: unknown) => State
  ): QueryCustomHook<State> =>
  () => {
    const validate = useValidator();
    const [state, { patch }] = useQueryState({
      defaultState: {},
      validate: (qs) => ({
        [paramName]: validate(qs[paramName]),
      }),
    });
    return [
      state[paramName],
      (nextValue, omit): void => patch({ [paramName]: nextValue }, omit),
    ];
  };

export const createQueryStringToggleHook =
  (paramName: string, defaultValue: string[]): QueryStringToggleHook =>
  (fallbackValue = defaultValue) => {
    const [state, { patch }] = useQueryState({
      defaultState: {
        [paramName]: fallbackValue,
      },
      validate: (qs) => ({
        [paramName]: queryParameterToStringArray(qs[paramName], fallbackValue),
      }),
    });
    const value = state[paramName];
    return [
      value,
      {
        set(nextValue): void {
          const nextState = nextValue ? [nextValue] : [];
          patch({ [paramName]: nextState });
        },
        toggle(nextValue, omit): void {
          const nextState = arrayToggle(value, nextValue);
          patch({ [paramName]: nextState }, omit);
        },
      },
    ];
  };

export const createQueryNumberToggleHook =
  (paramName: string, defaultValue: number[]): QueryNumberToggleHook =>
  (fallbackValue = defaultValue) => {
    const [state, { patch }] = useQueryState({
      defaultState: {
        [paramName]: fallbackValue,
      },
      validate: (qs) => ({
        [paramName]: queryParameterToNumberArray(qs[paramName], fallbackValue),
      }),
    });
    const value = state[paramName];
    return [
      value,
      (nextValue, omit): void => {
        const nextState = arrayToggle(value, nextValue);
        patch({ [paramName]: nextState }, omit);
      },
    ];
  };

type QueryStateHookParams<State extends Params> = {
  defaultState: State;
  validate(query: ParsedQuery<string>): State;
};

type QueryStateHookResult<State extends Params> = [
  State,
  QueryStateHookActions<State>
];

type QueryStateHookActions<State extends Params> = {
  next(nextState: State): void;
  patch(partialState: Partial<State>, omit?: string[]): void;
};

type QueryStringHook = (
  fallbackValue?: string
) => [string, (nextValue: string, omit?: string[]) => void];

type QueryNumberHook = (
  fallbackValue?: number
) => [number, (nextValue: number, omit?: string[]) => void];

type QueryStringToggleHook = (fallbackValue?: string[]) => [
  string[],
  {
    set(item: null | string): void;
    toggle(item: string, omit?: string[]): void;
  }
];

type QueryNumberToggleHook = (
  fallbackValue?: number[]
) => [number[], (item: number, omit?: string[]) => void];

type QueryCustomHook<State> = () => [
  State,
  (nextValue: State, omit?: string[]) => void
];
