import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRoute } from "wouter";

import { useLanguage } from "../i18n/useLanguage";
import { NationalCampaignRoute } from "../navigation/NationalRoutes";
import { useNullableCampaignInfo } from "../stores/hooks/useNullableCampaignInfo";
import { useSetErrorToast } from "../stores/hooks/useSetErrorToast";
import { LinearProgress } from "../ui/LinearProgress";
import { partTypeToProductType } from "../util/partTypeToProductType";

type UrlEntries = [string, string][];

export const NationalCampaignRedirect: FunctionComponent = () => {
  const { t } = useTranslation();
  const campaignInfo = useNullableCampaignInfo();
  const [landingpage] = useLanguage();
  const [, campaignParams] = useRoute(NationalCampaignRoute);
  const setErrorToast = useSetErrorToast();

  useEffect(() => {
    if (!campaignInfo || !campaignParams) {
      const error = new Error("Campaign info or params not found");
      setErrorToast(error);
      return;
    }

    // NOTE: V1 implementation is in code/client/service/DealerSelectionService.js:161
    // setWidgetRedirect
    const { search } = window.location;
    const params = new URLSearchParams(search);

    const lowercaseParams = new URLSearchParams();

    for (const [key, value] of params.entries()) {
      lowercaseParams.append(key.toLowerCase(), value);
    }

    const dealerInfo = campaignInfo.campaignDealerList[0];

    const hasMakeAndModel =
      lowercaseParams.has("vehiclemanufacturername") &&
      lowercaseParams.has("vehiclemodelname");

    const entries: UrlEntries = [
      ["dealer", dealerInfo?.dealerId ?? campaignParams.dealerId],
      [
        "product",
        partTypeToProductType(
          lowercaseParams.get("product") ??
            lowercaseParams.get("producttype") ??
            campaignInfo.campaign.campaignPartType
        ),
      ],
      ["type", "vehicle-search-simple"],
      ["year", lowercaseParams.get("vehicleyear")],
      hasMakeAndModel && [
        "make",
        lowercaseParams.get("vehiclemanufacturername"),
      ],
      hasMakeAndModel && ["category", "null"],
      hasMakeAndModel && ["model", lowercaseParams.get("vehiclemodelname")],
    ].filter((item) => item && item[1]) as UrlEntries;

    const dealerUrlCode = dealerInfo?.dealerUrlCode;
    const tail = entries.map(([key, value]) => `${key}/${value}`).join("/");
    const dealerPathname = `/${dealerUrlCode}/${landingpage}/${tail}`;

    window.open(dealerPathname, "_self");
  }, [campaignInfo, campaignParams]);

  return (
    <>
      <LinearProgress className="mt-8 mb-2 h-[2px]" />
      <p className="text-ink-2 font-thin text-sm">
        {t("national.redirecting")}
      </p>
    </>
  );
};
