import { DependencyList, RefObject, useEffect } from "react";

import { bulk } from "../../helpers/bulk";
import { subscribe } from "../../helpers/subscribe";
import { useLatestRef } from "../useLatestRef/useLatestRef";

type Options = {
  deps: DependencyList;
  stopPropagation?: boolean;
};

export const useEventOutsideRefs = <E extends Event = Event>(
  refs: RefObject<HTMLElement | null>[],
  eventTypes: string[],
  callback: (_event: E) => void,
  { deps, stopPropagation }: Options
): void => {
  const callbackRef = useLatestRef(callback);

  useEffect(() => {
    const handler = (event: Event): void => {
      const shouldIgnoreEvent = refs.some((ref) =>
        ref.current?.contains(event.target as HTMLElement)
      );

      if (!shouldIgnoreEvent) {
        callbackRef.current?.(event as E);
      }
    };

    const unsubscribeAll = eventTypes.flatMap((eventType) => {
      const unsubscribe = [subscribe(document, eventType, handler)];
      // NOTE: this one to prevent nested dialogs from closing each other
      for (const ref of refs) {
        if (stopPropagation && eventType !== "focusin") {
          unsubscribe.push(
            subscribe(ref.current, eventType, (event) => {
              event.stopPropagation();
            })
          );
        }
      }
      return unsubscribe;
    });

    return () => {
      bulk(...unsubscribeAll);
    };
  }, deps);
};
