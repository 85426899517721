import clsx from "clsx";
import React, { FunctionComponent, ReactNode } from "react";

import { TabPaddle } from "./TabPaddle";

type ButtonGroupProps = {
  children: ReactNode[];
  className?: string;
  id: string;
  role?: "group" | "tablist";
};

export const TabsGroup: FunctionComponent<ButtonGroupProps> = ({
  children,
  className,
  id,
  role = "tablist",
}) => {
  return (
    <nav className={clsx("tw-tabs-group", className)} id={id} role={role}>
      <TabPaddle />
      {children}
    </nav>
  );
};
