import clsx from "clsx";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useLanguage } from "../../i18n/useLanguage";
import { useNullableDealerAccountInfo } from "../../stores/hooks/useNullableDealerAccountInfo";
import { useNullableGroupAccountInfo } from "../../stores/hooks/useNullableGroupAccountInfo";
import { useNullableNationalAccountInfo } from "../../stores/hooks/useNullableNationalAccountInfo";
import { Button } from "../../ui/Button/Button";
import { HomepageCarouselImage } from "./HomepageCarouselImage";
import { HomepageCarouselLink } from "./HomepageCarouselLink";
import { HomepageCarouselVideo } from "./HomepageCarouselVideo";
import { ProductPageHero } from "./ProductPageHero";

const getTranslateX = (index: number, selectedIndex: number): string => {
  const diff = index - selectedIndex;
  return `translateX(${diff * 100}%)`;
};

export const HomepageCarousel: FunctionComponent = () => {
  const [language] = useLanguage();
  const dealer = useNullableDealerAccountInfo();
  const national = useNullableNationalAccountInfo();
  const group = useNullableGroupAccountInfo();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [failedSrcs, setFailedSrcs] = useState<Set<string>>(new Set());
  const [isLocked, setIsLocked] = useState(false);

  const dealerCarousels =
    dealer?.dealerCarousels ||
    national?.nationalCarousels ||
    group?.dealerGroupCarousels;

  const next = useCallback(
    (nextSelectedIndex: number) => {
      if (!isLocked) {
        setSelectedIndex(nextSelectedIndex);
      }
    },
    [isLocked]
  );

  const validItems = useMemo(() => {
    return (
      dealerCarousels?.home_primary
        .filter(({ imageURL, resourceType, videoYouTubeUrl }) => {
          switch (resourceType) {
            case "image": {
              const src = imageURL[language] || imageURL.en;
              const isFailed = failedSrcs.has(src);
              return !!src && !isFailed;
            }
            case "youtube": {
              const src = videoYouTubeUrl[language] || imageURL.en;
              return !!src;
            }

            default: {
              return false;
            }
          }
        })
        .sort((a, b) => Number(a.sortOrder) - Number(b.sortOrder)) ?? []
    );
  }, [dealerCarousels?.home_primary, failedSrcs, language]);

  const safeIndex = selectedIndex % validItems.length;
  const selectedItem = validItems[safeIndex];

  useEffect(() => {
    const rotationHoldDelayMS = Number(selectedItem?.rotationHoldDelayMS);
    if (!rotationHoldDelayMS) return;
    const timeout = setTimeout(() => {
      next(safeIndex + 1);
    }, rotationHoldDelayMS);
    return () => {
      clearTimeout(timeout);
    };
  }, [selectedItem?.rotationHoldDelayMS, safeIndex, next]);

  if (!validItems.length) {
    return <ProductPageHero />;
  }

  return (
    <figure className="tw-carousel">
      {validItems.map((carouselItem, currentIndex) => {
        const src = carouselItem.imageURL[language];

        return (
          <div
            className={clsx(
              "tw-carousel__item",
              currentIndex === safeIndex ? "relative" : "absolute top-0 left-0"
            )}
            style={{
              transform: getTranslateX(currentIndex, safeIndex),
            }}
            key={`${src}-${currentIndex}`}
          >
            {carouselItem.resourceType === "image" && (
              <HomepageCarouselImage
                onError={(src): void => {
                  setFailedSrcs((prev) => new Set(prev.add(src)));
                }}
                image={carouselItem.imageURL}
                language={language}
              />
            )}
            {carouselItem.resourceType === "youtube" && (
              <HomepageCarouselVideo
                carouselItem={carouselItem}
                language={language}
                setIsLocked={setIsLocked}
              />
            )}
            <HomepageCarouselLink
              carouselItem={carouselItem}
              language={language}
            />
          </div>
        );
      })}
      {validItems.length > 1 && (
        <nav className="tw-carousel__controls">
          {validItems.map((carouselItem, currentIndex) => (
            <Button
              onClick={(): void => {
                next(currentIndex);
              }}
              aria-label="carouselControl"
              className="tw-carousel__control"
              disabled={isLocked}
              key={`${carouselItem.imageURL[language]}-${currentIndex}`}
            >
              <span
                className={clsx(
                  "tw-carousel__bullet",
                  safeIndex === currentIndex
                    ? "bg-opacity-100 scale-110"
                    : "bg-opacity-0 scale-0"
                )}
              />
            </Button>
          ))}
        </nav>
      )}
    </figure>
  );
};
