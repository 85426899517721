/* eslint-disable react/prop-types */
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import { DealershipForm } from "../blocks/Dealerships/DealershipForm";
import { DealershipList } from "../blocks/Dealerships/DealershipList";
import { DealershipMap } from "../blocks/Dealerships/DealershipMap";
import { Meta } from "../blocks/Meta/Meta";
import { useLanguage } from "../i18n/useLanguage";
import { useNullableNationalAccountInfo } from "../stores/hooks/useNullableNationalAccountInfo";
import { Button } from "../ui/Button/Button";

export const NationalVendorPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const nationalInfo = useNullableNationalAccountInfo();
  const [language] = useLanguage();
  const [mapIsVisible, setMapIsVisible] = useToggle(false);
  return (
    <div className="grid gap-4 sm:gap-6 md:gap-8 mt-4 sm:mt-6">
      <Meta
        cmsTitle={
          nationalInfo?.nationalLandingPageTitle[language]?.trim() ?? ""
        }
        title="nationalVendorPage"
      />
      <DealershipForm />
      {mapIsVisible ? (
        <DealershipMap />
      ) : (
        <Button
          aria-label="showMap"
          className="border border-edge"
          onClick={setMapIsVisible}
        >
          {t("national.showMap")}
        </Button>
      )}
      <DealershipList />
    </div>
  );
};
