import { useLocation } from "wouter";

import { NationalCampaignRoute } from "../navigation/NationalRoutes";
import { useRouteParams } from "../navigation/useRouteParams";
import { useNullableGroupAccountInfo } from "../stores/hooks/useNullableGroupAccountInfo";
import { useNullableNationalAccountInfo } from "../stores/hooks/useNullableNationalAccountInfo";
import { useDefaultLanguage } from "./useDefaultLanguage";

export type Language = "en" | "fr";

export type LanguageMap = {
  en: string;
  fr: string;
};

export type NullableLanguageMap = {
  en: null | string;
  fr: null | string;
};

const languageSet = new Set(["en", "fr"]);

export const useLanguage = (): [Language, (nextLanguage: string) => void] => {
  const defaultLanguage = useDefaultLanguage();
  const [location, setLocation] = useLocation();
  const national = useNullableNationalAccountInfo();
  const group = useNullableGroupAccountInfo();
  const marketingParams = useRouteParams(NationalCampaignRoute);

  const languageParamIndex = national || group ? 3 : 2;

  const setLanguage = (nextLanguage: string): void => {
    const segments = location.split("/");
    segments[languageParamIndex] = nextLanguage;
    const nextLocation = segments.join("/") + window.location.search;
    setLocation(nextLocation, { replace: true });
  };

  const urlLanguage =
    marketingParams?.language || location.split("/")[languageParamIndex];

  const language = languageSet.has(urlLanguage)
    ? (urlLanguage as Language)
    : defaultLanguage;

  return [language, setLanguage];
};
