/* eslint-disable @typescript-eslint/ban-ts-comment */
import type {
  ZodEffects,
  ZodError,
  ZodObject,
  ZodRawShape,
  ZodRecord,
} from "zod";

import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useSetErrorToast } from "../stores/hooks/useSetErrorToast";

type FormObject = {
  [key: string]: FormDataEntryValue | FormDataEntryValue[];
};

type BaseSchema =
  | ZodEffects<ZodEffects<ZodObject<ZodRawShape>>>
  | ZodEffects<ZodObject<ZodRawShape>>
  | ZodObject<ZodRawShape>;

type FormCallback = (event: FormEvent<HTMLFormElement>) => Promise<void>;

type SubmitHandler<Schema extends BaseSchema | ZodRecord> = (
  validData: z.infer<Schema>,
  event: FormEvent<HTMLFormElement>
) => Promise<void> | void;

type FormHookProps<Schema extends BaseSchema | ZodRecord> = {
  liveErrors?: boolean;
  noReset?: boolean;
  onSubmit: SubmitHandler<Schema | ZodRecord>;
  schema: Schema;
};

export const formToPlainObject = (formNode: HTMLFormElement): FormObject => {
  const formData = new FormData(formNode);
  const serializedData: FormObject = {};

  for (const [key, value] of formData.entries()) {
    const trimmedValue = typeof value === "string" ? value.trim() : value;
    if (key in serializedData) {
      if (Array.isArray(serializedData[key])) {
        // @ts-ignore
        serializedData[key].push(trimmedValue);
      } else {
        // @ts-ignore
        serializedData[key] = [serializedData[key], trimmedValue];
      }
    } else {
      serializedData[key] = trimmedValue;
    }
  }
  return serializedData;
};

export const useForm = <Schema extends BaseSchema | ZodRecord>({
  liveErrors,
  noReset,
  onSubmit,
  schema,
}: FormHookProps<Schema>): [
  FormCallback,
  { busy: boolean; errors: ZodError<Schema> | null }
] => {
  const { t } = useTranslation();
  const [busy, setBusy] = useState(false);
  const [validationErrors, setValidationErrors] =
    useState<ZodError<Schema> | null>(null);

  const setErrorToast = useSetErrorToast();

  return [
    async (event): Promise<void> => {
      event.persist();
      event.preventDefault();
      const formNode = event.currentTarget;
      const elements = Array.from(formNode.elements);
      const formObject = formToPlainObject(formNode);
      const validationResult = await schema.safeParseAsync(formObject);

      if (validationResult.success) {
        setBusy(true);
        try {
          await onSubmit(validationResult.data, event);
          if (!noReset) {
            formNode.reset();
          }
        } catch (error) {
          setErrorToast(new Error(String(error)));
        } finally {
          setBusy(false);
        }
        setValidationErrors(null);
        for (const element of elements as unknown as HTMLInputElement[]) {
          element.setCustomValidity("");
          element.reportValidity();
        }
      } else {
        const validationErrors = validationResult.error.format();
        for (const element of elements.reverse() as unknown as HTMLInputElement[]) {
          const name = element.getAttribute("name") || "";
          const message =
            validationErrors[name]?._errors
              .map((error) => t(error))
              .join(".\n") || "";
          const shouldReport = message
            ? event?.type === "submit" || liveErrors
            : true;
          if (shouldReport) {
            element.setCustomValidity(message);
            if (element.type === "hidden" && message) {
              setErrorToast(new Error(message));
            }
            setTimeout(() => element.reportValidity(), 32);
          }
        }
        for (const validationError in validationErrors) {
          const item = validationErrors[validationError];
          if (Array.isArray(item)) {
            continue;
          }
          const element = elements.find(
            (element) => element.getAttribute("name") === validationError
          );
          if (!element) {
            const message =
              item!._errors.map((error) => t(error)).join(".\n") || "";
            const error = new Error(message);
            setErrorToast(error);
          }
        }
        setValidationErrors(validationResult.error as ZodError<Schema>);
      }
    },
    { busy, errors: validationErrors },
  ];
};
