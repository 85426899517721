/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import clsx from "clsx";
import React, {
  ForwardRefExoticComponent,
  HTMLAttributes,
  RefAttributes,
  forwardRef,
} from "react";

type InputGroupProps = HTMLAttributes<HTMLFieldSetElement> & {
  className?: string;
};

const InputGroupComponent: ForwardRefExoticComponent<
  InputGroupProps & RefAttributes<HTMLFieldSetElement>
> = forwardRef<HTMLFieldSetElement, InputGroupProps>(
  ({ children, className, ...rest }, ref) => {
    return (
      <fieldset
        {...rest}
        className={clsx("tw-input-group", className)}
        ref={ref}
      >
        {children}
      </fieldset>
    );
  }
);

export const InputGroup = InputGroupComponent;
