import clsx from "clsx";
import React, { FunctionComponent, useMemo } from "react";

import { DealerGroupDealerListPayload } from "../../queries/useGetDealerGroupDealerList";
import { VehicleManufacturer } from "../../stores/slices/groupAccountInfoSlice";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown";
import { Toggle } from "../../ui/Toggle";
import { ArrowLeftIcon } from "../../ui/icons/ArrowLeftIcon";

type OemLogoProps = {
  dealerList: DealerGroupDealerListPayload;
  vehicleManufacturer: VehicleManufacturer;
};

export const OemLogo: FunctionComponent<OemLogoProps> = ({
  dealerList,
  vehicleManufacturer,
}) => {
  const options = useMemo(
    () =>
      dealerList
        .filter(
          ({ nationalAccountId }) =>
            String(nationalAccountId) ===
            String(vehicleManufacturer.nationalAccountId)
        )
        .sort((a, b) => a.dealerName.localeCompare(b.dealerName)),
    [dealerList, vehicleManufacturer.nationalAccountId]
  );
  const buttonId = `oem-button-${vehicleManufacturer.manufacturerId}`;
  const dropDownId = `oem-dropdown-${vehicleManufacturer.manufacturerId}`;
  return (
    <Toggle>
      <Button
        className={clsx(
          "bg-paper rounded-none px-2 shrink-0",
          "md:rounded-md md:border md:border-edge md:pr-4 md:py-2 md:px-4"
        )}
        aria-label="dealerGroupLogo"
        disabled={options.length === 0}
        id={buttonId}
        key={vehicleManufacturer.manufacturerId}
      >
        <img
          alt={vehicleManufacturer.manufacturerName}
          className="aspect-square h-8 overflow-clip"
          src={vehicleManufacturer.manufacturerLogoUrl}
        />
        {vehicleManufacturer.manufacturerDisplayName}
      </Button>
      <Dropdown
        aria-labelledby={buttonId}
        className="grid py-2"
        id={dropDownId}
        preferredPlacement="bottom"
        role="menu"
      >
        {options.map(({ dealerId, dealerName, dealerUrlCode }) => (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            className={clsx(
              "tw-button rounded-none h-12 font-thin text-ink-2",
              "items-center justify-between text-left gap-4"
            )}
            href={`/${dealerUrlCode}`}
            key={dealerId}
            rel="noopener"
            target="_blank"
          >
            <span className="block max-w-[8rem]">{dealerName}</span>
            <ArrowLeftIcon className="rotate-180 w-4" />
          </a>
        ))}
      </Dropdown>
    </Toggle>
  );
};
