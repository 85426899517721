import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  tabChassis,
  tabPartNubmer,
  tabTireSize,
  tabVehicle,
} from "../../blocks/SearchForm/Tabs";
import { AnyProductType } from "../../constants/productTypes";

export type SearchFormState = {
  activeTab:
    | typeof tabChassis
    | typeof tabPartNubmer
    | typeof tabTireSize
    | typeof tabVehicle;
  dealerId: null | string;
  nationalAccountId: null | string;
  partNumber: null | string;
  postalCode: null | string;
  productType: AnyProductType | null;
  tireSize: null | string;
  vehicleChassis: null | string;
  vehicleManufacturerName: null | string;
  vehicleModelClassId: null | string;
  vehicleModelName: null | string;
  vehicleSubmodel: null | string;
  vehicleSubmodelSelected: null | string;
  vehicleSubmodelTiresizeId: null | string;
  vehicleYear: null | string;
};

const activeTab: SearchFormState["activeTab"] =
  ([
    window.location.pathname.includes("search/tiresize-search") && tabTireSize,
    window.location.pathname.includes("search/partnumber-search") &&
      tabPartNubmer,
  ].find(Boolean) as SearchFormState["activeTab"]) || tabVehicle;

export const defaultSearchFormState: SearchFormState = {
  activeTab: tabVehicle,
  dealerId: null,
  nationalAccountId: null,
  partNumber: null,
  postalCode: null,
  productType: null,
  tireSize: null,
  vehicleChassis: null,
  vehicleManufacturerName: null,
  vehicleModelClassId: null,
  vehicleModelName: null,
  vehicleSubmodel: null,
  vehicleSubmodelSelected: null,
  vehicleSubmodelTiresizeId: null,
  vehicleYear: null,
};

const name = "searchForm";

export const searchFormSlice = createSlice({
  initialState: { ...defaultSearchFormState, activeTab },
  name,
  reducers: {
    patch: (state, { payload }: PayloadAction<Partial<SearchFormState>>) => {
      const nextState = { ...state, ...payload };
      return nextState;
    },
    reset() {
      return defaultSearchFormState;
    },
    updateProductType: (state, { payload }: PayloadAction<AnyProductType>) => {
      const nextState = { ...state, productType: payload };
      return nextState;
    },
  },
});

export const searchForm = searchFormSlice.reducer;

export default searchFormSlice.reducer;
