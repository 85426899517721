import React, { FunctionComponent } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import { useNullableDealerAccountInfo } from "../../stores/hooks/useNullableDealerAccountInfo";
import { useNullableGroupAccountInfo } from "../../stores/hooks/useNullableGroupAccountInfo";
import { useNullableNationalAccountInfo } from "../../stores/hooks/useNullableNationalAccountInfo";

export const Meta: FunctionComponent<{
  cmsTitle: null | string;
  title: string;
}> = ({ cmsTitle, title }) => {
  const dealerInfo = useNullableDealerAccountInfo();
  const nationalInfo = useNullableNationalAccountInfo();
  const groupInfo = useNullableGroupAccountInfo();
  const { t } = useTranslation();

  const value =
    cmsTitle ||
    [
      t(`aoda.pageTitle.${title}`),
      dealerInfo?.dealerName,
      nationalInfo?.nationalAccountName,
      groupInfo?.dealerGroupName,
    ]
      .filter(Boolean)
      .slice(0, 2)
      .join(" | ");

  return (
    <Helmet>
      <title>{value}</title>
    </Helmet>
  );
};
