import React, { FunctionComponent } from "react";
import ReactPlayer from "react-player";
import { useWindowSize } from "react-use";

import type { Language } from "../../i18n/useLanguage";
import type { CarouselItem } from "../../stores/slices/dealerAccountInfoSlice";

export const HomepageCarouselVideo: FunctionComponent<{
  carouselItem: CarouselItem;
  language: Language;
  setIsLocked(isLocked: boolean): void;
}> = ({ carouselItem, language, setIsLocked }) => {
  const { height, width } = useWindowSize();
  const url =
    carouselItem.videoYouTubeUrl[language] || carouselItem.imageURL.en;

  const playerHeight = Math.min(
    Number(carouselItem.videoYouTubeHeight),
    Math.min(height, width) * 0.56
  );

  return (
    <ReactPlayer
      // NOTE: videos must be muted in order to play automatically.
      config={{
        youtube: {
          playerVars: { autoplay: carouselItem.videoAutoPlay },
        },
      }}
      onEnded={(): void => {
        setIsLocked(false);
      }}
      onError={(): void => {
        setIsLocked(false);
      }}
      onPause={(): void => {
        setIsLocked(false);
      }}
      onPlay={(): void => {
        setIsLocked(true);
      }}
      onStart={(): void => {
        setIsLocked(true);
      }}
      height={playerHeight}
      // https://www.theverge.com/2018/3/22/17150870/google-chrome-autoplay-videos-sound-mute-update
      muted={carouselItem.videoMute || carouselItem.videoAutoPlay}
      style={{ height: Math.min(Number(carouselItem.videoYouTubeHeight), 420) }}
      url={url}
      width="100%"
    />
  );
};
