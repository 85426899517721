export const whenTrue = async (callback: () => boolean): Promise<void> =>
  new Promise((resolve) => {
    const testCallback = (): void => {
      if (callback()) {
        resolve();
      } else {
        setTimeout(testCallback, 32);
      }
    };
    testCallback();
  });
