import { useMemo } from "react";

import { useObserveChanges } from "../useObserveChanges/index";
import {
  PopoverPlacementOptions,
  getOptimalPopoverPlacement,
} from "./getOptimalPopoverPlacement";
import { getPopoverCoordinates } from "./getPopoverCoordinates";

interface PopoverCoordinatesHook {
  (
    args: (
      | {
          placement?: PopoverPlacementOptions;
          preferredPlacement?: undefined;
        }
      | {
          placement?: undefined;
          preferredPlacement?: PopoverPlacementOptions;
        }
    ) & {
      consumerNode?: HTMLElement | null;
      disabled: boolean;
      offset?: number;
      producerNode?: HTMLElement | null;
    },
    ...deps: unknown[]
  ): {
    placement: PopoverPlacementOptions;
    x: number;
    y: number;
  } | null;
}

export type PopoverCoordinates = ReturnType<PopoverCoordinatesHook>;

export const usePopoverCoordinates: PopoverCoordinatesHook = (
  {
    consumerNode,
    disabled,
    offset = 0,
    placement,
    preferredPlacement,
    producerNode,
  },
  ...deps
) => {
  const shouldObserve: boolean =
    !disabled && Boolean(producerNode && consumerNode);
  const updatesCount = useObserveChanges({
    consumerNode,
    disabled: !shouldObserve,
    producerNode,
  });
  return useMemo(() => {
    if (!producerNode || !consumerNode) {
      return null;
    }
    const resultingPlacement =
      placement ||
      getOptimalPopoverPlacement({
        consumerNode,
        offset,
        preferredPlacement,
        producerNode,
      });

    return getPopoverCoordinates({
      offset,
      placement: resultingPlacement,
      producerNode,
    });
  }, [
    shouldObserve,
    producerNode,
    consumerNode,
    placement,
    preferredPlacement,
    offset,
    updatesCount,
    ...deps,
  ]);
};
