import { SWRConfiguration } from "swr";

import { FetcherResult } from "../api-client/fetcher";
import { ModifiedSWRResponse, useSwrGet } from "../api-client/useSwrGet";
import { useNullableGroupAccountInfo } from "../stores/hooks/useNullableGroupAccountInfo";
import { useSearchForm } from "../stores/hooks/useSearchForm";
import { DealerHours } from "../stores/slices/dealerAccountInfoSlice";

export const useGetDealerGroupDealerList = (
  swrOptions: SWRConfiguration<FetcherResult<DealerGroupDealerListPayload>> = {}
): ModifiedSWRResponse<
  FetcherResult<DealerGroupDealerListPayload>,
  DealerGroupDealerListPayload
> & { selectedDealer: DealerInfo | null } => {
  const groupAccountInfo = useNullableGroupAccountInfo();
  const [{ dealerId }] = useSearchForm();

  const { data, ...result } = useSwrGet<DealerGroupDealerListPayload>(
    groupAccountInfo
      ? "/retailSiteWebService/dealerGroup/getDealerGroupDealerList.php"
      : null,
    groupAccountInfo
      ? {
          dealerGroupId: groupAccountInfo.dealerGroupId,
        }
      : {},
    swrOptions
  );

  const selectedDealer =
    data?.find((dealer) => dealer.dealerId === dealerId) || null;

  return { data, selectedDealer, ...result };
};

export type DealerInfo = {
  dealerAddress: string;
  dealerCity: string;
  dealerContactEmail: string;
  dealerContactPhone: string;
  dealerCountry: string;
  dealerDefaultSearchSeason: string;
  dealerEnableECommerce: boolean;
  dealerEnableFinancing: boolean;
  dealerHidesPricing: boolean;
  dealerHours: DealerHours;
  dealerId: string;
  dealerIsActive: boolean;
  dealerLatitude: string;
  dealerLongitude: string;
  dealerMapExitLink: string;
  dealerName: string;
  dealerNationalAccountName: string;
  dealerNumber: string;
  dealerNumberAlias: string;
  dealerPostalCode: string;
  dealerRetailSiteGoogleMapsExternalUrl: string;
  dealerShowNoPricingOnNationalMap: boolean;
  dealerState: string;
  dealerUrlCode: string;
  dealerWebsiteUrl: string;
  nationalAccountId: string;
};

export type DealerGroupDealerListPayload = DealerInfo[];
