/* eslint-disable react/display-name */
import clsx from "clsx";
import React, {
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  RefAttributes,
  forwardRef,
} from "react";

interface CreateSelectOptions extends InputHTMLAttributes<HTMLSelectElement> {
  className?: string;
}

export interface SelectProps extends CreateSelectOptions {
  "aria-label": string;
}

export const createSelect = ({
  className: configClassName,
  ...config
}: CreateSelectOptions): ForwardRefExoticComponent<
  SelectProps & RefAttributes<HTMLSelectElement>
> =>
  forwardRef<HTMLSelectElement, SelectProps>(
    (
      {
        "aria-label": ariaLabel,
        children,
        className = configClassName,
        disabled,
        ...rest
      },
      ref
    ) => (
      <select
        {...config}
        {...rest}
        className={clsx(
          "tw-select",
          disabled && "tw-select--disabled",
          configClassName,
          className
        )}
        aria-label={ariaLabel}
        disabled={disabled}
        ref={ref}
      >
        {children}
      </select>
    )
  );
