import { useNullableDealerAccountInfo } from "../stores/hooks/useNullableDealerAccountInfo";
import { useNullableGroupAccountInfo } from "../stores/hooks/useNullableGroupAccountInfo";
import { useNullableNationalAccountInfo } from "../stores/hooks/useNullableNationalAccountInfo";
import { Language } from "./useLanguage";

export const useDefaultLanguage = (): Language => {
  const dealer = useNullableDealerAccountInfo();
  const national = useNullableNationalAccountInfo();
  const group = useNullableGroupAccountInfo();
  return (
    dealer?.dealerDefaultLanguage ??
    national?.nationalDefaultLanguage ??
    group?.dealerGroupDefaultLanguage ??
    "en"
  );
};
