import { useDispatch } from "react-redux";

import { useTypedSelector } from "../hooks";
import { SearchFormState, searchFormSlice } from "../slices/searchFormSlice";

interface SearchFormHook {
  (): [
    SearchFormState,
    {
      reset(): void;
      setActiveTab(activeTab: SearchFormState["activeTab"]): void;
      setDealership(
        dealerId: Pick<SearchFormState, "dealerId" | "nationalAccountId">
      ): void;
      setMake(make: SearchFormState["vehicleManufacturerName"]): void;
      setModel(
        vehicleModelName: SearchFormState["vehicleModelName"],
        vehicleModelClassId: SearchFormState["vehicleModelClassId"]
      ): void;
      setPartNumber(partNumber: SearchFormState["partNumber"]): void;
      setPostalCode(postalCode: SearchFormState["postalCode"]): void;
      setProduct(productType: SearchFormState["productType"]): void;
      setSelectedVehicleSubmodel(
        selectedVehicleSubmodel: SearchFormState["vehicleSubmodelSelected"]
      ): void;
      setTireSize(tireSize: SearchFormState["tireSize"]): void;
      setVehicleChassis(
        vehicleChassis: SearchFormState["vehicleChassis"]
      ): void;
      setVehicleSubmodel(
        vehicleSubmodel: SearchFormState["vehicleSubmodel"],
        vehicleSubmodelTiresizeId: SearchFormState["vehicleSubmodelTiresizeId"]
      ): void;
      setYear(year: SearchFormState["vehicleYear"]): void;
    }
  ];
}

export const useSearchForm: SearchFormHook = () => {
  const dispatch = useDispatch();
  const state = useTypedSelector(({ searchForm }) => searchForm);

  return [
    state,
    {
      reset(): void {
        const action = searchFormSlice.actions.reset();
        dispatch(action);
      },
      setActiveTab(activeTab): void {
        const action = searchFormSlice.actions.patch({ activeTab });
        dispatch(action);
      },
      setDealership(payload): void {
        const action = searchFormSlice.actions.patch(payload);
        dispatch(action);
      },
      setMake(vehicleManufacturerName): void {
        const action = searchFormSlice.actions.patch({
          productType: null,
          vehicleManufacturerName,
          vehicleModelName: null,
          vehicleSubmodel: null,
          vehicleSubmodelTiresizeId: null,
        });
        dispatch(action);
      },
      setModel(vehicleModelName, vehicleModelClassId): void {
        const action = searchFormSlice.actions.patch({
          productType: null,
          vehicleModelClassId,
          vehicleModelName,
          vehicleSubmodel: null,
          vehicleSubmodelTiresizeId: null,
        });
        dispatch(action);
      },
      setPartNumber(partNumber): void {
        const action = searchFormSlice.actions.patch({
          partNumber,
        });
        dispatch(action);
      },
      setPostalCode(postalCode): void {
        const action = searchFormSlice.actions.patch({
          postalCode,
        });
        dispatch(action);
      },
      setProduct(productType): void {
        const action = searchFormSlice.actions.patch({
          productType,
        });
        dispatch(action);
      },
      setSelectedVehicleSubmodel(selectedVehicleSubmodel): void {
        const action = searchFormSlice.actions.patch({
          vehicleSubmodelSelected: selectedVehicleSubmodel,
        });
        dispatch(action);
      },
      setTireSize(tireSize): void {
        const action = searchFormSlice.actions.patch({ tireSize });
        dispatch(action);
      },
      setVehicleChassis(chassis): void {
        const action = searchFormSlice.actions.patch({
          vehicleChassis: chassis,
        });
        dispatch(action);
      },
      setVehicleSubmodel(vehicleSubmodel, vehicleSubmodelTiresizeId): void {
        const action = searchFormSlice.actions.patch({
          vehicleSubmodel,
          vehicleSubmodelSelected: vehicleSubmodel,
          vehicleSubmodelTiresizeId,
        });
        dispatch(action);
      },
      setYear(vehicleYear): void {
        const action = searchFormSlice.actions.patch({
          productType: null,
          vehicleManufacturerName: null,
          vehicleModelName: null,
          vehicleSubmodel: null,
          vehicleSubmodelTiresizeId: null,
          vehicleYear,
        });
        dispatch(action);
      },
    },
  ];
};
