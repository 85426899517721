import { partTypeTire } from "../../constants/partTypes";
import { useIsLoggedOn } from "../../stores/hooks/useIsLoggedOn";
import { AnyOrderItem } from "../../stores/slices/cartSlice";

export const useTireLinkLogoIsVisible = ({
  itemType,
  // supportConsumerOrder,
  supportDealerOrder,
}: AnyOrderItem): boolean => {
  const isLoggedOn = useIsLoggedOn();

  if (itemType !== partTypeTire) {
    return false;
  }

  const shouldRenderTireLinkLogo = isLoggedOn && supportDealerOrder;
  // const shouldRenderTireLinkLogo = isLoggedOn
  //   ? supportDealerOrder
  //   : supportConsumerOrder;

  return shouldRenderTireLinkLogo;
};
