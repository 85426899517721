import {
  partTypeAccessory,
  partTypePackage,
  partTypeTire,
  partTypeWheel,
} from "../../constants/partTypes";
import { useLanguage } from "../../i18n/useLanguage";
import { useNullableDealerAccountInfo } from "../../stores/hooks/useNullableDealerAccountInfo";
import { AnyPartItem } from "../../stores/slices/cartSlice";

interface PricingLabelOverrideHook {
  (part: AnyPartItem | null): null | string;
}

export const usePricingLabelOverride: PricingLabelOverrideHook = (part) => {
  const dealer = useNullableDealerAccountInfo();
  const [language] = useLanguage();

  if (!dealer || !part) {
    return null;
  }

  switch (part.partDetails.primaryPart.itemType) {
    case partTypeTire: {
      const pricingLabelOverride =
        dealer.dealerTirePricingLabelOverride[language];
      return pricingLabelOverride || null;
    }

    case partTypeAccessory: {
      const pricingLabelOverride =
        dealer.dealerAccessoryPricingLabelOverride[language];
      return pricingLabelOverride || null;
    }

    case partTypePackage: {
      const pricingLabelOverride =
        dealer.dealerPackagePricingLabelOverride[language];
      return pricingLabelOverride || null;
    }

    case partTypeWheel: {
      const pricingLabelOverride =
        dealer.dealerWheelPricingLabelOverride[language];
      return pricingLabelOverride || null;
    }

    default: {
      return null;
    }
  }
};
