import { useLocation } from "wouter";

import { useLanguage } from "../../i18n/useLanguage";
import {
  NationalTiresizeResultsRoute,
  NationalVehicleResultsRoute,
} from "../../navigation/NationalRoutes";
import { useSomeRoutes } from "../../navigation/useSomeRoutes";
import { DealerEntryWithGeocoderResultAndDistance } from "../../queries/useGetNationalAccountDealerList";

export const useDealerLink = (
  dealer: DealerEntryWithGeocoderResultAndDistance | null
): string => {
  const [language] = useLanguage();
  const isResultsPage = useSomeRoutes(
    NationalVehicleResultsRoute,
    NationalTiresizeResultsRoute
  );
  const [location] = useLocation();

  if (!dealer) return "";

  const baseUrl = `/${dealer?.dealerUrlCode}/${language}/dealer/${dealer?.dealerId}`;

  if (isResultsPage) {
    const searchParams = location.split("/").slice(4).join("/");
    return `${baseUrl}/${searchParams}`;
  }

  // NOTE: must go after isResultsPage check, since results has its own contact-us form when no pricing
  if (dealer.dealerHidesPricing) {
    return `${baseUrl}/contact-us`;
  }

  return baseUrl;
};
