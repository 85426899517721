import { useDispatch } from "react-redux";

import { OrderDetails, cartSlice } from "../slices/cartSlice";

export const useCartNext = (): ((state: OrderDetails) => void) => {
  const dispatch = useDispatch();
  return (state): void => {
    const action = cartSlice.actions.next(state);
    dispatch(action);
  };
};
