import clsx from "clsx";
import React, {
  FunctionComponent,
  ImgHTMLAttributes,
  useEffect,
  useState,
} from "react";

type ImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  errorClassName?: string;
  fallbackSrc: string;
  pulsate?: boolean;
};

export const Image: FunctionComponent<ImageProps> = ({
  className,
  errorClassName,
  fallbackSrc,
  onError,
  pulsate,
  src,
  srcSet,
  ...props
}) => {
  const [failed, setFailed] = useState(false);
  useEffect(() => {
    setFailed(false);
  }, [src, srcSet]);
  return (
    <img
      {...props}
      className={clsx(
        className,
        (failed || (!src && !srcSet)) && errorClassName,
        pulsate && "animate-pulse"
      )}
      onError={(event): void => {
        onError?.(event);
        if (!event.defaultPrevented) {
          setFailed(true);
        }
      }}
      src={src && !failed ? src : fallbackSrc}
      srcSet={srcSet && !failed ? srcSet : undefined}
    />
  );
};
