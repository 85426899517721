import { fitsSpace } from "./fitsSpace";
import { suggestPlacement } from "./suggestPlacement";

interface OptimalPopeverPlacement {
  (args: {
    consumerNode: HTMLElement;
    offset: number;
    preferredPlacement?: PopoverPlacementOptions;
    producerNode: HTMLElement;
  }):
    | "bottom"
    | "bottom-left"
    | "bottom-right"
    | "left"
    | "left-bottom"
    | "left-top"
    | "right"
    | "right-bottom"
    | "right-top"
    | "top"
    | "top-left"
    | "top-right";
}

export type PopoverPlacementOptions = ReturnType<OptimalPopeverPlacement>;

export const getOptimalPopoverPlacement: OptimalPopeverPlacement = ({
  consumerNode,
  offset,
  preferredPlacement,
  producerNode,
}) => {
  if (
    preferredPlacement &&
    fitsSpace({ consumerNode, offset, preferredPlacement, producerNode })
  ) {
    return preferredPlacement;
  }
  return suggestPlacement({ producerNode });
};
