import { PopoverPlacementOptions } from "../hooks/usePopoverCoordinates/getOptimalPopoverPlacement";

export const getAnimationClassName = (
  placement: PopoverPlacementOptions,
  visible: boolean
): string => {
  switch (placement) {
    case "top":
    case "top-left":
    case "top-right":
      return visible
        ? "animate-tw-dropdown-in-top"
        : "animate-tw-dropdown-out-top";
    case "right":
    case "right-top":
    case "right-bottom":
      return visible
        ? "animate-tw-dropdown-in-right"
        : "animate-tw-dropdown-out-right";
    case "left":
    case "left-top":
    case "left-bottom":
      return visible
        ? "animate-tw-dropdown-in-left"
        : "animate-tw-dropdown-out-left";

    default:
      return visible
        ? "animate-tw-dropdown-in-bottom"
        : "animate-tw-dropdown-out-bottom";
  }
};
