import { FetcherResult } from "../api-client/fetcher";
import { PostResponse, usePost } from "../api-client/usePost";
import { PricingOption } from "../blocks/PriceSelect/getFlatPricingOptions";
import { useCartNext } from "../stores/hooks/useCartNext";
import { AnyOrderItem, OrderDetails } from "../stores/slices/cartSlice";
import { useGetNewCart } from "./useGetNewCart";

type UpdateItemInCart = (
  orderItem: Pick<AnyOrderItem, "optionId" | "orderItemId" | "vehicleId">,
  partPricingItem: Pick<PricingOption, "context" | "quantity">
) => Promise<FetcherResult<OrderDetails>>;

export const useUpdateItemInCart = (): Omit<
  PostResponse<OrderDetails>,
  "send"
> & {
  send: UpdateItemInCart;
} => {
  const init = useGetNewCart();
  const { send: updateItemInCart, ...post } = usePost<OrderDetails>(
    "/retailSiteWebService/shopping/cart/updateItemInCart.php"
  );
  const cartNext = useCartNext();
  const send: UpdateItemInCart = async (
    { optionId, orderItemId, vehicleId },
    { context, quantity }
  ) => {
    const { dealerId, orderNumber } = await init();
    const response = await updateItemInCart({
      cartId: orderNumber,
      cartItemId: orderItemId,
      dealerId,
      isNational: "0",
      itemQty: quantity,
      optionId,
      orderNumber,
      pricingContextId: context.pricingContextId,
      vehicleId,
    });
    cartNext(response.payload);
    return response;
  };
  return { ...post, send };
};
