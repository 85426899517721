import { AnyOrderItem, AnyPartItem, CartState } from "../slices/cartSlice";
import { useCart } from "./useCart";

const findOrderItemInCart = (
  cart: CartState,
  itemId: null | string
): AnyOrderItem | null =>
  cart?.orderItemList.find((item) => item.itemId === itemId) || null;

export const useRelatedCartItems = (
  part: AnyPartItem | null,
  isStaggered: boolean
): (AnyOrderItem | null)[] => {
  const cart = useCart();

  if (!part) {
    return [];
  }

  const primaryItem = findOrderItemInCart(
    cart,
    part.partDetails.primaryPart.itemId || null
  );

  const secondaryItems = isStaggered
    ? part.partDetails.secondaryParts
        .map((part) => findOrderItemInCart(cart, part.itemId || null))
        .slice(0, 1)
    : [];

  return [primaryItem, ...secondaryItems];
};
