import { PopoverPlacementOptions } from "./getOptimalPopoverPlacement";

interface FitsSpace {
  (args: {
    consumerNode: HTMLElement;
    offset: number;
    preferredPlacement: PopoverPlacementOptions;
    producerNode: HTMLElement;
  }): boolean;
}

export const fitsSpace: FitsSpace = ({
  consumerNode,
  offset,
  preferredPlacement,
  producerNode,
}) => {
  const producerBounds = producerNode.getBoundingClientRect();
  const consumerBounds = consumerNode.getBoundingClientRect();
  const doubleOffset = offset * 2;
  switch (preferredPlacement) {
    case "top":
    case "top-left":
    case "top-right": {
      const spaceTop =
        producerBounds.top - consumerBounds.height - doubleOffset;
      return spaceTop > 0;
    }

    case "left":
    case "left-top":
    case "left-bottom": {
      const spaceLeft =
        producerBounds.left - consumerBounds.width - doubleOffset;
      return spaceLeft > 0;
    }
    case "right":
    case "right-top":
    case "right-bottom": {
      const spaceRight =
        producerBounds.right + consumerBounds.width + doubleOffset;
      return spaceRight < window.innerWidth;
    }

    case "bottom":
    case "bottom-left":
    case "bottom-right":
    default: {
      const spaceBottom =
        producerBounds.bottom + consumerBounds.height + doubleOffset;
      return spaceBottom < window.innerHeight;
    }
  }
};
