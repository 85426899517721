import qs from "query-string";
import useSWR, { SWRConfiguration, SWRResponse } from "swr";

import { useTokenId } from "../stores/hooks/useTokenId";
import { FetcherResult, fetcher } from "./fetcher";

type UrlParameters = Record<string, string>;

export type ModifiedSWRResponse<Payload, ModifiedPayload> = Omit<
  SWRResponse<Payload>,
  "data"
> & { data: ModifiedPayload | undefined };

export const useSwrGet = <Data>(
  path: null | string,
  parameters?: UrlParameters,
  options: SWRConfiguration<FetcherResult<Data>> = {}
): ModifiedSWRResponse<FetcherResult<Data>, Data> => {
  const tokenId = useTokenId();
  const url = [
    path,
    parameters &&
      qs.stringify({
        ...parameters,
        tokenId,
      }),
  ]
    .filter(Boolean)
    .join("?");

  const { data, ...rest } = useSWR<FetcherResult<Data>>(path && url, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    ...options,
  });

  return { ...rest, data: data?.payload };
};
