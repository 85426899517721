import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { config } from "../config/config";
import { Token } from "../stores/slices/authSlice";

export const bugsnagStart: VoidFunction = () => {
  if (config.bugsnagApiKey) {
    Bugsnag.start({
      apiKey: config.bugsnagApiKey,
      plugins: [new BugsnagPluginReact()],
    });
    BugsnagPerformance.start({ apiKey: config.bugsnagApiKey });
  }
};

export const bugsnagNotify: typeof Bugsnag.notify = (...args) => {
  if (config.bugsnagApiKey) {
    Bugsnag.notify(...args);
  }
};

export const bugsnagUseToken = ({
  firstName,
  lastName,
  tokenId,
}: Token): void => {
  if (config.bugsnagApiKey) {
    Bugsnag.setUser(
      tokenId,
      "",
      [firstName, lastName].filter(Boolean).join(" ")
    );
  }
};
