import React, { FunctionComponent } from "react";

import type { Language, LanguageMap } from "../../i18n/useLanguage";

export const HomepageCarouselImage: FunctionComponent<{
  image: LanguageMap;
  language: Language;
  onError(src: string): void;
}> = ({ image, language, onError }) => {
  const src = image[language] || image.en;

  return (
    <img
      onError={(): void => {
        onError(src);
      }}
      alt="homepage carousel"
      className="tw-carousel__img"
      src={src}
    />
  );
};
