import { ReduxState } from "../../stores/reduxStore";

export const isCaptchaEnabled = (state: ReduxState): boolean => {
  const { _dealerAccountInfo, auth, captcha } = state;

  return (
    String(_dealerAccountInfo?.dealerEnableCaptcha) === "true" &&
    !auth.tokenIsAuthenticated &&
    !captcha.token
  );
};
