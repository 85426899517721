/* eslint-disable react/display-name */
import clsx from "clsx";
import React, {
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  RefAttributes,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";

type InputType =
  | "button"
  | "checkbox"
  | "date"
  | "email"
  | "file"
  | "hidden"
  | "image"
  | "number"
  | "password"
  | "radio"
  | "reset"
  | "submit"
  | "tel"
  | "text";

interface CreateInputOptions
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "type"> {
  className?: string;
  inputClassName?: string;
  type?: InputType;
}

export interface InputProps extends CreateInputOptions {
  after?: React.ReactNode;
  "aria-label": string;
  before?: React.ReactNode;
  busy?: boolean;
}

export const createInput = ({
  className: configClassName,
  inputClassName: configInputClassName,
  type: configType,
  ...config
}: CreateInputOptions): ForwardRefExoticComponent<
  InputProps & RefAttributes<HTMLInputElement>
> =>
  forwardRef<HTMLInputElement, InputProps>(
    (
      {
        after,
        "aria-label": ariaLabel,
        before,
        busy,
        className = configClassName,
        disabled,
        inputClassName = configInputClassName,
        type = configType || "text",
        ...rest
      },
      ref
    ) => {
      const { t } = useTranslation();
      return (
        <label
          className={clsx(
            "tw-input",
            disabled && "tw-input--disabled",
            busy && "tw-input--busy",
            configClassName,
            className
          )}
          onClick={(e): void => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          {before && <span>{before}</span>}
          <input
            {...config}
            {...rest}
            aria-label={t(`aoda.inputLabel.${ariaLabel}`)}
            className={inputClassName}
            disabled={disabled}
            ref={ref}
            type={type}
          />
          {after && <span>{after}</span>}
        </label>
      );
    }
  );
