import { useTranslation } from "react-i18next";
import { useLocation } from "wouter";

import { post } from "../../api-client/post";
import { usePost } from "../../api-client/usePost";
import { getDealerBasePath } from "../../navigation/getDealerBasePath";
import { useTypedSelector } from "../hooks";
import { Token, dealerUrlCode } from "../slices/authSlice";
import { useNullableDealerAccountInfo } from "./useNullableDealerAccountInfo";
import { useResetSearchForm } from "./useResetSearchForm";
import { useSetErrorToast } from "./useSetErrorToast";
import { useUpgradeToken } from "./useUpgradeToken";

export const useAuth = (): AuthHookResult => {
  const state = useTypedSelector(({ auth }) => auth);
  const dealerInfo = useNullableDealerAccountInfo();
  const [, setLocation] = useLocation();
  const resetRearchForm = useResetSearchForm();
  const upgradeToken = useUpgradeToken();
  const { t } = useTranslation();
  const setError = useSetErrorToast();
  const { loading: isSigningIn, send: signIn } = usePost("/sign-in");
  const { loading: isSigningOut, send: signOut } = usePost(
    "/retailSiteWebService/authentication/terminateSecurityToken.php"
  );

  return [
    state,
    {
      isSigningIn,
      isSigningOut,
      async signIn({ password, rememberMe, username }): Promise<boolean> {
        const { token } = await signIn({
          dealerId: dealerInfo?.dealerId ?? "",
          dealerUrlCode,
          password,
          rememberMe: String(rememberMe),
          username,
        });
        const success = Boolean(token.tokenIsAuthenticated);

        if (success) {
          upgradeToken(token);
        } else {
          const error = new Error(t("auth.failedToSignIn"));
          setError(error);
        }
        return success;
      },
      async signOut(): Promise<void> {
        await signOut({});
        const { token } = await post("/sign-out", {
          dealerId: dealerInfo?.dealerId,
          dealerUrlCode,
        });
        upgradeToken(token);

        resetRearchForm();
        setLocation(getDealerBasePath());
      },
    },
  ];
};

type AuthHookResult = [
  Token,
  {
    isSigningIn: boolean;
    isSigningOut: boolean;
    signIn(form: {
      password: string;
      rememberMe: boolean;
      username: string;
    }): Promise<boolean>;
    signOut(): Promise<void>;
  }
];
