import {
  PartTypeAccessory,
  PartTypePackage,
  PartTypeTire,
  PartTypeWheel,
} from "../../constants/partTypes";
import { Language } from "../../i18n/useLanguage";
import { AnyPartItem } from "../../stores/slices/cartSlice";
import { PricingOption, getFlatPricingOptions } from "./getFlatPricingOptions";

export type CombinedPart = {
  itemId: string;
  itemType: PartTypeAccessory | PartTypePackage | PartTypeTire | PartTypeWheel;
  pricingOptions: PricingOption[];
};

interface CombineParts {
  (
    part: AnyPartItem | null,
    isStaggered: boolean,
    language: Language
  ): CombinedPart[];
}

export const combinePartsForPricing: CombineParts = (
  part,
  isStaggered,
  language
) => {
  if (!part) {
    return [];
  }
  const primaryPart = {
    itemId: part.partSummary.primaryPart.itemId,
    itemType: part.partSummary.primaryPart.itemType,
    pricingOptions: getFlatPricingOptions(
      part.partPricing.primaryPart,
      language
    ),
  };
  const secondaryParts = isStaggered
    ? part.partSummary.secondaryParts
        .map(({ itemId, itemType }, key) => ({
          itemId,
          itemType,
          pricingOptions: getFlatPricingOptions(
            part.partPricing.secondaryParts[key],
            language
          ),
        }))
        .slice(0, 1)
    : [];
  const combinedParts = [primaryPart, ...secondaryParts];
  return combinedParts;
};
