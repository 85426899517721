import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { useLanguage } from "../../i18n/useLanguage";
import { DealerEntryWithGeocoderResultAndDistance } from "../../queries/useGetNationalAccountDealerList";
import { PrimaryButton } from "../../ui/Button/Button";
import { formatDistance } from "../../ui/DistanceDisplay/MileageDisplay";
import { Placeholder } from "../../ui/Placeholder";
import { getDealerAddress } from "./getDealerAddress";
import { useDealerLink } from "./useDealerLink";

type DealeerTileProps = {
  dealer: DealerEntryWithGeocoderResultAndDistance | null;
};

export const DealershipTile: FunctionComponent<DealeerTileProps> = ({
  dealer,
}) => {
  const [language] = useLanguage();
  const { t } = useTranslation();
  const link = useDealerLink(dealer);

  return (
    <div className="border border-edge rounded-panel p-4 sm:p-6 lg:p-8 flex flex-col gap-y-2 md:gap-y-3 lg:gap-y-4">
      <div className="flex flex-col gap-2 md:gap-3 flex-1">
        <div className="flex justify-between items-start mb-1 md:mb-2">
          <Placeholder className="w-32" loading={!dealer}>
            <h2 className="text-lg md:text-xl font-medium">
              {dealer?.dealerName}
            </h2>
          </Placeholder>
          {dealer?.distanceInKm && dealer.distanceInKm !== Infinity && (
            <span className=" bg-ink-2 text-paper rounded-panel px-2 shrink-0">
              {formatDistance(String(dealer?.distanceInKm), language, "CA")}
            </span>
          )}
        </div>
        {dealer?.dealerContactPhone && (
          <p>
            <span className="font-thin text-ink-2">{t("national.phone")}</span>{" "}
            <strong>
              <Placeholder className="w-24" loading={!dealer}>
                {dealer?.dealerContactPhone}
              </Placeholder>
            </strong>
          </p>
        )}
        <p>
          <span className="font-thin text-ink-2">{t("national.location")}</span>
          {` `}
          <strong>
            <Placeholder className="w-24" loading={!dealer}>
              {getDealerAddress(dealer)}
            </Placeholder>
          </strong>
        </p>
      </div>
      <hr />
      <PrimaryButton
        aria-label="visitDealerPage"
        className="md:h-12 shadow-md tw-button-dealeship-tile"
        disabled={!dealer}
        element="a"
        href={link}
      >
        {t(
          dealer?.dealerHidesPricing
            ? "national.contactDealerForPricing"
            : "national.selectDealerForPricing"
        )}
      </PrimaryButton>
    </div>
  );
};
