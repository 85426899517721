import { useMemo } from "react";

import { useSwrGet } from "../api-client/useSwrGet";
import {
  dealershipModeDealer,
  dealershipModeLocality,
  useDealershipsCriteria,
  useDealershipsDistance,
  useDealershipsMode,
} from "../blocks/Dealerships/DealershipForm";
import { useNullableNationalAccountInfo } from "../stores/hooks/useNullableNationalAccountInfo";
import { DealerHours } from "../stores/slices/dealerAccountInfoSlice";
import { GeocodingResults, useGeocoder } from "../util/geocoder";
import { Point, getDistanceInKm } from "../util/getDistanceInKm";

export const useGetNationalAccountDealerList = (): {
  data: DealerEntryWithGeocoderResultAndDistance[] | undefined;
  geocodeResult: GeocodingResults;
  isLoading: boolean;
} => {
  const nationalAccountInfo = useNullableNationalAccountInfo();
  const [mode] = useDealershipsMode();
  const [criteria] = useDealershipsCriteria();
  const [distance] = useDealershipsDistance();

  const trimmedCriteria = criteria.trim().toLowerCase();

  const { data: geoData, isLoading: geocoderIsLoading } = useGeocoder(
    mode === dealershipModeLocality ? trimmedCriteria : ""
  );

  const { data: payload, isLoading: apiIsLoading } = useSwrGet<Payload>(
    nationalAccountInfo
      ? "/retailSiteWebService/national/getNationalAccountDealerList.php"
      : null,
    {
      nationalAccountId: nationalAccountInfo?.nationalAccountId ?? "",
    }
  );

  const filteredData = useMemo(() => {
    const shouldFilterByText = Boolean(
      mode === dealershipModeDealer && trimmedCriteria
    );
    const shouldFilterByDistance = Boolean(
      mode === dealershipModeLocality && distance && trimmedCriteria
    );

    const results: DealerEntryWithGeocoderResultAndDistance[] | undefined =
      payload
        ?.map((item) => {
          const lat = geoData?.geometry?.location.lat() || 0;
          const lng = geoData?.geometry?.location.lng() || 0;
          const pointA: Point | null =
            lat && lng
              ? {
                  lat,
                  lng,
                }
              : null;

          const pointB: Point = {
            lat: parseFloat(item.dealerLatitude),
            lng: parseFloat(item.dealerLongitude),
          };

          const distanceInKm = getDistanceInKm(pointA, pointB);

          return {
            ...item,
            distanceInKm,
            point: pointB,
          };
        })
        .filter(
          ({
            dealerAddress,
            dealerCity,
            dealerContactPhone,
            dealerId,
            dealerName,
            dealerState,
            distanceInKm,
          }) => {
            const results = new Set<boolean>([true]);

            if (shouldFilterByText) {
              const text = [
                dealerAddress,
                dealerCity,
                dealerContactPhone,
                dealerId,
                dealerName,
                dealerState,
              ].join(" ");
              const result = text.toLowerCase().includes(trimmedCriteria);
              results.add(result);
            }

            if (shouldFilterByDistance) {
              results.add(distanceInKm <= distance);
            }

            return !results.has(false);
          }
        )
        .sort((a, b) => a.distanceInKm - b.distanceInKm);

    return results;
  }, [mode, trimmedCriteria, distance, geoData, payload]);

  return {
    data: filteredData,
    geocodeResult: geoData || null,
    isLoading: geocoderIsLoading || apiIsLoading,
  };
};

type Payload = DealerEntry[];

export type DealerEntryWithGeocoderResultAndDistance = DealerEntry & {
  distanceInKm: number;
  point: Point;
};

type DealerEntry = {
  dealerAddress: string;
  dealerCity: string;
  dealerContactEmail: string;
  dealerContactPhone: string;
  dealerCountry: string;
  dealerDefaultSearchSeason: string;
  dealerEnableECommerce: boolean;
  dealerEnableFinancing: boolean;
  dealerHidesPricing: boolean;
  dealerHours: DealerHours;
  dealerId: string;
  dealerIsActive: boolean;
  dealerLatitude: string;
  dealerLongitude: string;
  dealerMapExitLink: string;
  dealerName: string;
  dealerNationalAccountName: string;
  dealerNumber: string;
  dealerNumberAlias: string;
  dealerPostalCode: string;
  dealerRetailSiteGoogleMapsExternalUrl: string;
  dealerShowNoPricingOnNationalMap: boolean;
  dealerState: string;
  dealerUrlCode: string;
  dealerWebsiteUrl: string;
  nationalAccountId: string;
};
