import { useEffect } from "react";

export const useBodyClass = (...classNames: string[]): void => {
  useEffect(() => {
    const bodyNode = document.querySelector("body");
    if (bodyNode) {
      for (const className of classNames) {
        bodyNode.classList.add(className);
      }
    }
    return () => {
      if (bodyNode) {
        for (const className of classNames) {
          bodyNode.classList.remove(className);
        }
      }
    };
  }, [...classNames]);
};
