import { Language } from "../../i18n/useLanguage";
import { AccessoryPartPricing } from "../../queries/useSearchAccessoriesByVehicle";
import { PackagePartPricing } from "../../queries/useSearchPartPackagesByVehicle";
import {
  PricingContext,
  TirePartPricing,
} from "../../queries/useSearchTiresByVehicle";
import { WheelPartPricing } from "../../queries/useSearchWheelsByVehicle";

export type PricingOption = {
  context: PricingContext;
  data: null;
  label: string;
  quantity: string;
  value: number;
};

export type AnyPartPricing =
  | AccessoryPartPricing
  | PackagePartPricing
  | TirePartPricing
  | WheelPartPricing;

export const getFlatPricingOptions = (
  pricing: AnyPartPricing | null,
  language: Language
): PricingOption[] =>
  Object.values(pricing?.price || {})
    .flatMap(({ contextList, quantity }) =>
      contextList.map((context) => ({
        context,
        data: null,
        label: [
          String(quantity),
          `${
            context.promotionPricingText[language] ||
            context.promotionPricingText.en ||
            context.promotionText[language] ||
            context.promotionText.en
          }`,
        ]
          .filter(Boolean)
          .join(" – "),
        quantity,
        value: context.pricingContextId,
      }))
    )
    .map((option, value) => ({ ...option, value }));
