import clsx from "clsx";
import React, { FunctionComponent } from "react";

import { Language } from "../../i18n/useLanguage";
import { CarouselItem } from "../../stores/slices/dealerAccountInfoSlice";

type HomepageCarouselLinkProps = {
  carouselItem: CarouselItem;
  language: Language;
};

const getHAlignClassName = ({ linkHAlign }: CarouselItem): string => {
  switch (linkHAlign) {
    case "center":
      return "tw-carousel__link-halign-center";
    case "right":
      return "tw-carousel__link-halign-right";
    default:
      return "tw-carousel__link-halign-left";
  }
};

const getVAlignClassName = ({ linkVAlign }: CarouselItem): string => {
  switch (linkVAlign) {
    case "bottom":
      return "tw-carousel__link-valign-bottom";
    case "center":
      return "tw-carousel__link-valign-center";
    default:
      return "tw-carousel__link-valign-top";
  }
};

export const HomepageCarouselLink: FunctionComponent<
  HomepageCarouselLinkProps
> = ({ carouselItem, language }) => {
  const linkSrc = carouselItem.linkURL[language] || carouselItem.linkURL.en;
  const linkText = carouselItem.linkText[language] || carouselItem.linkText.en;
  const linkBody = carouselItem.linkBody[language] || carouselItem.linkBody.en;

  if (!linkSrc || !linkText) {
    return null;
  }

  return (
    <div
      className={clsx(
        "tw-carousel__link",
        getHAlignClassName(carouselItem),
        getVAlignClassName(carouselItem)
      )}
    >
      <div className="tw-carousel__link__wrapper">
        <a href={linkSrc} rel="noopener" target={carouselItem.linkTarget}>
          {linkText}
        </a>
        <p>{linkBody}</p>
      </div>
    </div>
  );
};
