import { createButton } from "./createButton";

export const Button = createButton({});

export const InputButton = createButton({
  className: "bg-input",
});

export const PrimaryButton = createButton({
  className:
    "tw-button--primary bg-primary text-white ring-amber-300 bg-opacity-100",
});

export const PrimaryOutlinedButton = createButton({
  className: "border border-primary text-pimary ring-amber-300",
});

export const PrimaryRoundButton = createButton({
  className: "bg-primary text-white ring-amber-300 p-0 w-9 h-9 rounded-tw-full",
});

export const RoundButton = createButton({
  className: "px-0 py-0 w-9 h-9 rounded-tw-full shrink-0",
});

export const SquareButton = createButton({
  className: "px-0 py-0 rounded-none shrink-0 aspect-square",
});
