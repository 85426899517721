import clsx from "clsx";
import React, { FunctionComponent } from "react";

import { partTypePackage, partTypeTire } from "../../../constants/partTypes";
import { useLanguage } from "../../../i18n/useLanguage";
import { useRemoveItemFromCart } from "../../../queries/useRemoveItemFromCart";
import { PackagePartItem } from "../../../queries/useSearchPartPackagesByVehicle";
import { useUpdateItemInCart } from "../../../queries/useUpdateItemInCart";
import { AnyOrderItem } from "../../../stores/slices/cartSlice";
import { Image } from "../../../ui/Image/Image";
import { fixTireImageUrl } from "../../../util/fixTireImageUrl";
import { getPartFallbackSrc } from "../../../util/getPartFallbackSrc";
import { getPartName } from "../../../util/getPartName";
import { PackageImages } from "../../Packages/PackageImages/PackageImages";
import { PriceSelect } from "../../PriceSelect/PriceSelect";
import { TireLinkLogo } from "../../TireLinkLogo/TireLinkLogo";
import { useTireLinkLogoIsVisible } from "../useTireLinkLogoIsVisible";
import { OrderItemRemoveButton } from "./OrderItemRemoveButton";

type OrderItemProps = {
  last: boolean;
  orderItem: AnyOrderItem;
};

export const OrderItem: FunctionComponent<OrderItemProps> = ({
  last,
  orderItem,
}) => {
  const shouldRenderTireLinkLogo = useTireLinkLogoIsVisible(orderItem);
  const updateItemInCart = useUpdateItemInCart();
  const removeItemFromCart = useRemoveItemFromCart();
  const [language] = useLanguage();
  const { partTire } = orderItem.itemPart;
  return (
    <div
      className={clsx(
        "pb-8 items-center gap-2 md:gap-4",
        !last && "border-b",
        partTire ? "grid md:flex" : "flex"
      )}
    >
      {orderItem.itemType === partTypePackage ? (
        <div className="flex gap-2">
          <PackageImages
            className="w-14 md:w-20 h-14 md:h-20"
            part={orderItem.itemPart as PackagePartItem}
          />
        </div>
      ) : (
        <Image
          className={clsx(
            "w-14 md:w-20 h-14 md:h-20 bg-input rounded-section object-cover object-center",
            "text-transparent",
            orderItem.itemType === partTypeTire && "scale-x-[-1]"
          )}
          src={fixTireImageUrl(
            orderItem.itemImageLink,
            orderItem.itemType !== partTypeTire
          )}
          alt="Package Image"
          errorClassName="opacity-40 blur-sm"
          fallbackSrc={getPartFallbackSrc(orderItem.itemType)}
        />
      )}
      <div className="flex-1">
        <div className="flex space-x-2 items-start">
          <h3 className="flex-1 text-lg font-medium leading-4">
            {getPartName(orderItem.itemPart, language)}
          </h3>
          <OrderItemRemoveButton orderItem={orderItem} />
        </div>
        <div>
          <div className="grid md:flex md:flex-wrap md:w-[24rem] items-center text-sm gap-2 md:gap-4">
            <PriceSelect
              onChange={([{ pricingOption }]): void => {
                updateItemInCart.send(orderItem, pricingOption);
              }}
              busy={updateItemInCart.loading}
              disabled={removeItemFromCart.loading}
              isStaggered={false}
              orderItem={orderItem}
              part={orderItem.itemPart}
              quantityClassName="hidden md:flex"
              totalClassName="hidden md:flex"
            />
          </div>
          {shouldRenderTireLinkLogo && <TireLinkLogo className="h-4 mt-1" />}
        </div>
      </div>
    </div>
  );
};
