import { FetcherResult, fetcher } from "./fetcher";

interface Post {
  <Data, Body = unknown>(path: string, payload?: Body): Promise<
    FetcherResult<Data>
  >;
}

export const post: Post = (path, payload) => {
  const requestInit = {
    body: new URLSearchParams({ ...payload }),
    method: "POST",
  };
  return fetcher(path, requestInit);
};
