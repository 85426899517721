import { createSlice } from "@reduxjs/toolkit";

const name = "pagination";

export const defaultItemsPerPage: number = 6;

type PaginationState = {
  total: number;
  visible: number;
};

const defaultState: PaginationState = {
  total: 0,
  visible: defaultItemsPerPage,
};

export const paginationSlice = createSlice({
  initialState: defaultState,
  name,
  reducers: {
    increment(state) {
      return { ...state, visible: state.visible + defaultItemsPerPage };
    },
    reset(_state, { payload }: { payload: number }) {
      return { total: payload, visible: defaultItemsPerPage };
    },
  },
});

export const pagination = paginationSlice.reducer;

export const incrementPagination = paginationSlice.actions.increment;

export const resetPagination = paginationSlice.actions.reset;
